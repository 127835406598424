import React from "react";
import { useState, useContext } from "react";
import Sidebar from "../../common/Sidebar/sidebar";
import { Modal } from "react-bootstrap";
import { Context } from "./Provider";


class AddButton extends React.Component {
  render() {
    const { onClick } = this.props;
    return (
      <button onClick={onClick} className="btn btn-primary my-2" type="button">
        Yangi qo'shish
      </button>
    );
  }
}

class EditButton extends React.Component {
  render() {
    const { onClick } = this.props;
    return (
      <button
        onClick={onClick}
        className="btn btn-light border-secondary"
        type="button"
        style={{ marginRight: "10px" }}
        title="O'zgartirish"
      >
        <i className="fa-regular fa-pen-to-square"></i>
      </button>
    );
  }
}
class DeleteButton extends React.Component {
  render() {
    const { onClick } = this.props;
    return (
      <button
        onClick={onClick}
        style={{ marginRight: "10px" }}
        className="btn btn-danger"
        type="button"
        title="O'chirish"
      >
        <i className="fa-solid fa-trash-can"></i>
      </button>
    );
  }
}

class DeleteConfirmation extends React.Component {
    render() {
      const {show} = this.props;
      return (
        <Modal
              show={show}
              onHide={() => {
                this.hideModal();
              }}
              animation={false}
            >
              hi here
            </Modal>
      )


    }
}

// const ShowSidebar = () => {
//   const [show, setShow] = useState(false);

//   const handleClick = () => {
//     setShow(!show);
//   };

//   return (
//     <div className={show ? "my-sidebar my-close" : "my-sidebar"}>
//       <Sidebar />
      
//       <div className="my-sidebar-handler">
//         <i
//           className={show ? "fa-solid fa-indent" : "fa-solid fa-outdent"}
//           onClick={() => handleClick()}
//         ></i>
       
//         </div>

//     </div>
//   );
// };
const ShowSidebar = () => {
  const { show } = useContext(Context);

  return (
    <div className={show ? "my-sidebar my-close" : "my-sidebar"}>
        <Sidebar />
    </div>
  );
};


export { AddButton, EditButton, DeleteButton, ShowSidebar, DeleteConfirmation };
