import React from 'react';
import axios from 'axios';
import { BASE_URL, FORMATS, CATEGORIES, SIZES, HEADERS } from '../../common/api';
import Table from '../../assets/js/Table';
import Pagination from "../../common/pagination";
import { Form } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import Select from 'react-select';



export default class SingleWarehouse extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            formats: [],
            categories: [],
            collections: [],
            designs: [],
            sizes: [],
            filters: {},
            currentPage: 1,
            totalPages: 1,
        }
    }

  
  loadOptions = (inputValue, dataType) => {
    return new Promise((resolve, reject) => {
      // using setTimeout to emulate a call to server
      setTimeout(() => {
        resolve(this.filter(inputValue, dataType));
      }, 2000);
    });
  };

  filter = async (inputValue, dataType) => {
    const data = this.state[dataType];
    return await data.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };


  handleFilter = (event, stateVariable, selectType=0) => {

    if (selectType === 1) {
    const selectedValues = [];
    const filteredValues = [];
    event.map((item) => {
      selectedValues.push({ value: item.value, label: item.label });
      filteredValues.push(item.value);
      return { selectedValues, filteredValues };
    });
    this.setState({
      [stateVariable]: selectedValues,
      filters: {
        ...this.state.filters,
        [stateVariable]: filteredValues.join(","),
      },
    });
    }
    else if (selectType === 2) {
      const value = event ? event.value : '';
      this.setState({
      filters: {
        ...this.state.filters,
        [stateVariable]: value
      },
    })
    } else {
      this.setState({
      filters: {
        ...this.state.filters,
        [stateVariable]: event.target.value,
      },
    })
    }
  };

  getData = (pageNumber=1) => {
        this._isMounted = true;
        const {id} = this.state;
        axios.get(BASE_URL + 'units/warehouse/' + id + `?page=${pageNumber}`, {params: this.state.filters})
            .then(res => this.setState({formats: res.data.results, totalPages: Math.ceil(res.data.count / 21)}));
    }

  handlePageChange(pageNumber) {
    this.setState({
      currentPage: pageNumber
    });
    this.getData(pageNumber)
  }



  async componentDidMount() {
        this.getData();
        await axios.get(BASE_URL + "collections-list", HEADERS).then((res) => {
      const arr = [];
      const data = res.data;
      data.map((el) => {
        return arr.push({ value: el.id, label: el.nameUz });
      });
      this.setState({ collections: arr });
    });
      await axios.get(BASE_URL + "products-list", HEADERS).then((res) => {
      const arr = [];
      const data = res.data;
      data.map((el) => {
        return arr.push({ value: el.id, label: el.vId });
      });
      this.setState({ designs: arr });
    });
      await axios
      .get(BASE_URL + SIZES + "?paginate=false", HEADERS)
      .then((res) => {
        const arr = [];
        const data = res.data;
        data.map((el) => {
          return arr.push({
            value: el.vSizeId,
            label: `${el.width}x${el.length}`,
          });
        });
        this.setState({ sizes: arr });
      });
      await axios.get(BASE_URL + CATEGORIES, HEADERS)
    .then(res => {
      const arr = [];
      const data = res.data;
      data.map((el) => {
        return arr.push({ value: String(el.id), label: el.nameUz });
      });
      this.setState({ categories: arr });
    })

    }

    async componentDidUpdate(prevProps, prevState) {
    if ((prevState.filters !== this.state.filters)) {
      await this.getData();
    }
  }

  render() {
    const {formats, currentPage, totalPages, categories, collections, designs, sizes, filters} = this.state;
    return (
        <>
               {formats ? (
                <>
                <div className="filters">
                    <div className="row">
                        <div className="col-4">
                            <Form.Label>Kategoriya:</Form.Label>
                            <Select
                    options={categories}
                    isClearable={true}
                    placeholder="Select..."
                    onChange={(event) => this.handleFilter(event, "category", 2)}
                    />
                        </div>
                        <div className="col-4">
                            <Form.Label>Kolleksiya:</Form.Label>
                            <AsyncSelect
                    defaultOptions={collections.slice(0, 10)}
                    cacheOptions
                    isClearable={true}
                    loadOptions={(inputValue) =>
                      this.loadOptions(inputValue, "collections")
                    }
                    onChange={(event) => this.handleFilter(event, "collection", 2)}
                            />
                        </div>
                        <div className="col-4">
                             <Form.Label>Dizayn:</Form.Label>
                  <AsyncSelect
                    defaultOptions={designs.slice(0, 10)}
                    cacheOptions={10}
                    isClearable={true}
                    loadOptions={(inputValue) =>
                      this.loadOptions(inputValue, "designs")
                    }
                    onChange={(event) => this.handleFilter(event, "design", 2)}
                  />
                        </div>
                    </div>
                    <div className="row pt-2">
                        <div className="col-4">
                            <Form.Label>Shakl:</Form.Label>
                            <Select
                    options={[{value: 0, label: "Oval"},
                              {value: 1, label: "To'rtburchak"},
                              {value: 2, label: "Metrik"},
                              {value: 5, label: "Noodatiy"},]}
                    isClearable={true}
                    placeholder="Select..."
                    onChange={(event) => this.handleFilter(event, "shape", 2)}
                    />
                        </div>
                        <div className="col-4">
                            <Form.Label>O'lcham:</Form.Label>
                            <AsyncSelect
                    defaultOptions={sizes.slice(0, 10)}
                    isClearable={true}
                    cacheOptions
                    loadOptions={(inputValue) =>
                      this.loadOptions(inputValue, "sizes")
                    }

                    onChange={(event) => this.handleFilter(event, "size", 2)}
                            />
                        </div>
                        <div className="col-4">
                             <Form.Label>Statusi</Form.Label>
                  <Select
                    options={[{value: true, label: "Aktiv"}, {value: false, label: "Passiv"}, {value: "all", label: "Barchasi"}]}
                    isClearable={true}
                    placeholder="Select..."
                    onChange={(event) => this.handleFilter(event, "status", 2)}
                    value={(filters.status === true && {value: filters.status, label: "Aktiv"}) || (filters.status === false && {value: filters.status, label: "Passiv"}) || (filters.status === "all" && {value: filters.status, label: "Barchasi"})} />
                        </div>
                    </div>
                    <div className="row pt-2">
                <div className="col-6">
                  <Form.Label>Soni (dan):</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={(event) => this.handleFilter(event, "count_min")}
                  />
                </div>
                <div className="col-6">
                  <Form.Label>Soni (gacha):</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={(event) => this.handleFilter(event, "count_max")}
                  />
                </div>
              </div>
              <div className="row pt-2">
                <div className="col-6">
                  <Form.Label>Narx (dan):</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={(event) => this.handleFilter(event, "price_min")}
                  />
                </div>
                <div className="col-6">
                  <Form.Label>Narx (gacha):</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={(event) => this.handleFilter(event, "price_max")}
                  />
                </div>
              </div>
                </div>
                 <table className="table table-hover">
                    <thead className="thead-light">
                    <tr>
                        <th>Artikul</th>
                        <th>Nomlanishi</th>
                        <th>Miqdori</th>

                    </tr>
                    </thead>
                    <tbody>
                    {formats.map((format, index) => (
                        <tr key={index}>
                        <td>{format.articul}</td>
                        <td>{format.product}</td>
                        <td>{format.count}</td>
                        </tr>
                    )) }
                    </tbody>
                </table>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(num)=>this.handlePageChange(num)}
        /> </>
               ) : (
                <h1>No data</h1>
               )}
            </>
        )
    }
}