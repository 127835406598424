import React, { Component } from "react";
import axios from "axios";
import { BASE_URL, HEADERS } from "../../common/api";
import {Button } from "react-bootstrap";
import { ModalDialog } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";



export default class Machine extends Component {

 constructor(props){
    super(props);
    this.state = {
        image: "", 
        date: "",
        imagePreview: "",
        id: 0,
        show: false, 
        selectedImage: null, 
        permisstion : false

    }
 }

 componentDidMount() {
    const searchParams = new URLSearchParams(window.location.pathname);
    // console.log(window.location.pathname);

    if (window.location.pathname === "/machine") {
        this.state.permisstion = true
    }

   
    axios.get(BASE_URL + "machine/").then((res) => {
        
        this.setState({
            date: res.data[0]?.updated_at.substring(0, 10),
            imagePreview: res.data[0]?.image,
            id: res.data[0]?.id
        });
    }).catch((err) => {
        console.log(err);
    })
 }

 handleChange = (event) => {
    const file = event.target.files[0];
    this.setState({image: file});
    
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        this.setState({ imagePreview: reader.result });
    };

   

   
}
showModal = (image) => {
    this.setState({ show: true, selectedImage: image });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();

    if (this.state.image) {
        formData.append("image", this.state.image, this.state.image.name);
    }
    axios.patch(BASE_URL + "machine/" + this.state.id + "/",  formData, HEADERS).then((res) => {
        console.log(res.data);
    }).catch((err) => {
        console.log(err);
    })
    // axios.post(BASE_URL + "machine/",  formData, HEADERS).then((res) => {
    //     console.log(res.data);
    // }).catch((err) => {
    //     console.log(err);
    // })
}


 render () {
    const {imagePreview, selectedImage, show, permisstion, date} = this.state;
   console.log(date);
    return (
        <div>
            { permisstion && (<div className="file-upload">
                        <label htmlFor="file-input">
                          <i className="fa-regular fa-image fa-2xl pr-2"></i>{" "}
                          Rasm yuklash
                        </label>
                        <input
                          type="file"
                          className="form-control-file"
                          id="file-input"
                          name="image"
                          onChange={this.handleChange}
                        />
            </div>)}
            <div className="text-center">{date} </div>
            {imagePreview && (
                          <img
                            src={imagePreview}
                            alt="ifoto"
                            className="img-fluid p-4"
                            style={{ width: "500px", height: "500px" }}
                            onClick={() => this.showModal(imagePreview)}
                          />
                        )}
            {permisstion && (<div >
            <Button className="m-2" onClick={this.handleSubmit}>Saqlash</Button>

            </div>)}

            <ModalDialog>
                <Modal
                    dialogClassName="custom-modal"
                    show={show}
                    onHide={()=> this.hideModal()}

                >
                    {selectedImage && (
                        <img src={selectedImage} alt="Selected" style={{ width: "100%" }} onClick={() => this.hideModal()}/>
                    )}

                </Modal>
            </ModalDialog>
        </div>
        
    )
 }

}