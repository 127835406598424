import React from "react";
import axios from "axios";
import { BASE_URL, SOCIALS, HEADERS } from "../../common/api";
import Table from "../../assets/js/Table";
import { Modal, Form, Button } from "react-bootstrap";
import Error from "../../assets/js/Error";

const columns = [
  { field: "image", header: "Rasmi" },
  { field: "link", header: "Havola" },
  { field: "isActive", header: "Statusi" },
];

export default class Social extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      link: "",
      image: null,
      id: "",
      isActive: true,
      status: 0,
    };
    this.showModal = this.showModal.bind(this);
    this.addOrUpdate = this.addOrUpdate.bind(this);
    this.renewState = this.renewState.bind(this);
    this.get = this.get.bind(this);
    this.delete = this.delete.bind(this);
    this.getTitle = this.getTitle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
    this._isMounted = false;
  }

  renewState = () => {
    this.setState({
      show: false,
      link: "",
      image: null,
      id: "",
      isActive: true,
    });
  };
  // Show or hide modal depending on show state
  // setState sets default values in order to erase state after every modal show
  showModal = () => {
    this.setState({ show: true });
  };

  onShowAlert = ()=>{
    this.setState({visible:true},()=>{
      window.setTimeout(()=>{
        this.setState({visible:false})
      },2000)
    });
  }

  hideModal = () => {
    this.setState({ show: false });
    this.renewState();
  };

  // Handle change in event
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  // Image handle
  onImageChange = (event) => {
    this.setState({ image: event.target.files[0] });
  };
  // TITLE change
  getTitle = () => {
    const { id } = this.state;
    if (id) {
      return <h3>O'zgartirish</h3>;
    } else {
      return <h3>Yangi qo'shish</h3>;
    }
  };

  // GET data
  componentDidMount() {
    this._isMounted = true;
    this._isMounted &&
      axios
        .get(BASE_URL + SOCIALS, HEADERS)
        .then((res) => {
          this._isMounted &&
            this.setState({ data: res.data, status: res.status }, ()=>console.log(this.state.data));
        })
        .catch((err) => {
          this._isMounted && this.setState({ status: err.response.status });
        });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // GET data depending on id in order to edit it
  get = (row) => {
    this.showModal();
    this.setState({
      link: row.link,
      priority: row.priority,
      image: row.image,
      id: row.id,
      isActive: row.isActive,
    });
  };

  // DELETE data
  delete = (id, event) => {
    event.preventDefault();
    const { data } = this.state;
    axios.delete(BASE_URL + SOCIALS + id, HEADERS).then((res) => {
      const updatedData = data.filter((banner) => banner.id !== id);
      this.setState({ data: updatedData });
    });
  };

  
  // POST or UPDATE data
  // Function collects all inputs and depending on whether id is exist or not, decides put or post
  addOrUpdate = (event) => {
    event.preventDefault();
    const { image, id, data } = this.state;

    let formData = new FormData();
    if (image !== null && image.name) {
      formData.append("image", image, image.name);
    }
    formData.append("link", this.state.link);
    formData.append("isActive", this.state.isActive);
    if (id) {
      axios
        .patch(BASE_URL + SOCIALS + id + "/", formData, HEADERS)
        .then((res) => {
        const index = data.findIndex((item) => item.id === id);
        data[index] = res.data;
        this.setState({ data });
        this.hideModal();
        }).catch(error => console.log(error.response));
    } else {
      axios
        .post(BASE_URL + SOCIALS, formData, HEADERS)
        .then(
          (res) =>
            this.setState({ data: [...this.state.data, res.data] }),
          this.renewState()
        )
        .catch((error) => {
          console.log(error.response);
        });
    }
  };

  render() {
    const {
      data,
      image,
      link,
      isActive,
      show,
      id,
      status,
    } = this.state;
    if (!data) {
      return null;
    }
    return (
      <>
        {status === 200 ? (
          <>
            <Modal
              show={show}
              onHide={() => {
                this.hideModal();
              }}
              animation={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>{this.getTitle()}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={(event) => this.addOrUpdate(event)}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                    key={id}
                  >
                    <Form.Label>Link</Form.Label>
                    <Form.Control
                      type="text"
                      name="link"
                      value={link}
                      onChange={this.handleChange}
                    />
                    <Form.Label>Rasm:</Form.Label>
                    {image && <img src={image} alt="logo" style={{"width": "90px", "height": "90px"}} className="p-2" />}
                    <Form.Control
                      type="file"
                      className="file"
                      name="image"
                      onChange={this.onImageChange}
                    />
                    <Form.Label>Statusi:</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleChange}
                      name="isActive"
                      value={isActive}
                    >
                      <option value="true">active</option>
                      <option value="false">NOT active</option>
                    </Form.Control>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={this.showModal}
                  >
                    Saqlash
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
            <Table
              addFunc={this.showModal}
              data={data}
              columns={columns}
              title="Ijtimoiy tarmoqlar"
              editFunc={(row) => this.get(row)}
              deleteFunc={(id, event) => this.delete(id, event)}
            />
          </>
        ) : (
          <Error status={status} />
        )}
      </>
    );
  }
}
