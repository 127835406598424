import React from 'react';
import axios from 'axios';
import { BASE_URL, CONTACTS, HEADERS } from "../../common/api";
import PhoneInput from 'react-phone-number-input/input';
import { Alert } from 'react-bootstrap';


export default class Contact extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            data: [],
            visible: false,
            main_phone: '',
            extra_phone: '',
        }

    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    onShowAlert = ()=> {
    this.setState({visible:true},()=>{
      window.setTimeout(()=>{
        this.setState({visible:false})
      },2000)
    });
  }

    handleSubmit = (event, item) => {
        event.preventDefault();
        let data = {
            main_phone: this.state.main_phone,
            extra_phone: this.state.extra_phone,
            manzilUz: this.state.manzilUz,
            manzilRu: this.state.manzilRu,
            aboutUz: this.state.aboutUz,
            aboutRu: this.state.aboutRu,
            email: this.state.email,
            id: this.state.id
        }
        axios.put(BASE_URL + CONTACTS + item.id + '/', data, HEADERS).then(
            this.onShowAlert()
        )
    }

    

    componentDidMount() {
        axios.get(BASE_URL + CONTACTS, HEADERS)
        .then(res => {
            this.setState({data: res.data, main_phone: res.data[0].main_phone, extra_phone: res.data[0].extra_phone});
        })
        .catch((err) => {
            console.log(err);
        })
    }



    render() {
        const {data, visible} = this.state;
        
        return (
            <>
            {data.map((item) => (
                <div className="pt-2 m-2" key={item.id}>
                
                <div className="row">
                    
                    <div className="col-4">
                        
                        <form>
                            <div className="form-group">
    <label htmlFor="textInput">Asosiy telefon raqam</label>
    
    <PhoneInput className="form-control" id="textInput" 
  value={item.main_phone}
  onChange={phone => this.setState({main_phone: phone})}/>
  </div>
                        </form>
                    </div>
                    <div className="col-4">
                        <form>
                            <div className="form-group">
    <label htmlFor="textInput">Qo'shimcha telefon raqam</label>
    <PhoneInput className="form-control" id="textInput" 
  value={item.extra_phone}
  onChange={phone => this.setState({extra_phone: phone})}/>
  </div>
                        </form>
                    </div>
                     <div className="col-4">
                        
                        <form>
                            <div className="form-group">
    <label htmlFor="textInput">E-mail</label>
    <input type="text" className="form-control" id="textInput" 
    defaultValue={item.email} name="email"
    aria-describedby="emailHelp" onChange={this.handleChange}  />
  </div>
                        </form>
                    </div>
                </div>
            
                <div className="row">
                    <div className="col-6">
                        <form>
                            <div className="form-group">
    <label htmlFor="textInput">Manzil</label>
    <input type="text" className="form-control" id="textInput" 
    defaultValue={item.manzilUz} name="manzilUz"
    aria-describedby="emailHelp" onChange={this.handleChange}  />
  </div>
                        </form>
                    </div>
                    <div className="col-6">
                        
                        <form>
                            <div className="form-group">
    <label htmlFor="textInput">Address</label>
    <input type="text" className="form-control" id="textInput" aria-describedby="emailHelp" defaultValue={item.manzilRu} name="manzilRu" onChange={this.handleChange}  />
  </div>
                        </form>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <form>
                            <div className="form-group">
    <label htmlFor="textInput">Haqida Uz</label>
    <input type="text" className="form-control" id="textInput" aria-describedby="emailHelp" defaultValue={item.aboutUz} name="aboutUz" onChange={this.handleChange}  />
  </div>
                        </form>
                    </div>
                    <div className="col-6">
                        <form>
                            <div className="form-group">
    <label htmlFor="textInput">Haqida Ru</label>
    <input type="text" className="form-control" id="textInput" aria-describedby="emailHelp" defaultValue={item.aboutRu} name="aboutRu" onChange={this.handleChange}  />
  </div>
                        </form>
                    </div>
                </div>
                <button className='btn btn-primary mt-3' onClick={(event)=>this.handleSubmit(event, item)}>Saqlash</button>
                {visible && <Alert variant="success" className="mt-3" style={{"position": "absolute", "top": "0", "right": "45%"}}>Ma'lumotlar muvaffaqiyatli saqlandi</Alert>}
            </div>

            )
            )}
            
            </>
        )
    }
}