import React from "react";
import { AddButton, EditButton, DeleteButton } from "./Button";
import { Image } from "react-bootstrap";
import noimage from "../static/image/noimage.png";

class Table extends React.Component {
  currencyFormat = (num) => {
    if (num) {
      num = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    }
    return num;
  };
  render() {
    const { data, columns, title, addFunc, editFunc, deleteFunc, filterFunc } =
      this.props;
    return (
      <div>
        {addFunc && (
          <div>
            <AddButton onClick={addFunc} />
          </div>
        )}
        <br />
        <h5>{title}</h5>
        <table className="table">
          <thead className="">
            <tr>
              {columns.map((column, index) => (
                <th key={index} scope="col">
                  {column.header}
                </th>
              ))}
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                {columns.map((col, index) =>
                  col.field === "logo" ||
                  col.field === "image" ||
                  col.field === "imageUz" ||
                  col.field === "imageRu" ? (
                    <td
                      key={index}
                      style={{
                        wordWrap: "break-word",
                        minWidth: "120px",
                        maxWidth: "120px",
                      }}
                    >
                      <Image
                        src={row[col.field] ? row[col.field] : noimage}
                        width="50"
                        height="50"
                      />
                    </td>
                  ) : col.field === "isActive" ? (
                    <td
                      key={index}
                      style={{
                        wordWrap: "break-word",
                        minWidth: "100px",
                        maxWidth: "100px",
                      }}
                    >
                      <p
                        className={
                          row[col.field]
                            ? "badge badge-light text-success"
                            : "badge badge-light text-danger"
                        }
                        role="alert"
                      >
                        {row[col.field] ? "Aktiv" : "Passiv"}
                      </p>
                    </td>
                  ) : col.field === "price" ? (
                    <td
                      key={index}
                      style={{
                        wordWrap: "break-word",
                        minWidth: "160px",
                        maxWidth: "160px",
                      }}
                    >
                      {this.currencyFormat(row[col.field])} so`m
                    </td>
                  ) : (
                    <td
                      key={index}
                      style={{
                        wordWrap: "break-word",
                        minWidth: "160px",
                        maxWidth: "160px",
                      }}
                    >
                      {row[col.field]}
                    </td>
                  )
                )}
                <td
                  style={{
                    wordWrap: "break-word",
                    minWidth: "160px",
                    maxWidth: "160px",
                  }}
                >
                  {editFunc && <EditButton onClick={() => editFunc(row)} />}
                  {deleteFunc && (
                    <DeleteButton
                      onClick={(event) => deleteFunc(row.id, event)}
                    />
                  )}
                  {filterFunc && (
                    <button
                      className="btn btn-light border-secondary"
                      onClick={() => filterFunc(row.id, row.vId)}
                      title="To'liq ko'rish"
                    >
                      <i className="fa-solid fa-chevron-right"></i>
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Table;
