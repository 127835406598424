import React, {createRef } from "react";
import axios from "axios";

import {
  BASE_URL,
  ORDERS,
  HEADERS,
  CURRENTUSER,
  REGIONS,
  ADMIN,
  DRIVERS,
  PAYMENT,
  SHOPS,
} from "../../common/api";
import "../../assets/order.css";
import { Form } from "react-bootstrap";
import Pagination from "../../common/pagination";
import Select from "react-select";

import PrintableCheck,  { generatePDF }  from "../../common/printableCheck";


const orderStatus = [
  { value: 0, label: "Ochiq" },
  { value: 1, label: "Qabul qilindi" },
  { value: 2, label: "Jarayonda" },
  { value: 3, label: "Yolda" },
  { value: 4, label: "Yetkazildi" },
  { value: 5, label: "Bekor qilindi" },
  { value: 6, label: "Qaytarildi" },
];

const deliveryStatus = [
  { value: "Waiting", label: "Kutilmoqda" },
  { value: "Delivery attached", label: "Deliver biriktirildi" },
  { value: "Delivery accepted", label: "Deliver qabul qilgan" },
  { value: "Client accepted", label: "Mijoz qabul qilgan" },
  { value: "Client rejected", label: "Mijoz rad etgan" },
];

const paymentStatus = [
  { value: 0, label: "Umuman to'lanmagan" },
  { value: 1, label: "Termianl orqali to'langan" },
  { value: 2, label: "Deliver orqali to'langan" },
];

export default class SpecialOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      items: [],
      filters: {},
      currentPage: 1,
      totalPages: 1,
      filtersVisible: false,
    
    };
    this.overallPrice = 0;
    this._isMounted = false;
    this.today = new Date();
    this.componentRef = React.createRef();
  

  }

 
  toggleFiltersVisibility = () => {
    this.setState((prevState) => ({
      filtersVisible: !prevState.filtersVisible,
    }));
  };

  currencyFormat = (num) => {
    return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
  };

  edit = (id) => {
    this.props.history.push(`/single-order/${id}/1`);
  };

  handleFilter = (event, stateVariable, selectType = 0) => {
    const updateFilters = () => {
      const { filters } = this.state;
      const queryString = Object.entries(filters)
        .reduce((acc, [key, value]) => {
          if (value !== "") {
            // Check for empty strings
            return [...acc, `${key}=${value}`];
          }
          // If the value is an empty string, skip this filter
          return acc;
        }, [])
        .join("&");
      this.props.history.push(`?${queryString}`);
      // Remove the filters that have an empty string value
      const filteredFilters = Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => value !== "")
      );
      this.setState({ filters: filteredFilters });
    };

    if (selectType === 2) {
      const value = event ? event.value : "";
      this.setState(
        {
          filters: {
            ...this.state.filters,
            [stateVariable]: value,
          },
        },
        updateFilters
      );
    } else {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            [stateVariable]: event.target.value,
          },
        },
        updateFilters
      );
    }
  };

  handlePageChange(pageNumber) {
    this.setState({
      currentPage: pageNumber,
    });
    this.getData(pageNumber);
  }

  getData = async (pageNumber = 1) => {
    this._isMounted = true;   
    this._isMounted &&
      await axios
        .get(
          BASE_URL + `special-orders/?page=${pageNumber}&warehouse_id=${this.warehouse_id}`,
          { params: this.state.filters },
          HEADERS
        )
        .then((res) => {
          const orders = res.data.results;
          console.log(orders);
          this.setState({
            orders: orders,
            totalPages: Math.ceil(res.data.count / 500),
          });
        });
  };

  getSelectedValues = (filterProp, stateProp) => {
    const { filters } = this.state;
    const selectedValues =
      filters[filterProp] && filters[filterProp].split(",").map(Number);
    const arr = [];
    selectedValues &&
      selectedValues.map((el) => {
        const exist = this.state[stateProp].find((col) => col.value == el);
        return arr.push(exist);
      });
    return arr.length ? arr : [];
  };

  async componentDidMount() {
    this._isMounted = true;
    await axios.get(BASE_URL + CURRENTUSER, HEADERS).then((res) => {
      const regions = res.data.regions;
      const filterRegions = regions.join(",");
      this.warehouse_id = res.data.warehouse;
      this.setState({
        filters: {
          ...this.state.filters,
          // region: filterRegions,
          // warehouse_id: warehouse_id,
        },
      });
    });
    // this.getData();

    this._isMounted &&
      axios.get(BASE_URL + REGIONS, HEADERS).then((res) => {
        const arr = [];
        const data = res.data;
        data.map((el) => {
          return arr.push({ value: el.id, label: el.nameUz });
        });
        this.setState({ regions: arr });
      });
    this._isMounted &&
      axios.get(BASE_URL + ADMIN, HEADERS).then((res) => {
        const arr = [];
        const data = res.data;
        data.map((el) => {
          return arr.push({
            value: el.id,
            label: `${el.first_name}  ${el.last_name}`,
          });
        });
        this.setState({ managers: arr });
      });
    this._isMounted &&
      axios.get(BASE_URL + DRIVERS, HEADERS).then((res) => {
        const arr = [];
        const data = res.data;
        data.map((el) => {
          return arr.push({
            value: el.id,
            label: `${el.first_name}  ${el.last_name}`,
          });
        });
        this.setState({ drivers: arr });
      });
    this._isMounted &&
      axios.get(BASE_URL + PAYMENT, HEADERS).then((res) => {
        const arr = [];
        const data = res.data;
        data.map((el) => {
          return arr.push({ value: el.id, label: el.title });
        });
        this.setState({ payments: arr });
      });
    this._isMounted &&
      axios.get(BASE_URL + SHOPS, HEADERS).then((res) => {
        const arr = [];
        const data = res.data;
        data.map((el) => {
          return arr.push({ value: el.id, label: el.name_uz });
        });
        this.setState({ shops: arr });
      });

    const searchParams = new URLSearchParams(window.location.search);
    const filters = {};
    for (let [key, value] of searchParams.entries()) {
      if (value === "true" || value === "false") {
        filters[key] = value === "true";
      } else if (isNaN(Number(value))) {
        filters[key] = value;
      } else {
        filters[key] = value;
      }
    }
    this.setState({ ...filters, filters }, () => {
      this.getData();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.search !== prevProps.location.search) {
      this.getData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      orders,
      filters,
      currentPage,
      totalPages,
      regions,
      managers,
      drivers,
      payments,
      shops,
      filtersVisible,
    } = this.state;
    return (
      <>
        {filtersVisible && (
          <div className="filters">
            <div className="row">
              <div className="col">
                <Form.Label>Buyurtma statusi:</Form.Label>
                <Select
                  options={orderStatus}
                  isClearable={true}
                  placeholder="Tanlang..."
                  onChange={(event) =>
                    this.handleFilter(event, "order_status", 2)
                  }
                  defaultValue={orderStatus.find(
                    (el) => el.value == this.state.filters.order_status
                  )}
                />
              </div>
              <div className="col">
                <Form.Label>To'lov statusi:</Form.Label>
                <Select
                  options={paymentStatus}
                  isClearable={true}
                  placeholder="Tanlang..."
                  onChange={(event) =>
                    this.handleFilter(event, "payment_status", 2)
                  }
                  defaultValue={paymentStatus.find(
                    (el) => el.value == this.state.filters.payment_status
                  )}
                />
              </div>
              {/* <div className="col">
              <Form.Label>Yetkazma statusi:</Form.Label>
              <Select
                options={deliveryStatus}
                isClearable={true}
                placeholder="Tanlang..."
                onChange={(event) =>
                  this.handleFilter(event, "delivery_status", 2)
                }
                defaultValue={deliveryStatus.find(
                  (el) => el.value == this.state.filters.delivery_status
                )}
              />
            </div> */}
            </div>
            <div className="row pt-2">
              {/* <div className="col">
              <Form.Label>Kuryer:</Form.Label>
              <Select
                options={drivers}
                isClearable={true}
                placeholder="Tanlang..."
                onChange={(event) => this.handleFilter(event, "deliver", 2)}
                defaultValue={
                  drivers &&
                  drivers.find((el) => el.value == this.state.filters.deliver)
                }
              />
            </div> */}
              <div className="col">
                <Form.Label>To'lov turi:</Form.Label>
                <Select
                  options={payments}
                  isClearable={true}
                  placeholder="Tanlang..."
                  onChange={(event) =>
                    this.handleFilter(event, "payment_met", 2)
                  }
                  defaultValue={
                    payments &&
                    payments.find(
                      (el) => el.value == this.state.filters.payment_met
                    )
                  }
                />
              </div>
              {/* <div className="col">
              <Form.Label>Shourum:</Form.Label>
              <Select
                options={shops}
                isClearable={true}
                placeholder="Tanlang..."
                onChange={(event) => this.handleFilter(event, "showroom", 2)}
                defaultValue={
                  shops &&
                  shops.find((el) => el.value == this.state.filters.showroom)
                }
              />
            </div> */}
              <div className="col">
                <Form.Label>Narx (dan):</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Kiriting"
                  name="name"
                  onChange={(event) => this.handleFilter(event, "price_min")}
                  defaultValue={filters.price_min || ""}
                />
              </div>
              <div className="col">
                <Form.Label>Narx (gacha):</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Kiriting"
                  name="name"
                  onChange={(event) => this.handleFilter(event, "price_max")}
                  defaultValue={filters.price_max || ""}
                />
              </div>
            </div>
            <div className="row pt-2">
              <div className="col">
                <Form.Label>Sana (dan):</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Kiriting"
                  name="date_min"
                  onChange={(event) => this.handleFilter(event, "date_min")}
                  defaultValue={filters.date_min || ""}
                />
              </div>
              <div className="col">
                <Form.Label>Sana (gacha):</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Kiriting"
                  name="date_max"
                  onChange={(event) => this.handleFilter(event, "date_max")}
                  defaultValue={filters.date_max || ""}
                />
              </div>
              <div className="col">
                <Form.Label>Ismi:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Kiriting"
                  name="name"
                  onChange={(event) => this.handleFilter(event, "name")}
                  defaultValue={filters.name || ""}
                />
              </div>
              <div className="col">
                <Form.Label>ID:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Kiriting"
                  name="id"
                  onChange={(event) => this.handleFilter(event, "id")}
                  defaultValue={filters.id || ""}
                />
              </div>
              <div className="col">
                <Form.Label>Telefon:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Kiriting"
                  name="phone"
                  onChange={(event) => this.handleFilter(event, "phone")}
                  defaultValue={filters.phone || ""}
                />
              </div>
            </div>
          </div>
        )}
        <div className="toggle-filters-btn text-right ml-auto ">
          Фильтры
          <i
            onClick={this.toggleFiltersVisibility}
            className={`btn cursor-pointer mb-5 mt-5 ${
              filtersVisible
                ? "fa-solid fa-filter-circle-xmark fa-xl"
                : "fa-solid fa-filter fa-xl"
            }`}
          ></i>
        </div>
        {/* <div className="orders">
          {orders &&
            orders.map((item) => (
              <div
                className="order-card"
                key={item.id}
                onClick={() => this.edit(item.id)}
              >
                <div className="customer-name">
                  {item.id}. {item.name}
                </div>
                <div className="customer-info">
                  <div className="customer-address">
                    <span className="address">
                      <i className="fa-solid fa-location-dot"></i>{" "}
                      {item.region_name}
                    </span>
                    <span className="date">
                      {new Date(item.expected_delivery_date).toLocaleDateString(
                        "ru-RU"
                      )}
                    </span>
                  </div>
                  <div className="customer-phone">{`+${item.phone}`}</div>
                </div>
                <div className="main-info">
                  <div>
                    Buyurtma narxi:{" "}
                    <span className="sub-info">
                      {this.currencyFormat(String(item.price))} so'm
                    </span>
                  </div>
                  <div>
                    Chegirma: <span className="sub-info">0 so'm</span>
                  </div>
                  <div>
                    Naqd summa:{" "}
                    <span className="sub-info">
                      {this.currencyFormat(String(item.price))} so'm
                    </span>
                  </div>
                  <div>
                    Tizim orqali: <span className="sub-info">0 so'm</span>
                  </div>
                  <div>
                    Yetkazuvchi orqali: <span className="sub-info">0 so'm</span>
                  </div>
                </div>
                <span
                  className={`badge order-status ${
                    item.order_status !== 5 ? "badge-success" : "badge-danger"
                  }`}
                >
                  {item.status}
                </span>
              </div>
            ))}
        </div> */}
        <div style={{ maxWidth: '100vw'}}>
        <table className="table">
          <thead style={{position: 'sticky', top: '0',  backgroundColor: "#f7f7f7", zIndex: '1'}} >
            <tr>
              <th>ID</th>
              <th>Sotuvchi</th>
              <th>Mijoz</th>
              <th className="text-center">Mahsulot</th>
              <th>Magazin</th>
              <th>Telefon</th>
              <th>Yaratildi</th>
              <th>Kun qoldi</th>
              <th>Narxi</th>
              <th>Holati</th>
              <th>Check</th>
            </tr>
          </thead>
          <tbody>
            {orders &&
              orders.map((item, index) => (
                <tr key={index} 
                
                >
                  <td>{item.id}</td>
                  <td>{item.saler_firstname && item.saler_firstname + " " + item.saler_lastname}</td>
                  <td>{item.name}</td>
                  <td style={{minWidth: '500px' }}>
                    <ol>
                      {item.order_items.map((el, index) => (
                        <li key={index} className="row">
                          {" "}
                          <strong className="col-5 "> {el.product}</strong>
                          <p className="mr-2">{el.quantity} {(el._type === '2' && el.image === null) ? "sm" : (el._type === '0' || el._type ==='1' ) ? "ta" : "m^2" }</p>
                          <p className="ml-1 col-2 align-middle ">{el.width}x{(el._type === '2' || el._type === '5') ? el.quantity : el.length} </p>
                          {/* <p>{el.warehouse_name && el.warehouse_name + "dan"}</p> */}
                        </li>
                      ))}
                    </ol>
                  </td>
                  <td>{item.warehouse_name ? item.warehouse_name : ""}</td>
                  <td>{item.phone}</td>
                  <td>{ new Date(item.created_at).toLocaleDateString("ru-RU") }</td>
                  <td>
                  {

                 item.expected_delivery_date ? 
                 Math.floor((new Date(item.expected_delivery_date).getTime() -  this.today.getTime())/(1000 * 3600 * 24)) + 1 : ""
  }</td>
                  <td>{item.price} so'm</td>
                  <td
                   
                  >
                    <span  className={`badge ${
                      item.order_status !== 5 ? "badge-success" : "badge-danger"
                    }`}>
                    {item.status}

                    </span>
                  </td>
                  <td>
                  {/* <ReactToPrint
                  trigger={() => <i className="fa-solid fa-print"></i>}
                  content={() => this.componentRef.current}
                /> */}
                {/* <ReactToPrint
                  trigger={() => <i className="fa-solid fa-print"></i>}
                  content={() => this.componentRef.current}
                />
                <div style={{ display: 'none' }}>
                  <PrintableCheck ref={this.componentRef} item={item} />
                </div> */}
                <button className="btn btn-primary" onClick={() => generatePDF(item)}>Print</button>
                  
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(num) => this.handlePageChange(num)}
        />
        
      </>
    );
  }
}
