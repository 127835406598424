import React from 'react';
import axios from 'axios';
import { BASE_URL, SHOPS, HEADERS } from '../../common/api';
import Table from "../../assets/js/Table";

const columns = [
    { field: "id", header: "ID" },
    { field: "image", header: "Rasm" },
    { field: "region_name", header: "Hudud"},
    { field: "main_phone", header: "Telefon raqami" },
]


export default class Showroom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        }
        this._isMounted = false;
    }

    showModal = () => {
    this.setState({ show: true });
    };


    hideModal = () => {
        this.setState({ show: false });
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onImageChange = (event) => {
        this.setState({ image: event.target.files[0] });
    };

    componentDidMount() {

        axios.get(BASE_URL + SHOPS, HEADERS )
        .then(res => this.setState({data: res.data}))
    }

    getTitle = () => {
    return this.state.id ? "O'zgartirish" : "Yangi qo'shish";
  };

    add = () => {
    this.props.history.push("/single-showroom/add");
    }

  get = (id) => {
        this.props.history.push(`single-showroom/${id}`)
    }

  delete = (id, event) => {
    event.preventDefault();
    const { data } = this.state;
    axios
      .delete(BASE_URL + SHOPS + id, HEADERS)
      .then((res) => {
        const updatedData = data.filter((brand) => brand.id !== id);
        this.setState({ data: updatedData });
      })
      .catch((err) => {
        this.setState({ status: err.response.status });
      });
  };




    render() {
        const {data} = this.state;
        return (
            <>
            <Table
            columns={columns}
            data={data}
            addFunc={(id)=>this.add(id)}
            filterFunc={(id)=> this.get(id)}
            deleteFunc={(event, id) => this.delete(event, id)}
            />
            </>
        )
    }
}