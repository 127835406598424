import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL, HEADERS, REGIONS, SERVICES } from '../../common/api';
import { Form } from "react-bootstrap";
import Table from '../../assets/js/Table';
import Error from "../../assets/js/Error";
import Pagination from '../../common/pagination';
import Select from 'react-select';


const columns = [
    {field: 'id', header: 'ID'},
    {field: 'created_at', header: 'Buyurtma berilgan sana'},
    {field: 'phone', header: 'Telefon raqami'},
    {field: 'name', header: "Ismi"},
    {field: 'to_date', header: "Yetkazib berish sanasi"},
    {field: 'service_status', header: "Holati"},
    {field: 'region_name', header: "Hudud"},
]

const options = [
    {value: 0, label: "Yangi"},
    {value: 1, label: "Qabul qilingan"},
    {value: 2, label: "Deliverga biriktirilgan"},
    {value: 3, label: "Bekor qilingan"},
    {value: 4, label: "Tugatilgan"},
]

export default class Service extends Component {
    constructor(props) {
        super(props);
        this.state = {
            services: [],
            regions: [],
            filter: {},
            status: 0,
            currentPage: 1,
            totalPages: 1
        }
        this._isMounted = false;
    }

    handlePageChange(pageNumber) {
        this.setState({
      currentPage: pageNumber
    });
        this.getData(pageNumber)
    }

    handleFilter = (event, stateVariable) => {
        const value = event ? event.value : '';
        this.setState({
        filters: {
            ...this.state.filters,
            [stateVariable]: value
        },
        })
        };

    getData = (pageNumber=1) => {
        this._isMounted = true;
        this._isMounted && axios.get(BASE_URL + SERVICES + `?page=${pageNumber}`, {params: this.state.filters},  HEADERS)
        .then(res => {
            const data = res.data.results;
            data.map(el => {
                el.created_at = new Date(el.created_at).toLocaleDateString("ru-RU")
                el.to_date = new Date(el.to_date).toLocaleDateString("ru-RU")
                return el
            })
            this.setState({services: data, status: res.status, totalPages: Math.ceil(res.data.count/21)})
        })

    }

    componentDidMount() {
        this.getData()
        axios.get(BASE_URL + REGIONS, HEADERS)
        .then(res => {
            const arr = [];
            const data = res.data;
            data.map(el => {
                return arr.push({value: el.id, label: el.nameUz})
            })
            this.setState({regions: arr})
        })
    }

    add = () => {
        this.props.history.push("/single-service/add");
    }

    get = (id) => {
        this.props.history.push(`single-service/${id}`)
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevState.filters !== this.state.filters)) {
            this.getData();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


  render() {
    const {services, status, currentPage, totalPages, regions} = this.state;
    console.log(this.state.filters);
    return (
        <>
        {status === 200 ? (<>
        <div className="filters">
            <div className="row">
                <div className="col-6">
                    <Form.Label>Status</Form.Label>
                    <Select
                    options={options}
                    isClearable={true}
                    placeholder="Tanlang..."
                    onChange={(event) => this.handleFilter(event, "status")} />
                </div>
                <div className="col-6">
                    <Form.Label>Hudud</Form.Label>
                    <Select
                    options={regions}
                    isClearable={true}
                    placeholder="Tanlang..."
                    onChange={(event) => this.handleFilter(event, "region")} />
                </div>
            </div>
        </div>
        <Table
        title="Xizmatlar"
        data={services}
        columns={columns}
        addFunc={()=>this.add()}
        filterFunc={(id)=> this.get(id)}
            />
        <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(num)=>this.handlePageChange(num)}
        />
        </>)
        : (<Error status={status} />)}
        </>
        )
    }
}
