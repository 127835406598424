import Main from '../views/info/Main';
import Banner from '../views/info/Banner';
import Page from '../views/info/Page';
import Opportunity from '../views/info/Opportunity';
import Post from '../views/info/Post';
import Social from '../views/info/SocialMedia';
import Contact from '../views/info/Contact';
import SinglePage from "../views/info/SinglePage";
import SinglePost from '../views/info/SinglePost';

var routes = [
    {
        path: "/main",
        name: "Main",
        component: Main,
    },
    {
        path: "/banner",
        name: "Banner",
        component: Banner,
    },
    {
        path: "/pages",
        name: "Page",
        component: Page
    },
    {
        path: "/single-id/:id",
        name: "SinglePage",
        component: SinglePage
    },
    {
        path: "/opportunity",
        name: "Opportunity",
        component: Opportunity
    },
    {
        path: "/post",
        name: "Post",
        component: Post
    },
    {
        path: "/single-post/:id",
        name: "Post",
        component: SinglePost
    },
    {
        path: "/social",
        name: "Social",
        component: Social
    },
    {
        path: "/contact",
        name: "Contact",
        component: Contact
    },
]

export default routes;