import surveys from "../views/analytics/surveys";
import addSurvey from "../views/analytics/addSurvey";
import results from "../views/analytics/results";
import Filters from "../views/analytics/filters";

var routes = [
  {
    path: "/survey",
    name: "survey",
    component: surveys,
  },
  {
    path: "/addsurvey/:id",
    name: "addSurvey",
    component: addSurvey,
  },
  {
    path: "/results/:id",
    name: "resilts",
    component: results,
  },
  {
    path: "/filters",
    name: "filters",
    component: Filters,
  },
];
export default routes;
