import React from 'react';
import axios from 'axios';
import {BASE_URL, DELIVERY, HEADERS} from '../../common/api';
import { Modal, Form, Button } from "react-bootstrap";
import Table from "../../assets/js/Table";

const columns = [
    {field: "id", header: "ID"},
    {field: 'nameUz', header: "Nomlanishi"},
    {field: 'description_uz', header: "Tavsifi"},
    {field: "price", header: "Narxi (so'm)"},
    {field: "isActive", header: "Statusi"}
]


export default class DeliveryMethods extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
             data: [],
             show: false,
             confirm: false,
             id: "",
             nameUz: "",
             nameRu: "",
             description_uz: "",
             description_ru: "",
             price: "",
             isActive: false,
        }
    }

    showModal = () => {
        this.setState({ show: true });};
    

    showConfirmationModal = (id, ) => {
    this.setState({confirm: true, id: id })
    }


    handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

    componentDidMount() {
        axios.get(BASE_URL + DELIVERY, HEADERS)
        .then(res => this.setState({data: res.data}))
    }

    componentWillUnmount() {
        this._isMounted = false;}

      componentDidUpdate(prevProps, prevState) {
        if (prevState.show && !this.state.show) {
        this.setState({
            show: false, 
            id: "",
            nameUz: "",
            nameRu: "",
            description_uz: "",
            description_ru: "",
            price: "",
            isActive: false,
        });
        }
  }

    hideModal = () => {
    this.setState({
        show: false,
        confirm: false
    });};

    get = (row) => {
        this.showModal();
        this.setState({
            nameUz: row.nameUz,
            nameRu: row.nameRu,
            description_uz: row.description_uz,
            description_ru: row.description_ru,
            isActive: row.isActive,
            price: row.price,
            id: row.id
        });
  };

    getTitle = () => {
    const { id } = this.state;
    if (id) {
        return <h3>O'zgartirish</h3>;
    } else {
        return <h3>Yangi qo'shish</h3>;
    }};

    delete = (id, event) => {
    event.preventDefault();
    const { data } = this.state;
    axios.delete(BASE_URL + DELIVERY + id, HEADERS).then((_res) => {
        const updatedData = data.filter((el) => el.id !== id);
        this.setState({ data: updatedData, confirm: false });
    });};

    addOrUpdate = (event) => {
    event.preventDefault();
    const {id, nameUz, nameRu, description_uz, description_ru, isActive, price} = this.state;
    let sendData = {
        nameUz: nameUz,
        nameRu: nameRu,
        description_uz: description_uz,
        description_ru: description_ru,
        isActive: isActive,
        price: price,
    }

    console.log(sendData)

    if (id) {
      axios.put(BASE_URL + DELIVERY + id + "/", sendData, HEADERS).then((res) => {
        const { data } = this.state;
        const index = data.findIndex((el) => el.id === id);
        data[index] = res.data;
        this.setState({ data: data, show: false });
      });
    } else {
      axios.post(BASE_URL + DELIVERY, sendData, HEADERS).then((res) =>
        this.setState({
          data: [...this.state.data, res.data],
          show: false,
        })
      ).catch(err => console.log(err.response));
    }
  };
    render() {
        const {data, show, nameUz, nameRu, description_uz, description_ru, isActive, price, confirm, id} = this.state;
        return (
            <>
            <Modal
              show={show}
              onHide={() => {
                this.hideModal();
              }}
              animation={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>{this.getTitle()}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={(event) => this.addOrUpdate(event)}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                    key={this.state.id}
                  >
                    <Form.Label>Nomlanishi:</Form.Label>
                    <Form.Control
                      type="text"
                      name="nameUz"
                      value={nameUz}
                      onChange={this.handleChange}
                    />
                    <Form.Label>Наименование:</Form.Label>
                    <Form.Control
                      type="text"
                      name="nameRu"
                      value={nameRu}
                      onChange={this.handleChange}
                    />
                    <Form.Label>Tavsifi:</Form.Label>
                    <Form.Control
                      type="text"
                      name="description_uz"
                      value={description_uz}
                      onChange={this.handleChange}
                    />
                    <Form.Label>Описание:</Form.Label>
                    <Form.Control
                      type="text"
                      name="description_ru"
                      value={description_ru}
                      onChange={this.handleChange}
                    />
                    <Form.Label>Narxi:</Form.Label>
                    <Form.Control
                      type="text"
                      name="price"
                      value={price}
                      onChange={this.handleChange}
                    />
                    <Form.Label>Statusi:</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleChange}
                      name="isActive"
                      value={isActive}
                    >
                      <option value="true">active</option>
                      <option value="false">NOT active</option>
                    </Form.Control>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={this.showModal}
                  >
                    Saqlash
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
            <Table
            columns={columns}
            data={data}
            title="Yetkazish metodlari"
            addFunc={this.showModal}
            editFunc={(row)=>this.get(row)}
            deleteFunc={(event, id)=>this.showConfirmationModal(event, id)}
            />

            {/* Delete confirmation modal field */}
          <Modal
            show={confirm}
            onHide={() => {
                this.hideModal();
              }}
              animation={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>O'chirish</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>Haqiqatan ham ushbu ma'lumotni o'chirmoqchimisiz?</h5>
                <button className="btn btn-danger m-1" onClick={(event) => this.delete(id, event)} >O'chirish</button>
                <button className="btn btn-light outline-secondary" onClick={() => {
                this.hideModal();
              }} >Qaytish</button>
              </Modal.Body>
            </Modal>
            </>
        )
    }
}