import React from "react";
import axios from "axios";
import "../../../assets/features.css";
import { BASE_URL, ROOMS, HEADERS } from "../../../common/api";
import { DeleteButton, EditButton, AddButton } from "../../../assets/js/Button";
import { Modal, Form, Button } from "react-bootstrap";

class Room extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rooms: [],
      show: false,
      confirm: false,
      nameUz: "",
      nameRu: "",
      id: "",
    };
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    this._isMounted &&
      axios.get(BASE_URL + ROOMS).then((res) => {
        this.setState({ rooms: res.data });
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.show && !this.state.show) {
      this.setState({
        show: false,
        nameUz: "",
        nameRu: "",
        id: "",
      });
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  showModal = () => {
    this.setState({ show: true });
  };

  showConfirmationModal = (event, id) => {
    this.setState({ confirm: true, id: id });
  };

  hideModal = () => {
    this.setState({ show: false, confirm: false });
  };

  get = (data) => {
    this.setState({
      nameUz: data.nameUz,
      nameRu: data.nameRu,
      id: data.id,
      show: true,
    });
  };

  delete = (id) => {
    const { rooms } = this.state;
    axios.delete(BASE_URL + ROOMS + id).then((res) => {
      const updatedData = rooms.filter((room) => room.id !== id);
      this.setState({ rooms: updatedData, confirm: false });
    });
  };

  addOrUpdate = (event) => {
    event.preventDefault();
    const { nameUz, nameRu, id } = this.state;
    let data = {
      nameUz: nameUz,
      nameRu: nameRu,
    };

    if (id) {
      axios.put(BASE_URL + ROOMS + id + "/", data, HEADERS).then((res) => {
        const { rooms } = this.state;
        const index = rooms.findIndex((room) => room.id === id);
        rooms[index] = res.data;
        this.setState({ rooms: rooms, show: false });
      });
    } else {
      axios.post(BASE_URL + ROOMS, data, HEADERS).then((res) => {
        this.setState({
          rooms: [...this.state.rooms, res.data],
          show: false,
        });
      });
    }
  };

  getTitle = () => {
    const { id } = this.state;
    if (id) {
      return <h3>O'zgartirish</h3>;
    } else {
      return <h3>Yangi qo'shish</h3>;
    }
  };

  render() {
    const { show, confirm, nameUz, nameRu, id } = this.state;
    const { permission } = this.props;
    return (
      <>
        <Modal
          show={show}
          onHide={() => {
            this.hideModal();
          }}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.getTitle()}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={(event) => this.addOrUpdate(event)}>
              <Form.Group className="mb-3" key={id}>
                <Form.Label>O'zbekcha nomlanishi:</Form.Label>
                <Form.Control
                  type="text"
                  name="nameUz"
                  value={nameUz}
                  onChange={this.handleChange}
                />
                <Form.Label>Ruscha nomlanishi:</Form.Label>
                <Form.Control
                  type="text"
                  name="nameRu"
                  value={nameRu}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Button variant="primary" type="submit" onClick={this.showModal}>
                Saqlash
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
        <section id="tab-item-3">
          {permission && <AddButton onClick={() => this.showModal()} />}
          <table className="table table-hover">
            <thead className="thead-light">
              <tr>
                <th>O'zbekcha nomlanishi</th>
                <th>Ruscha nomlanishi</th>
                <th>Amallar</th>
              </tr>
            </thead>
            <tbody>
              {this.state.rooms.map((room) => (
                <tr key={room.id}>
                  <td>{room.nameUz}</td>
                  <td>{room.nameRu}</td>
                  <td>
                    {permission && (
                      <EditButton onClick={() => this.get(room)} />
                    )}
                    {permission && (
                      <DeleteButton
                        onClick={(event, id) =>
                          this.showConfirmationModal(event, room.id)
                        }
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
        <Modal
          show={confirm}
          onHide={() => {
            this.hideModal();
          }}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>O'chirish</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Haqiqatan ham ushbu ma'lumotni o'chirmoqchimisiz?</h5>
            <button
              className="btn btn-danger m-1"
              onClick={(event) => this.delete(id, event)}
            >
              O'chirish
            </button>
            <button
              className="btn btn-light outline-secondary"
              onClick={() => {
                this.hideModal();
              }}
            >
              Qaytish
            </button>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Room;
