import SpecialOrder from "../views/shop/SpecialOrder";
import Units from "../views/shop/Units";
import LogisticOrder from "../views/shop/Order";
import CreateSpecialOrder from "../views/shop/CreateSpecialOrder";
import Machine from "../views/sale/Machine";


var routes = [
    {
        path: "/specialorders",
        name: "SpecialOrder",
        component: SpecialOrder,
    },
    {
        path: "/logorders",
        name: "LogisticOrder",
        component: LogisticOrder,
    },
    {
        path: "/units",
        name: "Units",
        component: Units,
    },
    {
        path: "/create-special-order/",
        name: "CreateSpecialOrder",
        component: CreateSpecialOrder,
    },
    {
        path: "/shop-machine",
        name: "Machine",
        component: Machine
    }
];

export default routes;