import React from "react";
import axios from "axios";
import { BASE_URL, BRANDS, CURRENTUSER, HEADERS } from "../../common/api";
import Table from "../../assets/js/Table";
import { Modal, Form, Button } from "react-bootstrap";
import Error from "../../assets/js/Error";
import Cookies from "universal-cookie";

const columns = [
  { field: "logo", header: "Rasm" },
  { field: "name", header: "Nomlanishi" },
  { field: "isActive", header: "Statusi" },
];

const cookies = new Cookies();
export default class Brand extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: true,
      show: false,
      confirm: false,
      brands: [],
      name: "",
      logo: null,
      id: "",
      isActive: true,
      status: 0,
    };
    this.data = {};
    this._isMounted = false;
  }
  // Show or hide modal depending on show state
  // setState sets default values in order to erase state after every modal show
  showModal = () => {
    this.setState({ show: true });
  };

  showConfirmationModal = (id) => {
    this.setState({ confirm: true, id: id });
  };

  hideModal = () => {
    this.setState({
      show: false,
      confirm: false,
    });
  };

  // Handle change in event
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  // Image handle
  onImageChange = (event) => {
    this.setState({ logo: event.target.files[0] });
  };
  // TITLE change
  getTitle = () => {
    const { id } = this.state;
    if (id) {
      return <h3>O'zgartirish</h3>;
    } else {
      return <h3>Yangi qo'shish</h3>;
    }
  };

  // GET data
  componentDidMount() {
    this._isMounted = true;
    this._isMounted &&
      axios
        .get(BASE_URL + BRANDS, HEADERS)

        .then((res) => {
          this.setState({ brands: res.data, status: res.status });
        })
        .catch((err) => {
          this.setState({ status: err.response.status });
        });
    if (cookies.get("hodim").toLowerCase() === "sotuvchi") {
      this.setState({ permission: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.show && !this.state.show) {
      this.setState({
        show: false,
        name: "",
        logo: null,
        id: "",
        isActive: true,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  // GET data depending on id in order to edit it
  get = (row) => {
    this.setState({
      name: row.name,
      logo: row.logo,
      id: row.id,
      isActive: row.isActive,
      show: true,
    });
  };
  // DELETE data
  delete = (id, event) => {
    event.preventDefault();
    const { brands } = this.state;
    axios.delete(BASE_URL + BRANDS + id, HEADERS).then((_res) => {
      const updatedData = brands.filter((brand) => brand.id !== id);
      this.setState({ brands: updatedData, confirm: false, id: "" });
    });
  };

  // POST or UPDATE data
  addOrUpdate = (event) => {
    event.preventDefault();
    const { logo, id, brands } = this.state;
    let formData = new FormData();

    if (logo !== null && logo.name) {
      formData.append("logo", logo, logo.name);
    }
    formData.append("name", this.state.name);
    formData.append("isActive", this.state.isActive);

    if (id) {
      axios.put(BASE_URL + BRANDS + id + "/", formData, HEADERS).then((res) => {
        const { brands } = this.state;
        const index = brands.findIndex((brand) => brand.id === id);
        brands[index] = res.data;
        this.setState({ brands: brands, show: false });
      });
    } else {
      axios.post(BASE_URL + BRANDS, formData, HEADERS).then((res) =>
        this.setState({
          brands: [...this.state.brands, res.data],
          show: false,
        })
      );
    }
  };

  render() {
    const { brands, name, isActive, show, status, confirm, id, permission } =
      this.state;
    return (
      <>
        {status === 200 ? (
          <>
            <Modal
              show={show}
              onHide={() => {
                this.hideModal();
              }}
              animation={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>{this.getTitle()}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={(event) => this.addOrUpdate(event)}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                    key={this.state.id}
                  >
                    <Form.Label>Nomlanishi:</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={name}
                      onChange={this.handleChange}
                    />
                    <Form.Label>Rasm:</Form.Label>
                    <Form.Control
                      type="file"
                      name="logo"
                      onChange={this.onImageChange}
                    />
                    <Form.Label>Statusi:</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleChange}
                      name="isActive"
                      value={isActive}
                    >
                      <option value="true">active</option>
                      <option value="false">NOT active</option>
                    </Form.Control>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={this.showModal}
                  >
                    Saqlash
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
            <Table
              data={brands}
              columns={columns}
              title="Brendlar"
              addFunc={permission ? this.showModal : null}
              editFunc={permission ? (row) => this.get(row) : null}
              deleteFunc={
                permission
                  ? (event, id) => this.showConfirmationModal(event, id)
                  : null
              }
            />

            {/* Delete confirmation modal field */}
            <Modal
              show={confirm}
              onHide={() => {
                this.hideModal();
              }}
              animation={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>O'chirish</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>Haqiqatan ham ushbu ma'lumotni o'chirmoqchimisiz?</h5>
                <button
                  className="btn btn-danger m-1"
                  onClick={(event) => this.delete(id, event)}
                >
                  O'chirish
                </button>
                <button
                  className="btn btn-light outline-secondary"
                  onClick={() => {
                    this.hideModal();
                  }}
                >
                  Qaytish
                </button>
              </Modal.Body>
            </Modal>
          </>
        ) : (
          <Error status={status} />
        )}
      </>
    );
  }
}
