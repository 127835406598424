import React from "react";
import axios from "axios";
import { BASE_URL, HEADERS } from "../../common/api";
import TinyMCEEditor from "../../common/tiny";

export default class SinglePost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      titleUz: "",
      titleRu: "",
      textUz: "",
      textRu: "",
      seoTitleUz: "",
      seoTitleRu: "",
      seoMetaTagUz: "",
      seoMetaTagRu: "",
      seoDescriptionUz: "",
      seoDescriptionRu: "",
      image: null,
      isActive: false,
      employee: "",
    };
    this.myRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.onUzTinyChange = this.onUzTinyChange.bind(this);
    this.onRuTinyChange = this.onRuTinyChange.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  log = () => {
    if (this.myRef.current) {
      console.log(this.myRef.current.getContent());
    }
  };

  handleChange = (event) => {
    console.log(event.target.name);
    this.setState({ [event.target.name]: event.target.value });
  };

  onUzTinyChange = (content) => {
    this.setState({ textUz: content });
  };

  onRuTinyChange = (content) => {
    this.setState({ textRu: content });
  };

  onImageChange = (event) => {
    this.setState({ image: event.target.files[0] });
  };

  componentDidMount() {
    const { id } = this.state;
    if (id === "add") {
      axios
        .get(BASE_URL + "users/user/", HEADERS)
        .then((res) => this.setState({ employee: res.data.id }));
    } else {
      axios.get(BASE_URL + `posts/${id}/`, HEADERS).then((res) => {
        const post = res.data;
        this.setState({
          image: post.image,
          titleUz: post.titleUz,
          titleRu: post.titleRu,
          textUz: post.textUz,
          textRu: post.textRu,
          seoTitleUz: post.seoTitleUz,
          seoTitleRu: post.seoTitleRu,
          seoMetaTagUz: post.seoMetaTagUz,
          seoMetaTagRu: post.seoMetaTagRu,
          seoDescriptionUz: post.seoDescriptionUz,
          seoDescriptionRu: post.seoDescriptionRu,
          isActive: post.isActive,
          employee: post.employee,
        });
      });
    }
  }

  handleSubmit = (event, id) => {
    const {
      image,
      titleUz,
      titleRu,
      textUz,
      textRu,
      seoTitleUz,
      seoTitleRu,
      seoMetaTagUz,
      seoMetaTagRu,
      seoDescriptionUz,
      seoDescriptionRu,
      isActive,
      employee,
    } = this.state;
    event.preventDefault();
    let formData = new FormData();
    if (image !== null && image.name) {
      formData.append("image", image, image.name);
    }
    formData.append("titleUz", titleUz);
    formData.append("titleRu", titleRu);
    formData.append("textUz", textUz);
    formData.append("textRu", textRu);
    formData.append("seoTitleUz", seoTitleUz);
    formData.append("seoTitleRu", seoTitleRu);
    formData.append("seoMetaTagUz", seoMetaTagUz);
    formData.append("seoMetaTagRu", seoMetaTagRu);
    formData.append("seoDescriptionUz", seoDescriptionUz);
    formData.append("seoDescriptionRu", seoDescriptionRu);
    formData.append("isActive", isActive);
    formData.append("employee", employee);

    if (id === "add") {
      axios
        .post(BASE_URL + "posts/", formData, HEADERS)
        .then((res) => {
          this.props.history.push("/post");
        })
        .catch((err) => console.log(err.response));
    } else {
      axios
        .put(BASE_URL + `posts/${id}/`, formData, HEADERS)
        .then(this.props.history.push("/post"))
        .catch((err) => {
          console.log(err);
          console.log(err.message);
          console.log(err.response);
        });
    }
  };

  render() {
    const {
      image,
      titleUz,
      titleRu,
      textUz,
      textRu,
      seoTitleUz,
      seoTitleRu,
      seoMetaTagUz,
      seoMetaTagRu,
      seoDescriptionUz,
      seoDescriptionRu,
      id,
      isActive,
    } = this.state;
    return (
      <>
        <div className="pt-2 m-2" key={id}>
          <div className="row m-1">
            <div className="col-4">
              {image && <img src={image} alt="" className="img-fluid" />}
            </div>
            <div className="col-4">
              <form>
                <div className="form-group">
                  <label htmlFor="exampleFormControlFile1">Rasm</label>
                  <input
                    type="file"
                    className="form-control-file"
                    id="exampleFormControlFile1"
                    name="image"
                    onChange={this.onImageChange}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-4">
              <form>
                <div className="form-group">
                  <label htmlFor="textInput">Sarlavha</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={titleUz}
                    name="titleUz"
                    id="textInput"
                    aria-describedby="emailHelp"
                    onChange={this.handleChange}
                  />
                </div>
              </form>
            </div>
            <div className="col-sm-12 col-lg-4">
              <form>
                <div className="form-group">
                  <label htmlFor="textInput">Заголовок</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={titleRu}
                    name="titleRu"
                    id="textInput"
                    aria-describedby="emailHelp"
                    onChange={this.handleChange}
                  />
                </div>
              </form>
            </div>
            <div className="col-sm-12 col-lg-4">
              <form>
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Statusi</label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                    onChange={this.handleChange}
                    name="isActive"
                    value={isActive}
                  >
                    <option value="false">Passiv</option>
                    <option value="true">Aktiv</option>
                  </select>
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <form>
                <div className="form-group">
                  <label htmlFor="textInput">Seo title uz</label>
                  <input
                    type="text"
                    className="form-control"
                    id="textInput"
                    defaultValue={seoTitleUz}
                    name="seoTitleUz"
                    aria-describedby="emailHelp"
                    onChange={this.handleChange}
                  />
                </div>
              </form>
            </div>
            <div className="col-sm-12 col-lg-6">
              <form>
                <div className="form-group">
                  <label htmlFor="textInput">Seo title ru</label>
                  <input
                    type="text"
                    className="form-control"
                    id="textInput"
                    defaultValue={seoTitleRu}
                    name="seoTitleRu"
                    aria-describedby="emailHelp"
                    onChange={this.handleChange}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <form>
                <div className="form-group">
                  <label htmlFor="textInput">Seo description Uz</label>
                  <input
                    type="text"
                    className="form-control"
                    id="textInput"
                    aria-describedby="emailHelp"
                    defaultValue={seoDescriptionUz}
                    name="seoDescriptionUz"
                    onChange={this.handleChange}
                  />
                </div>
              </form>
            </div>
            <div className="col-sm-12 col-lg-6">
              <form>
                <div className="form-group">
                  <label htmlFor="textInput">Seo description Ru</label>
                  <input
                    type="text"
                    className="form-control"
                    id="textInput"
                    aria-describedby="emailHelp"
                    defaultValue={seoDescriptionRu}
                    name="seoDescriptionRu"
                    onChange={this.handleChange}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <form>
                <div className="form-group">
                  <label htmlFor="textInput">Seo meta Uz</label>
                  <input
                    type="text"
                    className="form-control"
                    id="textInput"
                    aria-describedby="emailHelp"
                    defaultValue={seoMetaTagUz}
                    name="seoMetaTagUz"
                    onChange={this.handleChange}
                  />
                </div>
              </form>
            </div>
            <div className="col-sm-12 col-lg-6">
              <form>
                <div className="form-group">
                  <label htmlFor="textInput">Seo meta Ru</label>
                  <input
                    type="text"
                    className="form-control"
                    id="textInput"
                    aria-describedby="emailHelp"
                    defaultValue={seoMetaTagRu}
                    name="seoMetaTagRu"
                    onChange={this.handleChange}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <form>
                <div className="form-group">
                  <label htmlFor="textInput">Tasnif</label>
                  <TinyMCEEditor
                    changed={this.onUzTinyChange}
                    value={textUz}
                    name="textUz"
                  />
                </div>
              </form>
            </div>
            <div className="col-12">
              <form>
                <div className="form-group">
                  <label htmlFor="textInput">Описание</label>
                  <TinyMCEEditor
                    changed={this.onRuTinyChange}
                    value={textRu}
                    name="textRu"
                  />
                </div>
              </form>
            </div>
          </div>
          <button
            className="btn btn-primary m-3"
            onClick={(event) => this.handleSubmit(event, id)}
          >
            Saqlash
          </button>
        </div>
      </>
    );
  }
}
