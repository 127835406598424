import React, { Component } from 'react';
import axios from 'axios';
import { Form, Button, Alert } from 'react-bootstrap';
import { BASE_URL, HEADERS } from '../../common/api';
import { withRouter } from 'react-router-dom';

class PasswordChange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            error: "",
            oldPasswordError: "",
            id: this.props.location.state,
        }
    }



    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }   

    handleSubmit = (event) => {
        console.log(HEADERS);
        const {newPassword, confirmPassword, oldPassword, id} = this.state;
        event.preventDefault();

        if (newPassword !== confirmPassword) {
            this.setState({error: "Parollar to'g'ri kelmayapti!"});
            return;
        }

        const data = {
            old_password: oldPassword,
            new_password: newPassword,
            user_id: id,
        }
        axios.put(BASE_URL + 'password-change/', data, HEADERS)
        .then(res => {
            const message = res.data.message;
            this.props.history.push('/admins', {message})
        }).catch(err => {
            if(err.response.status === 400) {
                this.setState({oldPasswordError: "Eski parol noto'g'ri kiritildi!"})
                console.log(err.response);
            }

        });
    }
  render() {
    const {oldPassword, newPassword, confirmPassword, error, oldPasswordError} = this.state;
    return (
      <div className='row'>
      <div className="col-4"></div>
      <Form onSubmit={(event)=>this.handleSubmit(event)} className="col-4 change-form">
        <h3 className='text-center'>Parol almashtirish</h3>
        <Form.Group
        className="mb-3"                   
        key={this.state.id}>
            <Form.Label>Old Password: </Form.Label>
            <Form.Control type="password" name="oldPassword" value={oldPassword} onChange={this.handleChange} required />
            <Form.Label>New Password: </Form.Label>
            <Form.Control type="password" name="newPassword" value={newPassword} onChange={this.handleChange} required />
            <Form.Label>Confirm Password: </Form.Label>
            <Form.Control type="password" name="confirmPassword" value={confirmPassword} onChange={this.handleChange} required />
        </Form.Group>
        {error.length > 0 && <Alert variant='danger' key="danger">{error}</Alert>}
        {oldPasswordError.length > 0 && <Alert variant='danger' key='danger'>{oldPasswordError}</Alert>}
        <Button variant="primary" type="submit" style={{width: "100%"}}>
            Parolni o'zgartirish
        </Button>
      </Form>
      <div className="col-4"></div>
      </div>
    )
  }
}

export default withRouter(PasswordChange);