import React, { Component } from "react";
import axios from "axios";
import {
  BASE_URL,
  CATEGORIES,
  HEADERS,
  UNITSALL,
  SIZES,
  WAREHOUSE,
  BRANDS,
} from "../../common/api";
import Error from "../../assets/js/Error";
import Pagination from "../../common/pagination";

import { Form } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { withRouter } from "react-router-dom";

export class Units extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      brands: [],
      categories: [],
      collections: [],
      designs: [],
      sizes: [],
      warehouses: [],
      filters: {},
      status: 0,
      currentPage: 1,
      totalPages: 1,
      file: null,
      fileName: "",
    };
    this._isMounted = false;
  }
  create = () => {
    this.props.history.push(`/create-special-order`);
  };

  loadOptions = (inputValue, dataType) => {
    return new Promise((resolve, reject) => {
      // using setTimeout to emulate a call to server
      setTimeout(() => {
        resolve(this.filter(inputValue, dataType));
      }, 2000);
    });
  };

  filter = async (inputValue, dataType) => {
    const data = this.state[dataType];
    return await data.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  handleFilter = (event, stateVariable, selectType = 0) => {
    const updateFilters = () => {
      const { filters } = this.state;
      const queryString = Object.keys(filters)
        .map((key) => key + "=" + filters[key])
        .join("&");
      this.props.history.push(`?${queryString}`);
      this.setState({ queryString });
    };
    if (selectType === 1) {
      const selectedValues = [];
      const filteredValues = [];
      event.map((item) => {
        selectedValues.push({ value: item.value, label: item.label });
        filteredValues.push(item.value);
        return { selectedValues, filteredValues };
      });
      this.setState(
        {
          [stateVariable]: selectedValues,
          filters: {
            ...this.state.filters,
            [stateVariable]: filteredValues.join(","),
          },
        },
        updateFilters
      );
    } else if (selectType === 2) {
      const value = event ? event.value : "";
      this.setState(
        {
          filters: {
            ...this.state.filters,
            [stateVariable]: value,
          },
        },
        updateFilters
      );
    } else {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            [stateVariable]: event.target.value,
          },
        },
        updateFilters
      );
    }
  };

  handlePageChange(pageNumber) {
    this.setState({
      currentPage: pageNumber,
    });
    this.getData(pageNumber);
  }

  getData = async (pageNumber = 1) => {
    this._isMounted = true;
    this._isMounted &&
      (await axios
        .get(BASE_URL + UNITSALL + `?page=${pageNumber}&back=true`, {
          params: this.state.filters,
        })
        .then((res) =>
          this.setState({
            data: res.data.results,
            status: res.status,
            totalPages: Math.ceil(res.data.count / 21),
          })
        ));
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  };

  getSelectedValues = (filterProp, stateProp) => {
    const { filters } = this.state;
    const selectedValues =
      filters[filterProp] && filters[filterProp].split(",").map(Number);
    const arr = [];
    selectedValues &&
      selectedValues.map((el) => {
        const exist = this.state[stateProp].find((col) => col.value === el);
        return arr.push(exist);
      });
    return arr.length ? arr : [];
  };

  handleOpen = (id) => {
    this.props.history.push(`single-format/${id}`);
  };

  handleFileUpload = (event) => {
    const file = event.target.files[0];
    const fileName = file.name;
    const formData = new FormData();
    formData.append("file", file, fileName);

    this.setState({ file, fileName }, () => {
      this.handleSubmit(formData);
    });
  };
  async componentDidMount() {
    this.getData();
    await axios.get(BASE_URL + "collections-list", HEADERS).then((res) => {
      const arr = [];
      const data = res.data;
      data.map((el) => {
        if (el.nameUz) {
          arr.push({ value: el.id, label: el.nameUz });
        }
        return el.id;
      });
      this.setState({ collections: arr });
    });
    await axios.get(BASE_URL + "products-list-all", HEADERS).then((res) => {
      const arr = [];
      const data = res.data;
      data.map((el) => {
        return arr.push({ value: el.id, label: el.vId });
      });
      this.setState({ designs: arr });
    });
    await axios.get(BASE_URL + BRANDS, HEADERS).then((res) => {
      const arr = [];
      const data = res.data;
      data.map((el) => {
        return arr.push({ value: el.id, label: el.name });
      });
      this.setState({ brands: arr });
    });
    await axios.get(BASE_URL + WAREHOUSE, HEADERS).then((res) => {
      const arr = [];
      const data = res.data;
      data.map((el) => {
        return arr.push({ value: el.id, label: el.name_uz });
      });
      this.setState({ warehouses: arr });
    });
    await axios
      .get(BASE_URL + SIZES + "?paginate=false", HEADERS)
      .then((res) => {
        const arr = [];
        const data = res.data;
        data.map((el) => {
          return arr.push({
            value: el.vSizeId,
            label: `${el.width}x${el.length}_${el.type}`,
          });
        });
        this.setState({ sizes: arr });
      });
    await axios.get(BASE_URL + CATEGORIES, HEADERS).then((res) => {
      const arr = [];
      const data = res.data;
      data.map((el) => {
        if (el.nameUz && el.isActive) {
          arr.push({ value: String(el.id), label: el.nameUz });
        }
        return el.id;
      });
      this.setState({ categories: arr });
    });
    const searchParams = new URLSearchParams(window.location.search);
    const filters = {};
    for (let [key, value] of searchParams.entries()) {
      if (value === "true" || value === "false") {
        filters[key] = value === "true";
      } else if (isNaN(Number(value))) {
        filters[key] = value;
      } else {
        filters[key] = value;
      }
    }
    this.setState({ filters });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.filters !== this.state.filters) {
      await this.getData();
    }
  }

  handleSubmit = (formData) => {
    axios
      .post(BASE_URL + "upload-excel/", formData)
      .then(window.location.reload(false))
      .catch((err) => console.log(err));
  };

  render() {
    const {
      data,
      status,
      currentPage,
      totalPages,
      filters,
      brands,
      collections,
      categories,
      designs,
      sizes,
      warehouses,
    } = this.state;
    return (
      <>
        {status === 200 ? (
          <div>
            <div className="file-upload">
              {/* <form onSubmit={this.handleSubmit}>
                <label htmlFor="file-input">
                  <i className="fa-solid fa-file-excel fa-2xl pr-2"></i>Excel
                  yuklash
                </label>
                <input
                  type="file"
                  className="form-control-file"
                  id="file-input"
                  onChange={this.handleFileUpload}
                />
              </form> */}
            </div>
            <div className="filters">
              <div className="row">
                {/* <div className="col-3">
                  <Form.Label>Brend:</Form.Label>
                  <Form.Control
                    as="select"
                    name="brand"
                    className="foo"
                    onChange={(event) => this.handleFilter(event, "brand")}
                    value={filters.brand || ""}
                  >
                    <option hidden>Brend tanlang:</option>
                    {brands.map((brand) => (
                      <option
                        value={brand.value}
                        key={brand.value}
                        className="p-4"
                      >
                        {brand.label}
                      </option>
                    ))}
                  </Form.Control>
                  {filters.brand && (
                    <button
                      className="btn btn-secondary bar"
                      onClick={() => this.handleCancel("brand")}
                    >
                      x
                    </button>
                  )}
                </div> */}
                <div className="col-4">
                  <Form.Label>Kategoriyalar:</Form.Label>
                  <Select
                    options={categories}
                    isClearable={true}
                    placeholder="Select..."
                    onChange={(event) =>
                      this.handleFilter(event, "category", 2)
                    }
                    defaultValue={this.getSelectedValues(
                      "category",
                      "categories"
                    )}
                  />
                </div>
                <div className="col-4">
                  <Form.Label>Kolleksiya:</Form.Label>
                  <AsyncSelect
                    isMulti
                    defaultOptions={collections.slice(0, 10)}
                    cacheOptions
                    loadOptions={(inputValue) =>
                      this.loadOptions(inputValue, "collections")
                    }
                    value={
                      this.getSelectedValues("collection", "collections") || []
                    }
                    onChange={(event) =>
                      this.handleFilter(event, "collection", 1)
                    }
                  />
                </div>
                <div className="col-4">
                  <Form.Label>Dizayn kodi:</Form.Label>
                  <AsyncSelect
                    isMulti
                    defaultOptions={designs.slice(0, 10)}
                    cacheOptions={10}
                    loadOptions={(inputValue) =>
                      this.loadOptions(inputValue, "designs")
                    }
                    value={this.getSelectedValues("design", "designs") || []}
                    onChange={(event) => this.handleFilter(event, "design", 1)}
                  />
                </div>
              </div>
              <div className="row pt-2">
                <div className="col-6">
                  <Form.Label>Narx (dan):</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={filters.price_min || ""}
                    onChange={(event) => this.handleFilter(event, "price_min")}
                  />
                </div>
                <div className="col-6">
                  <Form.Label>Narx (gacha):</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={filters.price_max || ""}
                    onChange={(event) => this.handleFilter(event, "price_max")}
                  />
                </div>
              </div>
              <div className="row pt-2">
                <div className="col-5">
                  <Form.Label>O'lchami:</Form.Label>
                  <AsyncSelect
                    isMulti
                    defaultOptions={sizes}
                    cacheOptions={10}
                    loadOptions={(inputValue) =>
                      this.loadOptions(inputValue, "sizes")
                    }
                    defaultValue={this.getSelectedValues("size", "sizes") || []}
                    onChange={(event) => this.handleFilter(event, "size", 1)}
                  />
                </div>
                <div className="col-5">
                  <Form.Label>Skladi:</Form.Label>
                  <AsyncSelect
                    isMulti
                    defaultOptions={warehouses}
                    cacheOptions={10}
                    loadOptions={(inputValue) =>
                      this.loadOptions(inputValue, "warehouses")
                    }
                    value={
                      this.getSelectedValues("warehouse", "warehouses") || []
                    }
                    onChange={(event) =>
                      this.handleFilter(event, "warehouse", 1)
                    }
                  />
                </div>
                <div className="col-2">
                  <button 
                    className="btn btn-primary" 
                    style={{marginTop: 32}}
                  onClick={() => this.create()}
                  >
                    Yangi qo'shish
                  </button>
                </div>
                {/* <div className="col-4">
                  <Form.Label>Statusi</Form.Label>
                  <Form.Control
                    as="select"
                    className="foo"
                    onChange={(event) => this.handleFilter(event, "isActive")}
                    value={filters.isActive || "all"}
                    name="isActive"
                  >
                    <option value="True">Aktiv</option>
                    <option value="False">Passiv</option>
                    <option value="all">Barchasi</option>
                  </Form.Control>
                  {filters.status && (
                    <button
                      className="btn btn-secondary bar"
                      onClick={() => this.handleCancel("status")}
                    >
                      x
                    </button>
                  )}
                </div> */}
              </div>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Dizayn kodi</th>
                  <th>Sklad</th>
                  <th>Kolleksiya</th>
                  <th>Forma</th>
                  <th>O'lcham</th>
                  <th>Narxi so'm</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.item_vId}</td>
                    <td>{item.warehouse_name_uz}</td>
                    <td>{item.collections}</td>
                    <td>{item.shape}</td>
                    <td>
                      {item.shape == "Metrik"
                        ? `${item.width}x${item.count}`
                        : `${item.width}x${item.length}`}
                    </td>
                    <td>{item.price}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(num) => this.handlePageChange(num)}
            />
          </div>
        ) : (
          <Error status={status} />
        )}
      </>
    );
  }
}

export default withRouter(Units);
