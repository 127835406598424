import React from "react";
import axios from "axios";
import "../../assets/features.css";

import Parametr from "./parameter/Parametr";
import Style from "./parameter/Style";
import Color from "./parameter/Color";
import Price from "./parameter/Price";
import Room from "./parameter/Room";
import DeliveryDay from "./parameter/DeliveryDay";
import { BASE_URL, CURRENTUSER, HEADERS } from "../../common/api";
import Cookies from "universal-cookie";

const cookies = new Cookies();
class Features extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: true,
    };
  }

  componentDidMount() {
    if (cookies.get("hodim").toLowerCase() === "sotuvchi") {
      this.setState({ permission: false });
    }
  }

  render() {
    const { permission } = this.state;
    return (
      <div className="">
        <div className="tabs effect-3">
          <input
            type="radio"
            id="tab-1"
            name="tab-effect-3"
            defaultChecked="checked"
          />
          <span>Stil</span>

          <input type="radio" id="tab-2" name="tab-effect-3" />
          <span>Ranglar</span>

          <input type="radio" id="tab-3" name="tab-effect-3" />
          <span>Xona</span>

          <input type="radio" id="tab-4" name="tab-effect-3" />
          <span>Xususiyatlar</span>

          <input type="radio" id="tab-5" name="tab-effect-3" />
          <span>Narxi</span>

          <input type="radio" id="tab-6" name="tab-effect-3" />
          <span>Kun</span>

          <div className="line ease"></div>

          <div className="tab-content">
            <Style permission={permission} />
            <Color permission={permission} />
            <Room permission={permission} />
            <Price permission={permission} />
            <Parametr permission={permission} />
            <DeliveryDay permission={permission} />
          </div>
        </div>
      </div>
    );
  }
}

export default Features;
