import axios from "axios";
import React from "react";
import { BASE_URL } from "../../common/api";
import query from "query-string";
import FilterComponent from "./filterComponent";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [],
      bigData: {},
      data: {},
      color: [],
    };
    this.date = "";
    this.rooms = [];
    this.colors = [];
    this.styles = [];
    this.price = [];
    this.collections = [];
    this.sizes = [];
    this.queries = [];
    this.shapes = {
      TB: "To'rtburchak",
      OV: "Oval",
      KV: "Kvadrat",
      NO: "Noodatiy",
      MK: "Metrik",
    };
    this.room_data = {};
    this.color_data = {};
    this.style_data = {};
    this.price_data = {};
    this.collection_data = {};
    this.size_data = {};
    this.query_data = {};
  }

  async componentDidMount() {
    await axios.get(BASE_URL + "rooms/").then((res) => {
      this.rooms = res.data;
    });
    await axios.get(BASE_URL + "colors/").then((res) => {
      this.colors = res.data;
    });
    await axios.get(BASE_URL + "styles/").then((res) => {
      this.styles = res.data;
    });
    await axios.get(BASE_URL + "price/").then((res) => {
      this.price = res.data;
    });
    await axios.get(BASE_URL + "collections/").then((res) => {
      this.collections = res.data;
    });
    await axios.get(BASE_URL + "filters/").then((res) => {
      const filters = res.data;
      this.setState({
        filters,
      });
      this.parseFilter(filters);
    });
    this.drawChart();
    this.setState({
      data: this.data_room,
    });
  }

  drawChart = () => {
    if (Object.keys(this.bigData).length > 0) {
      const room_data = this.bigData["room"];
      const room_label = [];
      const room_element = [];

      Object.keys(room_data).forEach((key) => {
        room_label.push(key + " : " + room_data[key]);
        room_element.push(room_data[key]);
      });
      this.options_room = {
        responsive: true,
        plugins: {
          legend: {
            position: "right",
            padding: 20,
            labels: {
              boxWidth: 25,
              boxHeight: 15,
              font: {
                size: 20,
              },
            },
          },
        },
      };
      this.data_room = {
        labels: room_label,
        datasets: [
          {
            label: "Xonalar bo'yicha",
            data: room_element,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      };
      // End of function

      const color_data = this.bigData["color"];
      const color_label = [];
      const color_element = [];
      Object.keys(color_data).forEach((key) => {
        color_label.push(key + " : " + color_data[key]);
        color_element.push(color_data[key]);
      });
      this.options_color = {
        responsive: true,
        elemnts: {
          point: {
            hoverBorderWidth: 2,
          },
        },
        plugins: {
          legend: {
            position: "right",
            padding: 20,
            labels: {
              boxWidth: 25,
              boxHeight: 15,
              font: {
                size: 20,
              },
            },
          },
        },
      };
      this.data_color = {
        labels: color_label,

        datasets: [
          {
            label: "Ranglar bo'yicha",
            data: color_element,
            backgroundColor: [
              "rgba(207,187,98)",
              "rgba(102,50,3)",
              "rgba(69,102,133)",
              "rgba(188,170,134)",
              "rgba(176,176,176)",
              "rgba(248,241,198)",
              "rgba(128,0,1)",
              "rgba(52,52,52)",
              "rgba(146,208,79)",
            ],
            borderColor: "white",
            borderWidth: 1,
          },
        ],
      };

      const style_data = this.bigData["style"];
      const style_label = [];
      const style_element = [];

      Object.keys(style_data).forEach((key) => {
        style_label.push(key + " : " + style_data[key]);
        style_element.push(style_data[key]);
      });
      this.options_style = {
        responsive: true,
        plugins: {
          legend: {
            position: "right",
            padding: 20,
            labels: {
              boxWidth: 25,
              boxHeight: 15,
              font: {
                size: 20,
              },
            },
          },
        },
      };
      this.data_style = {
        labels: style_label,
        datasets: [
          {
            label: "Stillar bo'yicha",
            data: style_element,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      };

      const price_data = this.bigData["price"];
      const price_label = [];
      const price_element = [];

      Object.keys(price_data).forEach((key) => {
        const price = key.split("-");
        price_label.push(
          this.currencyFormat(price[0]) +
            "-" +
            this.currencyFormat(price[1]) +
            " so'm  : " +
            price_data[key]
        );
        price_element.push(price_data[key]);
      });
      this.options_price = {
        responsive: true,
        plugins: {
          legend: {
            position: "right",
            padding: 20,
            labels: {
              boxWidth: 25,
              boxHeight: 15,
              font: {
                size: 20,
              },
            },
          },
        },
      };
      this.data_price = {
        labels: price_label,
        datasets: [
          {
            label: "narxlar bo'yicha",
            data: price_element,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      };

      const shape_data = this.bigData["shape"];
      const shape_label = [];
      const shape_element = [];

      Object.keys(shape_data).forEach((key) => {
        shape_label.push(key + " : " + shape_data[key]);
        shape_element.push(shape_data[key]);
      });
      this.options_shape = {
        responsive: true,
        plugins: {
          legend: {
            position: "right",
            padding: 20,
            labels: {
              boxWidth: 25,
              boxHeight: 15,
              font: {
                size: 20,
              },
            },
          },
        },
      };
      this.data_shape = {
        labels: shape_label,
        datasets: [
          {
            label: "Shakllar bo'yicha",
            data: shape_element,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      };
    } else {
      this.data_room = {
        labels: [],
        datasets: [
          {
            label: "Shakllar bo'yicha",
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
          },
        ],
      };
      this.data_color = {
        labels: [],
        datasets: [
          {
            label: "Shakllar bo'yicha",
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
          },
        ],
      };
      this.data_style = {
        labels: [],
        datasets: [
          {
            label: "Shakllar bo'yicha",
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
          },
        ],
      };
      this.data_price = {
        labels: [],
        datasets: [
          {
            label: "Shakllar bo'yicha",
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
          },
        ],
      };
      this.data_shape = {
        labels: [],
        datasets: [
          {
            label: "Shakllar bo'yicha",
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
          },
        ],
      };
    }
  };

  currencyFormat = (num) => {
    return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
  };

  getInitialData = (array, array_data) => {
    array.map((item) => {
      array_data[item.nameUz] = 0;
      return item.id;
    });
  };

  parseFilter = (filters) => {
    this.getInitialData(this.colors, this.color_data);
    this.getInitialData(this.rooms, this.room_data);
    this.getInitialData(this.styles, this.style_data);
    this.getInitialData(this.collections, this.collection_data);
    this.price.forEach((item) => {
      this.price_data[item.price_min + "-" + item.price_max] = 0;
    });
    this.shape_data = {
      "To'rtburchak": 0,
      Oval: 0,
      Kvadrat: 0,
      Noodatiy: 0,
      Metrik: 0,
    };
    this.size_data = {};
    this.query_data = {};
    this.bigData = {
      collection: this.collection_data,
      color: this.color_data,
      price: this.price_data,
      query: this.query_data,
      room: this.room_data,
      shape: this.shape_data,
      size: this.size_data,
      style: this.style_data,
    };
    if (filters.length > 0) {
      filters.map((item) => {
        let parse = query.parse(item.filters, { arrayFormat: "comma" });
        Object.keys(parse).forEach((key) => {
          if (key === "rooms") {
            this.getResults(parse, key, this.rooms, this.room_data, "room");
          } else if (key === "styles") {
            this.getResults(parse, key, this.styles, this.style_data, "style");
          } else if (key === "colors") {
            this.getResults(parse, key, this.colors, this.color_data, "color");
          } else if (key === "collection") {
            this.getResults(
              parse,
              key,
              this.collections,
              this.collection_data,
              "collection"
            );
          } else if (key === "prices") {
            if (typeof parse[key] === "object") {
              parse[key].map((item) => {
                let data = this.price.filter((el) => el.id === parseInt(item));
                let count =
                  this.price_data[
                    data[0]["price_min"] + "-" + data[0]["price_max"]
                  ];
                count++;
                this.price_data[
                  data[0]["price_min"] + "-" + data[0]["price_max"]
                ] = count;
                return item;
              });
            } else {
              let data = this.price.filter(
                (el) => el.id === parseInt(parse[key])
              );
              let count =
                this.price_data[
                  data[0]["price_min"] + "-" + data[0]["price_max"]
                ];
              count++;
              this.price_data[
                data[0]["price_min"] + "-" + data[0]["price_max"]
              ] = count;
            }

            this.bigData["price"] = this.price_data;
          } else if (key === "shapes") {
            if (typeof parse[key] === "object") {
              let data = [];
              parse[key].map((item, index) => {
                Object.keys(this.shapes).forEach((key) => {
                  if (key === item) {
                    data.push(this.shapes[key]);
                  }
                });
                let count = this.shape_data[data[index]];
                count++;
                this.shape_data[data[index]] = count;
                return item;
              });
            } else {
              let data = [];
              Object.keys(this.shapes).forEach((el) => {
                if (el === parse[key]) {
                  data.push(this.shapes[el]);
                }
              });
              let count = this.shape_data[data[0]];
              count++;
              this.shape_data[data[0]] = count;
            }
            this.bigData["shape"] = this.shape_data;
          } else if (key === "width") {
            if (this.size_data[parse["width"] + "x" + parse["length"]]) {
              let count =
                this.size_data[parse["width"] + "x" + parse["length"]];
              count++;
              this.size_data[parse["width"] + "x" + parse["length"]] = count;
            } else {
              this.size_data[parse["width"] + "x" + parse["length"]] = 1;
            }
            this.bigData["size"] = this.size_data;
          } else if (key === "query") {
            console.log(parse[key]);
            if (this.query_data[parse[key].toLocaleLowerCase()]) {
              let count = this.query_data[parse[key].toLocaleLowerCase()];
              count++;
              this.query_data[parse[key].toLocaleLowerCase()] = count;
            } else {
              this.query_data[parse[key].toLocaleLowerCase()] = 1;
            }
            this.bigData["query"] = this.query_data;
          }
        });
        return item.id;
      });
    } else {
      this.bigData = {};
    }
    this.setState({
      bigData: this.bigData,
    });
    console.log(this.state.bigData["collection"]);
  };

  getResults = (parse, key, array, array_data, name) => {
    if (typeof parse[key] === "object") {
      parse[key].map((item) => {
        let data = array.filter((el) => el.id === parseInt(item));
        let count = array_data[data[0]["nameUz"]];
        count++;
        array_data[data[0]["nameUz"]] = count;
        return item;
      });
    } else {
      let data = array.filter((el) => el.id === parseInt(parse[key]));
      let count = array_data[data[0]["nameUz"]];
      count++;
      array_data[data[0]["nameUz"]] = count;
    }
    this.bigData[name] = array_data;
  };

  getMonth = async (e) => {
    e.preventDefault();
    const date = e.target.value;
    const array = date.split("-");
    const year = array[0];
    const month = array[1];
    const day = array[2];
    await axios
      .get(BASE_URL + "filters/" + year + "/" + month + "/" + day)
      .then((res) => {
        const filters = res.data;
        this.setState({
          filters,
        });
        this.parseFilter(filters);
      });
    this.drawChart();
    this.setState({
      bigData: this.bigData,
    });
  };

  //   getData = (data, allData) => {
  //     const room = this.bigData[data];
  //     const label = [];
  //     const element = [];
  //     console.log(room);
  //     Object.keys(room).forEach((key) => {
  //       label.push(key);
  //       element.push(room[key]);
  //     });
  //     console.log(label);
  //     this.data = {
  //       labels: label,
  //       datasets: [
  //         {
  //           label: allData,
  //           data: element,
  //           backgroundColor: [
  //             "rgba(255, 99, 132, 0.2)",
  //             "rgba(54, 162, 235, 0.2)",
  //             "rgba(255, 206, 86, 0.2)",
  //             "rgba(75, 192, 192, 0.2)",
  //             "rgba(153, 102, 255, 0.2)",
  //             "rgba(255, 159, 64, 0.2)",
  //           ],
  //           borderColor: [
  //             "rgba(255, 99, 132, 1)",
  //             "rgba(54, 162, 235, 1)",
  //             "rgba(255, 206, 86, 1)",
  //             "rgba(75, 192, 192, 1)",
  //             "rgba(153, 102, 255, 1)",
  //             "rgba(255, 159, 64, 1)",
  //           ],
  //           borderWidth: 1,
  //         },
  //       ],
  //     };
  //   };
  render() {
    return (
      <div>
        <input
          type="date"
          defaultValue={this.date}
          onChange={(e) => this.getMonth(e)}
        />
        <div className="row">
          <div className="col-3">
            <p>Ranglar bo'yicha</p>
            {this.data_color && (
              <Pie options={this.options_color} data={this.data_color} />
            )}
          </div>
          <div className="col-3"></div>
          <div className="col-3">
            <p>Stillar bo'yicha</p>
            {this.data_style && (
              <Pie options={this.options_style} data={this.data_style} />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p>Xonalar bo'yicha</p>
            {this.data_room && (
              <Pie options={this.options_room} data={this.data_room} />
            )}
          </div>
          <div className="col-3"></div>
          <div className="col-3">
            <p>Shakllar bo'yicha</p>
            {this.data_shape && (
              <Pie options={this.options_shape} data={this.data_shape} />
            )}
          </div>
        </div>
        <div className="row pt-2 pb-0">
          <div className="col-3">
            <p>Narxlar bo'yicha</p>
            {this.data_price && (
              <Pie options={this.options_price} data={this.data_price} />
            )}
          </div>
        </div>
        <hr />
        {console.log(this.state.bigData.collection)}
        {this.state.bigData && (
          <FilterComponent
            name={"Kolleksiyalar"}
            data={this.state.bigData.collection}
          />
        )}
        <hr />
        {this.state.bigData && (
          <FilterComponent name={"O'lchamlar"} data={this.state.bigData.size} />
        )}
        <hr />
        {this.state.bigData && (
          <FilterComponent name={"So'zlar"} data={this.state.bigData.query} />
        )}
      </div>
    );
  }
}
export default Filters;
