import React from "react";
import axios from "axios";
import "../assets/login.css";
import { withRouter } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import { BASE_URL } from "../common/api";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";

const regex = /^(\+\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/;

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "+998",
      password: "",
      error: "",
      type: true,
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  togglePassword = () => {
    this.setState({
      type: !this.state.type,
    });
    console.log(this.state.type);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      username: this.state.username,
      password: this.state.password,
    };
    axios
      .post(BASE_URL + "rest-auth/login/", data)
      .then((res) => {
        sessionStorage.setItem("token", res.data.key);
        window.location.href = "/home";
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ error: "Неправильный пользователь или парол" });
      });
  };

  render() {
    return (
      <>
        <div className="main-div">
          {this.state.error ? (
            <div className="alert alert-danger col-3" role="alert">
              {this.state.error}
            </div>
          ) : (
            <div></div>
          )}
          <div className="login">
            <h3 className="text-center">Login forma</h3>
            <form onSubmit={(event) => this.handleSubmit(event)}>
              <div className="form-group input-div">
                <span>
                  <FaIcons.FaUserAlt />
                </span>
                <input
                  type="text"
                  className="input-form"
                  placeholder="Telefon"
                  autoComplete="off"
                  id="username"
                  name="username"
                  value={this.state.username}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="form-group input-div">
                <span>
                  <FaIcons.FaLock />
                </span>
                <input
                  type={this.state.type ? "password" : "text"}
                  className="input-form"
                  placeholder="Parol"
                  id="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                  required
                />
                <span onClick={this.togglePassword}>
                  {this.state.type ? (
                    <BsIcons.BsEyeFill />
                  ) : (
                    <BsIcons.BsEyeSlashFill />
                  )}
                </span>
              </div>
              <button type="submit" className="btn btn-primary but">
                Login
              </button>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(LoginPage);
