import React from "react";
import axios from "axios";
import { BASE_URL } from "../../common/api";
import { withRouter } from "react-router-dom";

class surveys extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      surveys: [],
    };
  }

  componentDidMount() {
    axios.get(BASE_URL + "surveys/").then((res) => {
      const surveys = res.data;
      this.setState({ surveys });
    });
  }
  redirect = (id) => {
    if (id) {
      this.props.history.push(`/addsurvey/${id}`);
      console.log("mana");
    } else {
      this.props.history.push("/addsurvey/add");
    }
  };
  deleteSurvey = (id) => {
    axios
      .delete(BASE_URL + "surveys/" + id + "/")
      .then((res) => {
        // Successful delete request
        // You can update the state to remove the deleted survey from the list
        // by filtering out the deleted survey from the list of surveys
        this.setState({
          surveys: this.state.surveys.filter((survey) => survey.id !== id),
        });
      })
      .catch((err) => {
        // Handle error
        console.log(err);
      });
  };

  render() {
    return (
      <>
        <h1>So'rovnomalar</h1>
        <button className="btn btn-primary" onClick={() => this.redirect()}>
          Yangisini qo'shish
        </button>
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>So'rovnomalar</th>
              <th>Amallar</th>
            </tr>
          </thead>
          <tbody>
            {this.state.surveys.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.nameUz}</td>
                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() => this.deleteSurvey(item.id)}
                  >
                    Delete
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => this.redirect(item.id)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() =>
                      this.props.history.push("results/" + item.id)
                    }
                  >
                    Result
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }
}
export default withRouter(surveys);
