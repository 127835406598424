import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import BaseLayout from "./layouts/BaseLayout";

class App extends React.Component {
  render() {
    return (
      <div>
        <BaseLayout />
      </div>
    );
  }
}
export default App;
