import React from "react";
import axios from "axios";
import { BASE_URL, HEADERS, CURRENTUSER } from "../api";
import "../../assets/dropdown.css";
import Cookies from "universal-cookie";

import {
  Admin,
  Katalog,
  Main,
  Logistika,
  Analitika,
  Sotuv,
  Info,
  Magazin
} from "./SidebarData";

const cookies = new Cookies();
export default class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: true,
      is_superuser: false,
      groups: [],
      permissions: [],
    };
    this._isMounted = false;
  }

  componentDidMount() {
    axios
      .get(BASE_URL + CURRENTUSER, HEADERS)
      .then((res) => {
        const user = res.data;
        const role_name = user.role_name;
        if (role_name.toLowerCase() === "sotuvchi") {
          this.setState({ permission: false });
          cookies.set("hodim", "sotuvchi");
        } else {
          cookies.set("hodim", "admin");
        }
        this.setState({
          is_superuser: res.data.is_superuser,
          groups: res.data.groups,
        });
      })
      .then(() => {
        this.state.groups.forEach((group) => {
          this.setState({
            permissions: [...this.state.permissions, group.name],
          });
        });
      });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { permissions, permission } = this.state;
    return (
      <ul className="my-nav-links">
        <Main permissions={permissions} />
        <Analitika permissions={permissions} />
        <Admin permissions={permissions} />
        <Katalog permissions={permissions} permission={permission} />
        <Logistika permissions={permissions} />
        <Sotuv permissions={permissions} />
        <Info permissions={permissions} />
        <Magazin permissions={permissions} />
      </ul>
    );
  }
}
