import Role from "../views/admin/Role";
import Admin from "../views/admin/Admin";
import Region from "../views/admin/Region";
import District from "../views/admin/District";
import BaseLayout from "../layouts/BaseLayout";
import MainPage from "../views/main";
import PasswordChange from "../views/admin/PasswordChange";

var routes = [
  {
    path: "/home",
    name: "Home",
    component: MainPage,
  },
  {
    path: "/roles",
    name: "Role",
    component: Role,
  },
  {
    path: "/admins",
    name: "Admin",
    component: Admin,
  },
  {
    path: "/regions",
    name: "Region",
    component: Region,
  },
  {
    path: "/region/:id",
    name: "Region",
    component: District,
  },
  {
    path: "/password-change",
    name: "PasswordChange",
    component: PasswordChange
  },
];

export default routes;
