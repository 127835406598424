import React from 'react';
import axios from 'axios';
import { BASE_URL, HEADERS, REGIONS, SHOPS } from '../../common/api';
import { MapContainer, TileLayer } from "react-leaflet";
import { Marker, useMap, useMapEvents } from "react-leaflet";
import {useState, useEffect} from 'react';


function ChangeView({ center }) {
  const map = useMap();
  map.setView(center);
  return null;
}

const SomeComponent = (props) => {

  let initialPosition = [props.lat, props.lng];
  const [selectedPosition, setSelectedPosition] = useState(null);

  if (!props.lat) {
    initialPosition = [41.311081, 69.240562]
  }


  const Markers = () => {

    const map = useMapEvents({
      click(e) {
        setSelectedPosition([e.latlng.lat, e.latlng.lng]);
        props.getCoords(e.latlng.lat, e.latlng.lng)
      },
    });

    return <>
        <Marker
        key={initialPosition[0]}
        position={selectedPosition===null ? (initialPosition) : (selectedPosition)}
        interactive={true} />
        </>
    

   
  };

  return (
    <>
    <MapContainer
      center={selectedPosition || initialPosition}
      zoom={13}
      style={{ height: "400px" }}
    >
      <ChangeView center={selectedPosition || initialPosition} zoom={15} />
      <Markers />
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
    </MapContainer>
    </>
  );
};




export default class SingleShowroom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            regions: [],
            name_uz: "",
            name_ru: "",
            address_uz: "",
            address_ru: "",
            orienter_uz: "",
            orienter_ru: "",
            region: "",
            main_phone: "",
            extra_phone: "",
            lng: "",
            lat: "",
            lngNew: 0,
            latNew: 0,
            image: null,
            isActive: false,
        }
    }

    handleClick = (lat, lng) => {
      this.setState({latNew: lat, lngNew: lng})
    }

    handleChange = (event) => {
      this.setState({[event.target.name]: event.target.value})
    }

    onImageChange = (event) => {
      this.setState({ image: event.target.files[0] });
  };

    componentDidMount() {
        const {id} = this.state;
        axios.get(BASE_URL + REGIONS)
        .then(res => this.setState({regions: res.data}))
        if(id==='add') {
          return
        }
        else {
          axios.get(BASE_URL + SHOPS + id)
        .then(res => 
            {
                const data = res.data;
                this.setState({
            name_uz: data.name_uz,
            name_ru: data.name_ru,
            address_uz: data.address_uz,
            address_ru: data.address_ru,
            orienter_uz: data.orienter_uz,
            orienter_ru: data.orienter_ru,
            region: data.region,
            main_phone: data.main_phone,
            extra_phone: data.extra_phone,
            lng: data.lng,
            lat: data.lat,
            image: data.image,
            isActive: data.isActive,
                })
            }
            );
        }

    }

    handleSubmit = (event, id) => {
      const {latNew, lngNew, name_uz, name_ru, address_uz, address_ru, orienter_uz, orienter_ru, region, main_phone, extra_phone, image, isActive} = this.state;
      event.preventDefault();
      let formData = new FormData();
        if (image !== null && image.name) {
          formData.append("image", image, image.name);
      }
      let data = {
        name_uz: name_uz,
        name_ru: name_ru,
        address_uz: address_uz,
        address_ru: address_ru,
        orienter_uz: orienter_uz,
        orienter_ru: orienter_ru,
        region: region,
        main_phone: main_phone,
        extra_phone: extra_phone,
        isActive: isActive,
      }
      if (latNew > 0 && lngNew > 0) {
        data.lat = latNew;
        data.lng = lngNew;
      }
      Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
      })
      if(id==="add") {
        axios.post(BASE_URL + SHOPS, formData, HEADERS)
        .then(this.props.history.push('/showroom'))
        .catch(err => console.log(err.response))
      }
      else {
        axios.patch(BASE_URL + SHOPS + id + "/", formData, HEADERS)
        .then(this.props.history.push('/showroom')).catch(err => console.log(err.response, err.message));
      }
    }

    render() {
        const {image, id, name_uz, name_ru, address_uz, address_ru, orienter_uz, orienter_ru, region, main_phone, extra_phone, lng, lat, isActive, regions} = this.state;
        return (
            <>
                <div className="pt-2 m-2">
                   <div className="filters">
                     <div className="row">
                        <div className="col-3">
                        <form>
            <div className="form-group">
    <label htmlFor="textInput">Nomi</label>
    <input type="text" className="form-control" 
    defaultValue={name_uz} name="name_uz"
    id="textInput" aria-describedby="emailHelp" onChange={this.handleChange}  />
  </div>
                        </form>
                        </div>
                    <div className="col-3">
                        <form>
            <div className="form-group">
    <label htmlFor="textInput">Наименование</label>
    <input type="text" className="form-control" 
    defaultValue={name_ru} name="name_ru"
    id="textInput" aria-describedby="emailHelp" onChange={this.handleChange}  />
  </div>
                        </form>
                        </div>
                    <div className="col-3">
                        <form>
            <div className="form-group">
    <label htmlFor="textInput">Manzil</label>
    <input type="text" className="form-control" 
    defaultValue={address_uz} name="address_uz"
    id="textInput" aria-describedby="emailHelp" onChange={this.handleChange}  />
  </div>
                        </form>
                        </div>
            <div className="col-3">
                        <form>
            <div className="form-group">
    <label htmlFor="textInput">Адрес</label>
    <input type="text" className="form-control" 
    defaultValue={address_ru} name="address_ru"
    id="textInput" aria-describedby="emailHelp" onChange={this.handleChange}  />
  </div>
                        </form>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-3">
                            <form>
            <div className="form-group">
    <label htmlFor="textInput">Mo'ljal</label>
    <input type="text" className="form-control" 
    defaultValue={orienter_uz} name="orienter_uz"
    id="textInput" aria-describedby="emailHelp" onChange={this.handleChange}  />
  </div>
                        </form>
                        </div>
                        <div className="col-3">
                            <form>
            <div className="form-group">
    <label htmlFor="textInput">Ориентир</label>
    <input type="text" className="form-control" 
    defaultValue={orienter_ru} name="orienter_ru"
    id="textInput" aria-describedby="emailHelp" onChange={this.handleChange}  />
  </div>
                        </form>
                        </div>
                        <div className="col-3">
                            <form>
                            <div className="form-group">
    <label htmlFor="exampleFormControlSelect1">Statusi</label>
    <select className="form-control" id="exampleFormControlSelect1" onChange={this.handleChange}
                      name="isActive"
                      value={isActive}>
      <option value="false">Passiv</option>
      <option value="true">Aktiv</option>

    </select>
  </div>
                        </form>
                        </div>
                        <div className="col-3">
                            <form>
            <div className="form-group">
    <label htmlFor="textInput">Hudud</label>
    <select name="region" id=""  className="form-control" value={region} onChange={this.handleChange}>
      <option hidden>Hudud tanlang</option>
      {regions.map(item => (
        <option value={item.id} key={item.id}>{item.nameUz}</option>
      ))}
    </select>
  </div>
                        </form>
                        </div>
                    </div>
                   </div>
                    <div className="row filters">
                    <div className="col-12">
                        <SomeComponent lat={lat} lng={lng} getCoords={this.handleClick} />
                    </div>
                    </div>
                    <div className="row filters">
                    <div className="col-6">
                        <form>
                          <div className="form-group">
                            <label htmlFor="textInput">Telefon raqam</label>
                            <input type="text" className="form-control" 
                            defaultValue={main_phone} name="main_phone"
                            id="textInput" aria-describedby="emailHelp" onChange={this.handleChange}  />
                          </div>
                        </form>
                    </div>
                    <div className="col-6">
                        <form>
                          <div className="form-group">
                            <label htmlFor="textInput">Qo'shimcha raqam</label>
                            <input type="text" className="form-control" 
                            defaultValue={extra_phone} name="extra_phone"
                            id="textInput" aria-describedby="emailHelp" onChange={this.handleChange}  />
                          </div>
                        </form>
                    </div>
                    </div>
                    <div className="row filters">
                      <div className="col-3">
                            {image && <img src={image} alt="" className="img-fluid" /> }
                        </div>
                        <div className="col-3">
                            <form>
                <div className="form-group">
                    <label htmlFor="exampleFormControlFile1">Rasm</label>
                    <input type="file" className="form-control-file" id="exampleFormControlFile1" name="image" onChange={this.onImageChange} />
                </div>
                        </form>
                        </div>
                    </div>
                <button className='btn btn-primary my-2' onClick={(event)=>this.handleSubmit(event, id)}>Saqlash</button>
                </div>

            </>
        )
    }
}