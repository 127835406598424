import React, { useContext } from 'react';
import { Context } from '../js/Provider';

const OpenClose = () => {
  const { show, toggleShow } = useContext(Context);

  return (
    <div className="my-sidebar-handler" onClick={toggleShow}>
        <i
          className={show ? "fa-solid fa-indent fa-lg" : "fa-solid fa-outdent fa-lg"}
          ></i>
        </div> 
  );
};

export default OpenClose;