import React from "react";
import axios from "axios";
import { BASE_URL, REGIONS, HEADERS } from "../../common/api";
import { Modal, Form, Button } from "react-bootstrap";

export default class Warehouse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      data: [],
      regions: [],
      regionsData: [],
      collect: [],
      show: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  handleClick = (event, id) => {
    event.preventDefault();
    axios.get(BASE_URL + `delivery-dates/?warehouse=${id}`, HEADERS).then((res) => {
      const data = res.data;
      this.setState({ regions: data, show: true, id: id });
      console.log(data);
    });
  };

  hideModal = () => {
    this.setState({
      show: false,
    });
  };

  handleChange = (event, el) => {
    const { collect } = this.state;

    if (event.target.type === "checkbox") {
      event.target.value = event.target.checked;
    }

    if (collect.length === 0) {
      collect.push({ id: el, [event.target.name]: event.target.value });
    } else {
      const index = collect.findIndex((x) => x.id === el);
      if (index === -1) {
        collect.push({ id: el, [event.target.name]: event.target.value });
      } else {
        collect[index][event.target.name] = event.target.value;
      }
    }

    console.log(collect);
  };

  componentDidMount() {
    axios.get(BASE_URL + "warehouses/", HEADERS).then((res) => {
      const data = res.data;
      console.log(res.data);
      this.setState({ data });
    });
    axios.get(BASE_URL + REGIONS, HEADERS).then((res) => {
      const data = res.data;
      this.setState({ regionsData: data });
    });
  }

  update(event) {
    console.log("here");
    event.preventDefault();
    const { collect } = this.state;
    collect.forEach((item, index) => {
      axios.patch(BASE_URL + `delivery-dates/${item.id}/`, item, HEADERS).then((res) => {
        console.log(res.data);
      });
    });
    this.hideModal();
  }

  filter = (id) => {
    this.props.history.push("single-warehouse/" + id);
  };

  render() {
    const { show } = this.state;
    return (
      <>
        <Modal show={show} onHide={() => this.hideModal()} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Kun</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {this.state.regions.map((item, index) => {
                return (
                  <Form.Group
                    className="mb-3 row"
                    controlId={item.id}
                    key={item.id}
                  >
                    <div className="col-6">
                      <Form.Label>{item.region_name}</Form.Label>
                      <Form.Control
                        type="number"
                        defaultValue={item.delivery_day}
                        name="delivery_day"
                        onChange={(event) => this.handleChange(event, item.id)}
                      />
                    </div>
                    <div className="col-6">
                      <Form.Label>Statusi</Form.Label>
                      <Form.Check
                        type="checkbox"
                        defaultChecked={item.isActive}
                        name="isActive"
                        onChange={(event) => this.handleChange(event, item.id)}
                      />
                    </div>
                  </Form.Group>
                );
              })}
              <Button
                variant="primary"
                type="button"
                onClick={(event) => this.update(event)}
              >
                Save
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
        <div className="filters row">
          <div className="col-12">
            <Form.Control as="select" onChange={this.handleFilter} name="brand">
              <option hidden>Viloyat tanlang</option>
              {this.state.regionsData.map((cat) => (
                <option value={cat.id} key={cat.id}>
                  {cat.nameUz}
                </option>
              ))}
            </Form.Control>
          </div>
        </div>
        <div>
          <table className="table">
            <thead>
              <tr>
                <th>NameUz</th>
                <th>NameRu</th>
                <th>Region</th>
                <th>Amallar</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.name_uz}</td>
                    <td>{item.name_ru}</td>
                    <td>{item.region}</td>
                    <td>
                      <button
                        className="btn btn-secondary mx-2"
                        onClick={(event) => this.handleClick(event, item.id)}
                      >
                        <i className="fa-solid fa-circle-info"></i>
                      </button>
                      <button
                        className="btn btn-light border-secondary"
                        onClick={() => this.filter(item.id)}
                      >
                        <i className="fa-solid fa-chevron-right"></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}
