import React from 'react';
import axios from 'axios';
import { BASE_URL, DISTRICTS, HEADERS } from '../../common/api';
import Table from '../../assets/js/Table';
import { Modal, Form, Button } from "react-bootstrap";


const columns = [
  { field: "nameUz", header: "Nomlanishi" },
];


export default class District extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            region_id: this.props.match.params.id,
            id: 0,
            data: [],
            nameUz: "",
            nameRu: "",
            confirm: false,
            show: false,
        }

        this._isMounted = false;
    }

    showModal = () => {
        this.setState({ show: true});
    };

    showConfirmationModal = (id) => {
      this.setState({confirm: true, id: id})
    }

    hideModal = () => {
        this.setState({ show: false, confirm: false });
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };


    componentDidMount() {
        const {region_id} = this.state;
        axios.get(BASE_URL + DISTRICTS +`?region=${region_id}`)
        .then(res => this.setState({data: res.data}))
    }

    componentDidUpdate(prevProps, prevState) {
    if(prevState.show && !this.state.show) {
      this.setState({
        id: "",
        nameUz: "",
        nameRu: "",
        show: false,
      })
    }
  }

    getTitle = () => {
    return this.state.id ? "O'zgartirish" : "Yangi qo'shish";
  };

    get = (row) => {
            this.showModal();
            this.setState({
                id: row.id,
                nameUz: row.nameUz,
                nameRu: row.nameRu,
                image: row.image,
                isActive: row.isActive,
            });
        }

    delete = (id, event) => {
        event.preventDefault();
        const { data } = this.state;
        axios
        .delete(BASE_URL + 'districts/' + id + "/")
        .then((res) => {
            const updatedData = data.filter((item) => item.id !== id);
            this.setState({ data: updatedData, confirm: false });
        })
        .catch((err) => {
            console.log(err.response);
        });
    };

    addOrUpdate = (event) => {
        event.preventDefault();
        const { region_id, id, nameUz, nameRu, data } = this.state;
        const formData = new FormData();
        formData.append("region", region_id);
        formData.append("nameUz", nameUz);
        formData.append("nameRu", nameRu);

        if(id) {
            axios.put(BASE_URL + DISTRICTS + id + "/", formData, HEADERS)
            .then(res => {
                const index = data.findIndex((region) => region.id === id);
                data[index] = res.data;
                this.setState({data: data, show: false});
            })
            .catch((err) => {
                console.log(err.response, err.message)
            });
        } else {
            axios.post(BASE_URL + DISTRICTS, formData, HEADERS)
            .then(res => {
                this.setState({data: [...this.state.data, res.data], show: false});
            })
            .catch((err) => {
                console.log(err.response, err.message)
            });
        }
    }


    render() {
        const {data, show, nameUz, nameRu, id, confirm} = this.state;
        return (
            <>
            <Modal show={show} onHide={()=>this.hideModal()} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.getTitle()}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                    key={this.state.id}
                  >
                    <Form.Label>O'zbekcha nomlanishi:</Form.Label>
                    <Form.Control
                      type="text"
                      name="nameUz"
                      value={nameUz}
                      onChange={this.handleChange}
                    />
                    <Form.Label>Ruscha nomlanishi:</Form.Label>
                    <Form.Control
                      type="text"
                      name="nameRu"
                      value={nameRu}
                      onChange={this.handleChange}
                    />
                    
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="button"
                    onClick={this.addOrUpdate}
                  >
                    Saqlash
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
            <Table
            data={data}
            columns={columns} 
            addFunc={this.showModal}
            editFunc={(row) => this.get(row)}
            deleteFunc={(event, id)=>this.showConfirmationModal(event, id)}
            />

            {/* Delete confirmation modal field */}
          <Modal
            show={confirm}
            onHide={() => {
                this.hideModal();
              }}
              animation={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>O'chirish</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>Haqiqatan ham ushbu ma'lumotni o'chirmoqchimisiz?</h5>
                <button className="btn btn-danger m-1" onClick={(event) => this.delete(id, event)} >O'chirish</button>
                <button className="btn btn-light outline-secondary" onClick={() => {
                this.hideModal();
              }} >Qaytish</button>
              </Modal.Body>
            </Modal>
            </>
            
        )
    }
}