import React from "react";
import AppHeader from "../common/header";
import { ShowSidebar } from "../assets/js/Button";
import Routes from "../routes";
import { Route, Redirect } from "react-router-dom";
import { Router } from "react-router";
import LoginPage from "../views/loginPage";
import { createBrowserHistory } from "history";
import { Provider } from '../assets/js/Provider';

const history = createBrowserHistory();

class BaseLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: sessionStorage.getItem("token"),
      show: false,
    };
    this.handleShow = this.handleShow.bind(this);
  }

  handleShow = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  render() {
    const { token } = this.state;
    return (
      <>
        <Router history={history}>
          <Provider>
          {token ? (

            <div className="main-content">
              <ShowSidebar />

              <div className="my-home-section">
                <div className="my-home-content">
                  <AppHeader />
                </div>
                <div>
                  <Routes />
                </div>
              </div>
            </div>
          ) : (
            <Route Redirect to="/login" exact component={LoginPage} />
            
          )}
          </Provider>
        </Router>
      </>
    );
  }
}

export default BaseLayout;
