import React, { Component } from "react";
import axios from "axios";
import { BASE_URL, SIZES, HEADERS } from "../../common/api";
import { Form } from "react-bootstrap";
import Pagination from "../../common/pagination";


export class Size extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sizes: [],
      status: 0,
      filters: {
        width: "",
        length: "",
      },
      currentPage: 1,
      totalPages: 1
    };
    this._isMounted = false;
  }

  handleFilter = (event, stateVariable) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [stateVariable]: event.target.value,
      },
    });
  };

  handleCancel = (stateVariable) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [stateVariable]: "",
      },
    });
  };

  handlePageChange(pageNumber) {
    this.setState({
      currentPage: pageNumber
    });
    this.getData(pageNumber)
  }

  getData = (pageNumber=1) => {
    this._isMounted = true;
    axios
      .get(BASE_URL + SIZES + `?page=${pageNumber}`, { params: this.state.filters },HEADERS)
      .then((res) => {
        if (this._isMounted) {
          this.setState({ sizes: res.data.results, totalPages: Math.ceil(res.data.count / 21) });
        }
      })
      .catch((err) => {
        this.setState({ status: err.response.status });
      });
  }

  componentDidMount() {
    this.getData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filters !== this.state.filters) {
      this.getData();
    }
  }

  render() {
    const {currentPage, totalPages} = this.state;
    console.log(totalPages);
    return (
      <div>
        <div className="filters row">
          <div className="col-6">
            <Form.Label>Kengligi:</Form.Label>
            <Form.Control
              type="number"
              name="width"
              onChange={(event) => this.handleFilter(event, "width")}
            />
          </div>
          <div className="col-6">
            <Form.Label>Uzunligi:</Form.Label>
            <Form.Control
              type="number"
              name="length"
              onChange={(event) => this.handleFilter(event, "length")}
            />
          </div>
        </div>
        <table className="table table-hover mx-2">
          <thead className="thead-light">
            <tr>
              <th>Kod</th>
              <th>Kengligi</th>
              <th>Balandligi</th>
              <th>Maydoni</th>
              <th>Turi</th>
            </tr>
          </thead>
          <tbody>
            {this.state.sizes.map((size) => (
              <tr key={size.id}>
                <td>{size.vSizeId}</td>
                <td>{size.width}</td>
                <td>{size.length}</td>
                <td>{size.width * size.length}</td>
                <td>{size.shape}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(num)=>this.handlePageChange(num)}
        />
      </div>
    );
  }
}

export default Size;

