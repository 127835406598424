import Order from "../views/sale/Order"
import Showroom from "../views/sale/Showroom"
import SingleOrder from "../views/sale/SingleOrder"
import SingleShowroom from "../views/sale/SingleShowroom";
import Client from "../views/sale/Client";
import Service from "../views/sale/Service";
import SingleService from "../views/sale/SingleService";
import Machine  from "../views/sale/Machine";


var routes = [
    {
        path: '/order',
        name: "Order",
        component: Order
    },
    {
        path: '/single-order/:id/:orderType',
        name: "SingleOrder",
        component: SingleOrder
    },
    {
        path: "/showroom",
        name: "Showroom",
        component: Showroom
    },
    {
        path: "/single-showroom/:id",
        name: "SingleShowroom",
        component: SingleShowroom,
    },
    {
        path: "/clients",
        name: "Client",
        component: Client
    },
    {
        path: "/services",
        name: "Service",
        component: Service,
    },
    {
        path: "/single-service/:id",
        name: "SingleService",
        component: SingleService,
    },
    {
        path: "/machine",
        name: "Machine",
        component: Machine,
    },
]

export default routes