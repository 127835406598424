import React from "react";
import axios from "axios";
import { BASE_URL, ROLES, ADMIN, HEADERS, REGISTER, WAREHOUSE } from "../../common/api";
import { EditButton, DeleteButton, AddButton } from "../../assets/js/Button";
import { Modal, Form, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import Error from "../../assets/js/Error";


export default class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            show: false,
            confirm: false,
            id: "",
            admins: [],
            status: 0,
            username: "",
            first_name: "",
            last_name: "",
            password: "",
            role: "",
            phone: "",
            warehouses: "",
            receive_message: false,
            roles: [],
            warehouses: [],
            showAlert: true,
        }
        this._isMounted = false;
        this.timer = null;
    }

    showModal = () => {
        this.setState({ show: true });
    }


    showConfirmationModal = (id) => {
      this.setState({confirm: true, id: id})
    }

    hideModal = () => {
        this.setState({ show: false, confirm: false });
    }



    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    getTitle = () => {
        const {id} = this.state;
        if (id) {
            return <h3>O'zgartirish</h3>;
            } else {
            return <h3>Yangi qo'shish</h3>;}
    }

    get = (item) => {

        this.setState({
            id: item.id,
            username: item.username,
            first_name: item.first_name,
            last_name: item.last_name,
            password: item.password,
            role: item.role,
            role_name: item.role_name,
            phone: item.phone,
            warehouse: item.warehouse,
            receive_message: item.receive_message,
            show: true,
        });
    }

    componentDidMount() {
        this.timer = setTimeout(() => {
          this.setState({ showAlert: false });
          }, 3000);
        const arr = [];
        this._isMounted = true;
        axios.get(BASE_URL + ADMIN, HEADERS).then((res) => {
            this._isMounted && this.setState({ admins: res.data, status: res.status });
        }).catch((err)=> {
        this._isMounted && this.setState({status: err.response.status})
      })
      
      this._isMounted &&
      axios.get(BASE_URL + ROLES + 'list/', HEADERS).then((res) => {
        let result = res.data;
        result.map((item) => {
            return arr.push({value: item.id, label: item.title});});
        this._isMounted && this.setState({ roles: res.data, status: res.status});
      }
      ).catch((err)=> {
        this._isMounted && this.setState({status: err.response.status})
      })
      axios.get(BASE_URL + WAREHOUSE, HEADERS).then((res) => {
        this._isMounted && this.setState({warehouses: res.data, status: res.status});
      }).catch((err)=> {
        this._isMounted && this.setState({status: err.response.status})
      })
    }

    componentDidUpdate(prevProps, prevState) {
      if(prevState.show && !this.state.show) {
        this.setState({
          id: "",
          username: "",
          first_name: "",
          last_name: "",
          password: "",
          role: "",
          phone: "",
          warehouse: "",
          receive_message: false,
          showAlert: false,
        })
      }
    }

    componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.timer);
    }

    delete = (id, event) => {
        event.preventDefault();
        const {admins} = this.state;
        axios.delete(BASE_URL + ADMIN + id + '/', HEADERS).then((res) => {
            this.setState({ admins: admins.filter((item) => item.id !== id), confirm: false, id: "" });
        });}

    addOrUpdate = (event) => {
        event.preventDefault();
        const {id, admins, username, password, first_name, last_name, role, phone, receive_message, warehouse} = this.state;
        let data = {
            username: username,
            password: password,
            first_name: first_name,
            last_name: last_name,
            role: role,
            phone: phone,
            receive_message: receive_message,
            warehouse: warehouse,
        }

        if (id) {
            axios.put(BASE_URL + ADMIN + id + '/', data, HEADERS).then((res) => {
                const {admins} = this.state;
                console.log(admins)
                const index = admins.findIndex((item) => item.id === id);
                admins[index] = res.data;
                this.setState({ admins: admins, show: false });
                })
                .catch((error) => {
                    this.setState({ error: error.response.status });
        })
    }
        else {
            axios.post(BASE_URL + REGISTER, data, HEADERS).then((res) => {
                this.setState({ admins: [...admins, res.data], show: false });
            }
        ).catch((error) => {
          this.setState({ error: error.response.status });
        })}
    }


    render() {
        const {admins, show, username, first_name, last_name, password, id, role, roles, confirm, receive_message, phone, showAlert, status, warehouses, warehouse} = this.state;
        const {location} = this.props;
        const message = location.state?.message;
        return (
            <>
            {(message && showAlert) && <div className="alert-div"><Alert variant="success" key="success" onClose={() => this.setState({ showAlert: false })}>{message}</Alert></div>}
            {status === 200 ? (<>
            <Modal
              show={show}
              onHide={() => {
                this.hideModal();
              }}
              animation={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>{this.getTitle()}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={(event) => this.addOrUpdate(event)}>
                  <Form.Group
                    className="mb-3"
                    
                  >
                    <Form.Label>Username:</Form.Label>
                    <Form.Control
                      type="text"
                      name="username"
                      value={username}
                      onChange={this.handleChange}
                    />
                    <Form.Label>Ismi:</Form.Label>
                    <Form.Control
                      type="text"
                      name="first_name"
                      value={first_name}
                      onChange={this.handleChange}
                    />
                    <Form.Label>Familyasi:</Form.Label>
                    <Form.Control
                      type="text"
                      name="last_name"
                      value={last_name}
                      onChange={this.handleChange}
                    />
                    <Form.Label>Telefon raqami:</Form.Label>
                    <Form.Control
                      type="phone"
                      name="phone"
                      value={phone}
                      onChange={this.handleChange}
                    />
                    <Form.Label>SMS yuboriladimi:</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleChange}
                      name="receive_message"
                      value={receive_message}
                    >
                      <option value="true">Ha</option>
                      <option value="false">Yo'q</option>
                    </Form.Control>
                    <Form.Label>Parol:</Form.Label>
                    {id ? (
                        <>
                        <Button variant="primary" type="button" className="m-3">
                          <Link to={{pathname: 'password-change/', state: id}}>Parolni o'zgartirish</Link>
                        </Button>
                        <br />
                        </>
                    ) : 
                    (
                        <Form.Control
                      type="password"
                      name="password"
                      id="password"
                      value={password}
                      onChange={this.handleChange}
                    />
                    )
                    }

                    <Form.Label>Roli:</Form.Label>
                  <Form.Control as="select" onChange={this.handleChange}
                  name="role" value={role} >

                    <option hidden>Выберите рола</option>
                    {roles.map((role)=> (
                      <option value={role.id} key={role.id}>{role.title}</option>
                    ))}
                  </Form.Control>
                  <Form.Label>Skladi:</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleChange}
                      name="warehouse"
                      value={warehouse}
                    >
                      <option hidden>Выберите склад</option>
                      {
                        warehouses.map((warehouse)=> (
                          <option value={warehouse.id} key={warehouse.id}>{warehouse.name_uz}</option>
                        ))
                      }
                    </Form.Control>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={this.showModal}
                  >
                    Saqlash
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
            
            <div>
              <AddButton onClick={this.showModal} />
              <hr />
            </div>


            <table className="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>F.I.Sh</th>
                            <th>Rol</th>
                            <th>Telefon raqami</th>
                            <th>SMS yuboriladimi</th>
                            <th>Amallar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {admins.map(item =>
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.first_name + " " + item.last_name}</td>
                                <td>{item.role_name}</td>
                                <td>{item.phone}</td>
                                <td><p className={item.receive_message ? "badge badge-light text-success" : "badge badge-light text-danger"}>{item.receive_message ? "Ha" : "Yo'q"}</p></td>                
                              <td>
                                <EditButton onClick={() => this.get(item)} />
                                <DeleteButton onClick={()=>this.showConfirmationModal(item.id)} />

                              </td>

                            </tr>
                            )}
                    </tbody>
                </table>


                {/* Delete confirmation modal field */}
          <Modal
            show={confirm}
            onHide={() => {
                this.hideModal();
              }}
              animation={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>O'chirish</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>Haqiqatan ham ushbu ma'lumotni o'chirmoqchimisiz?</h5>
                <button className="btn btn-danger m-1" onClick={(event) => this.delete(id, event)} >O'chirish</button>
                <button className="btn btn-light outline-secondary" onClick={() => {
                this.hideModal();
              }} >Qaytish</button>
              </Modal.Body>
            </Modal>
            </>) : (<Error status={status} />)}
            </>
        )
    }
}