import React, { Component } from 'react'
import axios from 'axios';
import { BASE_URL, SERVICES, HEADERS, REGIONS, DISTRICTS, DRIVERS } from '../../common/api';
import { Form } from 'react-bootstrap';
import makeAnimated from "react-select/animated";
import Select from 'react-select';

const animatedComponents = makeAnimated();

export default class SingleService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            data: {},
            drivers: [],
            regions: [],
            districts: [],
        }
        this._isMounted = false;
    }

      // Handle change in event
    handleChange = (event) => {
        this.setState({ data: {
            ...this.state.data,
            [event.target.name]: event.target.value
        } });
    };

    handleFilter = (event, stateVariable) => {
        const value = event ? event.value : '';
        this.setState({
        data: {
        ...this.state.data,
        [stateVariable]: value
    },
    })
    };

    componentDidMount() {
        const {id} = this.state;
        this._isMounted = true;
        this._isMounted && axios.get(BASE_URL + REGIONS, HEADERS)
        .then(res => {
            const arr = [];
            const data = res.data;
            data.map((el) => {
                return arr.push({ value: el.id, label: el.nameUz });
            });
            this.setState({ regions: arr });
        })
        this._isMounted && axios.get(BASE_URL + DISTRICTS, HEADERS)
        .then(res => {
            const arr = [];
            const data = res.data;
            data.map((el) => {
                return arr.push({ value: el.id, label: el.nameUz, region: el.region });
            });
            this.setState({ districts: arr });
        })
        this._isMounted && axios.get(BASE_URL + DRIVERS, HEADERS)
        .then(res => {
            const arr = [];
            const data = res.data;
            data.map((el) => {
                return arr.push({ value: el.user.id, label: el.first_name });
            });
            this.setState({ drivers: arr });
        })
        if (id === 'add') {
            return
        }
        else {
            this._isMounted && axios.get(BASE_URL + SERVICES + id, HEADERS)
        .then(res => this.setState({data: res.data}))
        }
    }

    componentDidUpdate(prevState) {
        if (prevState.data !== this.state.data) {
            const {districts} = this.state;
            const updatedDistricts = districts.filter(el => el.region === this.state.data.region)

        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    // Function takes id as an argument. If id is a number, it updates fields of that id, otherwise creates a new data in db
    addOrUpdate = (event, reject=false) => {
        event.preventDefault();
        const {id, data} = this.state;
        if (id === 'add') {
            axios.post(BASE_URL + SERVICES, data, HEADERS)
            .then(this.props.history.push('/services'))
            .catch(err=>console.log(err.response))
        }
        else {
            data['status'] = 1
            if(data.deliver) {
                data['status'] = 2
            }
            if(reject) {
                data['status'] = 3
            }
            axios.patch(BASE_URL + SERVICES + id + '/', data, HEADERS)
            .then(this.props.history.push('/services'))
            .catch(err=>console.log(err.response))
        }
    }

    render() {
    const {data, drivers, regions, districts} = this.state;
    console.log(data.status);
    return (
    <>
        <h5 className='text-bold'>Holati: {data.service_status} </h5>
        <div className="filters">
                <div className="row">
                <div className={data.status === 0 ? "col-4" : "col-3"}>
                    <Form.Label>Telefon raqami:</Form.Label>
                    <Form.Control
                    type="text"
                    name="phone"
                    defaultValue={data.phone || ''}
                    onChange={this.handleChange} />
                </div>
                <div className={data.status === 0 ? "col-4" : "col-3"}>
                    <Form.Label>Ismi:</Form.Label>
                    <Form.Control
                    type="text"
                    name="name"
                    defaultValue={data.name || ''}
                    onChange={this.handleChange} />
                </div>
                <div className={data.status === 0 ? "col-4" : "col-3"}>
                    <Form.Label>Izoh:</Form.Label>
                    <Form.Control
                    type="text"
                    name="comment"
                    defaultValue={data.comment || ''}
                    onChange={this.handleChange} />
                </div>
                {data.status !== 0 &&
                <div className="col-3">
                    <Form.Label>Yetkazib beruvchi:</Form.Label>
                    <Select
                    options={drivers}
                    value={drivers.find(el => el.value === this.state.data.deliver)}
                    isClearable={true}
                    placeholder="Tanlang..."
                    onChange={(event) => this.handleFilter(event, "deliver")} />
                    
                </div>
                }
            </div>
            <div className="row pt-2">
                <div className="col-3">
                    <Form.Label>Hudud:</Form.Label>
                    <Select
                    options={regions}
                    value={regions.find(el => el.value === this.state.data.region)}
                    isClearable={true}
                    placeholder="Tanlang..."
                    onChange={(event) => this.handleFilter(event, "region")} />
                </div>
                <div className="col-3">
                    <Form.Label>Tuman:</Form.Label>
                    <Select
                    options={districts.filter(el => el.region === this.state.data.region)}
                    value={districts.find(el => el.value === this.state.data.district)}
                    isDisabled={this.state.data.region ? false : true}
                    isClearable={true}
                    placeholder="Tanlang..."
                    onChange={(event) => this.handleFilter(event, "district")} />
                </div>
                <div className="col-3">
                    <Form.Label>Manzil:</Form.Label>
                    <Form.Control
                    type="text"
                    name="address"
                    defaultValue={data.address || ''}
                    onChange={this.handleChange} />
                </div>
                <div className="col-3">
                    <Form.Label>Sanagacha:</Form.Label>
                    <Form.Control
                    type="date"
                    name="to_date"
                    defaultValue={data.to_date || ''}
                    onChange={this.handleChange} />
                </div>
            </div>
            {(data.status !== 3 && data.status !== 4) && <button className="btn btn-primary mt-4" onClick={(event)=>this.addOrUpdate(event)}>{this.state.id === 'add' ? 'Tasdiqlash' : 'Qabul qilish'}</button>}
            <button className="btn btn-danger mt-4 ml-2" onClick={(event)=>this.addOrUpdate(event, true)} disabled={data.status === 3 || data.status === 4}>Bekor qilish</button>
        </div>
    </>
    )
    }
}
