import Warehouse from "../views/logistics/Warehouse";
import SingleWarehouse from "../views/logistics/SingleWarehouse";
import DeliveryMethods from "../views/logistics/DeliveryMethods";
import SingleOrder from "../views/sale/SingleOrder";
import Driver from "../views/logistics/Driver";

var routes = [
      
    {
        path: '/single-order/:id/:orderType',
        name: "SingleOrder",
        component: SingleOrder
    },
    {
        path: "/warehouse",
        name: "Warehouse",
        component: Warehouse,
    },
    {
        path: "/single-warehouse/:id",
        name: "Single-Warehouse",
        component: SingleWarehouse
    },
    {
        path: "/delivery-methods",
        name: "DeliveryMethods",
        component: DeliveryMethods
    },
    {
        path: "/drivers",
        name: "Driver",
        component: Driver
    }
 
]

export default routes;