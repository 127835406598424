import React from "react";
import axios from "axios";
import { BASE_URL } from "../../common/api";
import { EditButton } from "../../assets/js/Button";


export default class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        }
    }

    componentDidMount() {
        axios.get(BASE_URL + 'pages/')
        .then(res => this.setState({data: res.data}))
    }

    editPage = (id) => {
        this.props.history.push(`/single-id/${id}`)
    }

    render() {
        const {data} = this.state;
        return (
            <>
                <h4>Sahifalar</h4>
                <table className="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Sahifa</th>
                            <th>Amal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(item =>
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.titleUz}</td>
                                
                              <td>
                                <EditButton onClick={() => this.editPage(item.id)} />
                              </td>

                            </tr>
                            )}
                        
                    </tbody>
                </table>
            </>
        )
    }
}