import React from "react";
import axios from "axios";
import { BASE_URL, POSTS, HEADERS } from "../../common/api";
import Table from "../../assets/js/Table";
import Error from "../../assets/js/Error";
import { AddButton } from "../../assets/js/Button";

const columns = [
    {field: "id" , header: "ID"},
  { field: "image", header: "Rasmi" },
  {field: "titleUz", header: "Sarlavha"},
  { field: "isActive", header: "Statusi" },
  {field: "first_name", header: "Xodim"}
];

export default class Post extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      status: 0,
    };
    this._isMounted = false;
  }



  // GET data
  componentDidMount() {
    this._isMounted = true;
    this._isMounted &&
      axios
        .get(BASE_URL + POSTS, HEADERS)
        .then((res) => {
          this._isMounted &&
            this.setState({ posts: res.data, status: res.status });
        })
        .catch((err) => {
          this._isMounted && this.setState({ status: err.response.status });
        });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  add = () => {
    this.props.history.push("/single-post/add");
  }
  edit = (id) => {
    this.props.history.push(`/single-post/${id}`);
};

    delete = (id) => {
        axios.delete(BASE_URL + POSTS + id, HEADERS)
        .then((res) => {
            this.setState({ posts: this.state.posts.filter((post) => post.id !== id) });
        })
        .catch((err) => {
            this.setState({ status: err.response.status });
        });
    };


  render() {
    const {
      posts,
      status,
    } = this.state;
    if (!posts) {
      return null;
    }
    return (
      <>
        {status === 200 ? (
          <>
          
            <Table
              addFunc={this.add}
              data={posts}
              columns={columns}
              title="Postlar"
              editFunc={(row) => this.edit(row.id)}
              deleteFunc={(id, event) => this.delete(id, event)}
            />
          </>
        ) : (
          <Error status={status} />
        )}
      </>
    );
  }
}