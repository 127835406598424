import React from "react";
import axios from "axios";
import { BASE_URL, HEADERS } from "../../common/api";
import { Editor } from "@tinymce/tinymce-react";

export default class SinglePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      data: {},
    };
    this.myRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  log = () => {
    if (this.myRef.current) {
      console.log(this.myRef.current.value);
    }
  };

  handleChange = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleTinyChange = (content, editor) => {
    const name = editor.id;
    this.setState({ data: { ...this.state.data, [name]: content } });
  };

  componentDidMount() {
    axios.get(BASE_URL + `pages/${this.state.id}/`).then((res) => {
      const data = res.data;
      this.setState({ data });
    });
  }

  handleSubmit = (event, id) => {
    event.preventDefault();

    axios
      .put(BASE_URL + `pages/${id}/`, this.state.data, HEADERS)
      .then(this.props.history.push("/pages"));
  };

  render() {
    const { data } = this.state;
    console.log(this.state);
    return (
      <>
        <div className="pt-2 m-2" key={data.id}>
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <form>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Sarlavha</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={data.titleUz}
                    name="titleUz"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    onChange={this.handleChange}
                  />
                </div>
              </form>
            </div>
            <div className="col-sm-12 col-lg-6">
              <form>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Заголовок</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={data.titleRu}
                    name="titleRu"
                    id="exampleInputEmail1"
                    onChange={this.handleChange}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <form>
                <div className="form-group">
                  <label htmlFor="textInput">Seo title uz</label>
                  <input
                    type="text"
                    className="form-control"
                    id="textInput"
                    defaultValue={data.seoTitleUz}
                    name="seoTitleUz"
                    onChange={this.handleChange}
                  />
                </div>
              </form>
            </div>
            <div className="col-sm-12 col-lg-6">
              <form>
                <div className="form-group">
                  <label htmlFor="textInput">Seo title ru</label>
                  <input
                    type="text"
                    className="form-control"
                    id="textInput"
                    defaultValue={data.seoTitleRu}
                    name="seoTitleRu"
                    onChange={this.handleChange}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <form>
                <div className="form-group">
                  <label htmlFor="textInput">Seo description Uz</label>
                  <input
                    type="text"
                    className="form-control"
                    id="textInput"
                    defaultValue={data.seoDescriptionUz}
                    name="seoDescriptionUz"
                    onChange={this.handleChange}
                  />
                </div>
              </form>
            </div>
            <div className="col-sm-12 col-lg-6">
              <form>
                <div className="form-group">
                  <label htmlFor="textInput">Seo description Ru</label>
                  <input
                    type="text"
                    className="form-control"
                    id="textInput"
                    defaultValue={data.seoDescriptionRu}
                    name="seoDescriptionRu"
                    onChange={this.handleChange}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <form>
                <div className="form-group">
                  <label htmlFor="textInput">Tasnif</label>
                  <Editor
                    onEditorChange={this.handleTinyChange}
                    apiKey="zaiyqjctvu9iyehzrv81id1239klkgku53rms74jp6bi721o"
                    id="textUz"
                    value={data.textUz}
                    init={{
                      height: 500,
                      menubar: true,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "image code preview | undo redo | blocks | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </div>
              </form>
            </div>
            <div className="col-12">
              <form>
                <div className="form-group">
                  <label htmlFor="textInput">Описание</label>
                  <Editor
                    id="textRu"
                    onEditorChange={this.handleTinyChange}
                    apiKey="zaiyqjctvu9iyehzrv81id1239klkgku53rms74jp6bi721o"
                    value={data.textRu}
                    init={{
                      height: 500,
                      menubar: true,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "image code preview | undo redo | blocks | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </div>
              </form>
            </div>
          </div>
          <button
            className="btn btn-primary m-3"
            onClick={(event) => this.handleSubmit(event, data.id)}
          >
            Saqlash
          </button>
        </div>
      </>
    );
  }
}
