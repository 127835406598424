import React from "react";
import axios from "axios";
import { BASE_URL, REGIONS, HEADERS } from "../../common/api";
import Table from "../../assets/js/Table";
import { Modal, Form, Button } from "react-bootstrap";
import Error from "../../assets/js/Error";

const columns = [
  { field: "image", header: "Rasm" },
  { field: "nameUz", header: "Nomlanishi" },
  { field: "isActive", header: "Statusi" },
];


export default class Region extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            regions: [],
            id: "",
            status: 0,
            nameUz: "",
            nameRu: "",
            image: null,
            isActive: false,
            show: false,
            confirm: false,
        }
        this._isMounted = false;
    }


  showModal = () => {
    this.setState({ show: true });
  };

  showConfirmationModal = (id) => {
      this.setState({confirm: true, id: id})
    }


  hideModal = () => {
    this.setState({ show: false, confirm: false });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onImageChange = (event) => {
    this.setState({ image: event.target.files[0] });
  };

  componentDidMount() {
    this._isMounted = true;
    this._isMounted &&
      axios
        .get(BASE_URL + REGIONS, HEADERS)
        .then((res) => {
          this.setState({ regions: res.data, status: res.status });
        })
        .catch((err) => {
          this.setState({ status: err.response.status });
        });
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.show && !this.state.show) {
      this.setState({
        id: "",
        nameUz: "",
        nameRu: "",
        image: null,
        isActive: false
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getTitle = () => {
    return this.state.id ? "O'zgartirish" : "Yangi qo'shish";
  };

  get = (row) => {
        this.showModal();
        this.setState({
            id: row.id,
            nameUz: row.nameUz,
            nameRu: row.nameRu,
            image: row.image,
            isActive: row.isActive,
        });
    }

  delete = (id, event) => {
    event.preventDefault();
    const { regions } = this.state;
    axios
      .delete(BASE_URL + REGIONS + id, HEADERS)
      .then((res) => {
        const updatedData = regions.filter((brand) => brand.id !== id);
        this.setState({ regions: updatedData, confirm: false });
      })
      .catch((err) => {
        console.log(err.response);
        this.setState({ status: err.response.status, confirm: false });
      });
  };

  // Opens appropriate districts for given region
  filter = (id) => {
    this.props.history.push(`/region/${id}`)
  }

  addOrUpdate = (event) => {
        event.preventDefault();
        const { id, nameUz, nameRu, image, isActive } = this.state;
        const formData = new FormData();
        formData.append("nameUz", nameUz);
        formData.append("nameRu", nameRu);
        if(image !== null && image.name) {
            formData.append("image", image, image.name);
        }
        formData.append("isActive", isActive);
        if(id) {
            axios.patch(BASE_URL + REGIONS + id + "/", formData, HEADERS)
            .then(res => {
                const {regions} = this.state;
                const index = regions.findIndex((region) => region.id === id);
                regions[index] = res.data;
                this.setState({regions: regions, show: false});
            })
            .catch((err) => {

                this.setState({ status: err.response.status });
            });
        } else {
            axios.post(BASE_URL + REGIONS, formData, HEADERS)
            .then(res => {
                this.setState({regions: [...this.state.regions, res.data], show: false});
            })
            .catch((err) => {
 
                this.setState({ status: err.response.status });
            });
        }
    }



    render() {
        const {regions, id, status, nameUz, nameRu, isActive, show, confirm} = this.state;
        return (
 <>
        {status === 200 ? (
          <>
            <Modal
              show={show}
              onHide={() => {
                this.hideModal();
              }}
              animation={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>{this.getTitle()}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={(event) => this.addOrUpdate(event)}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                    key={id}
                  >
                    <Form.Label>O'zbekcha nomlanishi:</Form.Label>
                    <Form.Control
                      type="text"
                      name="nameUz"
                      value={nameUz}
                      onChange={this.handleChange}
                    />
                    <Form.Label>Ruscha nomlanishi:</Form.Label>
                    <Form.Control
                      type="text"
                      name="nameRu"
                      value={nameRu}
                      onChange={this.handleChange}
                    />
                    <Form.Label>Rasm:</Form.Label>
                    <Form.Control
                      type="file"
                      name="image"
                      onChange={this.onImageChange}
                    />
                    <Form.Label>Statusi:</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleChange}
                      name="isActive"
                      value={isActive}
                    >
                      <option value="true">active</option>
                      <option value="false">NOT active</option>
                    </Form.Control>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={this.showModal}
                  >
                    Saqlash
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
            <Table
              addFunc={this.showModal}
              data={regions}
              columns={columns}
              title="Hududlar"
              editFunc={(row) => this.get(row)}
              deleteFunc={(event, id)=>this.showConfirmationModal(event, id)}
              filterFunc={(id)=>this.filter(id)}
            />

            {/* Delete confirmation modal field */}
          <Modal
            show={confirm}
            onHide={() => {
                this.hideModal();
              }}
              animation={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>O'chirish</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>Haqiqatan ham ushbu ma'lumotni o'chirmoqchimisiz?</h5>
                <button className="btn btn-danger m-1" onClick={(event) => this.delete(id, event)} >O'chirish</button>
                <button className="btn btn-light outline-secondary" onClick={() => {
                this.hideModal();
              }} >Qaytish</button>
              </Modal.Body>
            </Modal>
          </>
        ) : (
          <Error status={status} />
        )}
      </>
    );
  }
}



