import React from "react";
import axios from "axios";
import "../../../assets/features.css";
import { BASE_URL, DAYS, HEADERS } from "../../../common/api";
import { DeleteButton, EditButton, AddButton } from "../../../assets/js/Button";
import { Modal, Form, Button } from "react-bootstrap";

class DeliveryDay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      days: [],
      show: false,
      confirm: false,
      date_min: "",
      date_max: "",
      priority: "",
      isActive: false,
      id: "",
    };
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    this._isMounted &&
      axios.get(BASE_URL + DAYS).then((res) => {
        console.log(res.data);
        this.setState({ days: res.data });
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.show && !this.state.show) {
      this.setState({
        show: false,
        date_min: "",
        date_max: "",
        priority: "",
        isActive: false,
        id: "",
      });
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  showModal = () => {
    this.setState({ show: true });
  };

  showConfirmationModal = (event, id) => {
    this.setState({ confirm: true, id: id });
  };

  hideModal = () => {
    this.setState({ show: false, confirm: false });
  };

  get = (data) => {
    this.setState({
      date_min: data.date_min,
      date_max: data.date_max,
      id: data.id,
      priority: data.priority,
      isActive: data.isActive,
      show: true,
    });
  };

  delete = (id) => {
    const { days } = this.state;
    axios.delete(BASE_URL + DAYS + id).then((res) => {
      const updatedData = days.filter((day) => day.id !== id);
      this.setState({ days: updatedData, confirm: false });
    });
  };

  addOrUpdate = (event) => {
    event.preventDefault();
    const { date_min, date_max, priority, isActive, id } = this.state;
    let data = {
      date_min: date_min,
      date_max: date_max,
      priority: priority,
      isActive: isActive,
    };

    if (id) {
      axios.put(BASE_URL + DAYS + id + "/", data, HEADERS).then((res) => {
        const { days } = this.state;
        const index = days.findIndex((day) => day.id === id);
        days[index] = res.data;
        this.setState({ days: days, show: false });
      });
    } else {
      axios.post(BASE_URL + DAYS, data, HEADERS).then((res) => {
        this.setState({
          days: [...this.state.days, res.data],
          show: false,
        });
      });
    }
  };

  getTitle = () => {
    const { id } = this.state;
    if (id) {
      return <h3>O'zgartirish</h3>;
    } else {
      return <h3>Yangi qo'shish</h3>;
    }
  };

  render() {
    const { show, confirm, date_min, date_max, priority, isActive, id } =
      this.state;
    const { permission } = this.props;
    return (
      <>
        <Modal
          show={show}
          onHide={() => {
            this.hideModal();
          }}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.getTitle()}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={(event) => this.addOrUpdate(event)}>
              <Form.Group className="mb-3" key={id}>
                <Form.Label>Dan:</Form.Label>
                <Form.Control
                  type="number"
                  name="date_min"
                  value={date_min}
                  onChange={this.handleChange}
                />
                <Form.Label>Gacha:</Form.Label>
                <Form.Control
                  type="number"
                  name="date_max"
                  value={date_max}
                  onChange={this.handleChange}
                />
                <Form.Label>Prioriteti:</Form.Label>
                <Form.Control
                  type="number"
                  name="priority"
                  value={priority}
                  onChange={this.handleChange}
                />
                <Form.Label>Statusi:</Form.Label>
                <Form.Control
                  as="select"
                  onChange={this.handleChange}
                  name="isActive"
                  value={isActive}
                >
                  <option value="true">active</option>
                  <option value="false">NOT active</option>
                </Form.Control>
              </Form.Group>
              <Button variant="primary" type="submit" onClick={this.showModal}>
                Saqlash
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
        <section id="tab-item-6">
          {permission && <AddButton onClick={() => this.showModal()} />}
          <table className="table table-hover">
            <thead className="thead-light">
              <tr>
                <th>ID</th>
                <th>Dan</th>
                <th>Gacha</th>
                <th>Prioriteti</th>
                <th>Statusi</th>
                <th>Amallar</th>
              </tr>
            </thead>
            <tbody>
              {this.state.days.map((day) => (
                <tr key={day.id}>
                  <td>{day.id}</td>
                  <td>{day.date_min}</td>
                  <td>{day.date_max}</td>
                  <td>{day.priority}</td>
                  <td>
                    <p
                      className={
                        day.isActive
                          ? "badge badge-light text-success"
                          : "badge badge-light text-danger"
                      }
                      role="alert"
                    >
                      {day.isActive ? "Aktiv" : "Passiv"}
                    </p>
                  </td>
                  <td>
                    {permission && <EditButton onClick={() => this.get(day)} />}
                    {permission && (
                      <DeleteButton
                        onClick={(event, id) =>
                          this.showConfirmationModal(event, day.id)
                        }
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
        <Modal
          show={confirm}
          onHide={() => {
            this.hideModal();
          }}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>O'chirish</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Haqiqatan ham ushbu ma'lumotni o'chirmoqchimisiz?</h5>
            <button
              className="btn btn-danger m-1"
              onClick={(event) => this.delete(id, event)}
            >
              O'chirish
            </button>
            <button
              className="btn btn-light outline-secondary"
              onClick={() => {
                this.hideModal();
              }}
            >
              Qaytish
            </button>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default DeliveryDay;
