import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL, HEADERS, DISTRICTS, SIZES } from '../../common/api';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import CurrencyInput from 'react-currency-input-field';

export default class CSO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            districts: [],
            payments: [],
            phone: '',
            saler: null,
            name: '',
            selectedDistrict: null,
            address: '',
            paid_amount: 0,
            comment: '',

            orderItems: [
                {
                    quantity: '',
                    product_id: '',
                    size_id: '',
                    joynamoz: false,
                    photo: null,

                },
            ],

            products: [],
            sizes: [],
            selectedFiles: [],
            data: {},
            selectedProductData: [],
            selectedCurrency: 'UZS', 
            focused: false,

        };
        this._isMounted = false;
    }

    async componentDidMount() {

        this._isMounted = true;

        try {
            const userResponse = await axios.get(BASE_URL + "users/user/", HEADERS);
            const userData = userResponse.data;

            let salerOptions;

            if (userData.warehouse !== null) {
                const warehouseResponse = await axios.get(BASE_URL + `users_warehouse/?warehouse_id=${userData.warehouse}`, HEADERS);
                const warehouseData = warehouseResponse.data;

                salerOptions = warehouseData.map((warehouse) => ({
                    value: warehouse.id,
                    label: warehouse.first_name + " " + warehouse.last_name,
                }));

            } else {
                salerOptions = [{ value: userData.id, label: userData.first_name }];
            }

            // Find the index of the default user in salerOptions
            const defaultUserIndex = salerOptions.findIndex((option) => option.value === userData.id);

            if (defaultUserIndex !== -1) {
                const defaultSaler = salerOptions.splice(defaultUserIndex, 1)[0];
                salerOptions.unshift(defaultSaler);
            }

            this.setState({
                salerOptions,
                saler: salerOptions[0], // Set the default value here
            });

        } catch (error) {
            console.error("Error loading data:", error);
        }

        
        // this._isMounted && axios.get(BASE_URL + DISTRICTS, HEADERS).then(res => {
        await axios.get(BASE_URL + DISTRICTS, HEADERS).then(res => {
            const arr = [];
            const data = res.data;
            data.map((el) => {
                return arr.push({ id: el.id, label: el.nameUz, region: el.region });
            });
            this.setState({ districts: arr });
        })


        await axios.get(BASE_URL + "products-list-all", HEADERS).then((res) => {
            const data = res.data;
            const productsOptions = data.map((el) => ({ value: el.id, label: el.vId }));
            this.setState({ products: productsOptions });
        });

        await axios.get(BASE_URL + SIZES + "?paginate=false", HEADERS).then((res) => {
            const data = res.data;
            const sizesOptions = data.map((el) => ({ value: el.id, label: `${el.width}x${el.length}_${el.type}` }));
            this.setState({ sizes: sizesOptions });
        });

    }

    componentDidUpdate(prevState) {

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleOrderItemsChange = (value, field, index) => {
        this.setState((prevState) => {
            const updatedOrderItems = [...prevState.orderItems];

            
        if (field === 'product_id') {
            updatedOrderItems[index][field] = value.value;

            this.fetchProductData(value.value, index);

        }
    
            if (field === 'product_id' || field === 'size_id') {
                updatedOrderItems[index][field] = value ? value.value : null;
            } else if (field === 'quantity') {
                updatedOrderItems[index][field] = Number(value);
            } else if (field === 'photo') {
                const file = value.target.files[0];
                updatedOrderItems[index][field] = file;
    
            } else {
                updatedOrderItems[index][field] = value;
            }
    
            return { orderItems: updatedOrderItems };
        });
    };

    
    fetchProductData = async (productId, index) => {
        try {
            const response = await axios.get(`${BASE_URL}products/${productId}/`, HEADERS);
            const productData = response.data;

            this.setState((prevState) => {
                const selectedProductData = [...prevState.selectedProductData];
                selectedProductData[index] = productData;
                return { selectedProductData };
            });
        } catch (error) {
            console.error("Error loading product data:", error);
        }
    };

    handleAddItem = () => {
        this.setState((prevState) => {
            return {
                orderItems: [
                    ...prevState.orderItems,
                    {
                        quantity: '',
                        product_id: '',
                        size_id: '',
                        joynamoz: false,
                        photo: null,
                        
                    },
                ],
            };
        });
    };

    loadProductOptions = async (inputValue) => {
        try {
            const response = await axios.get(BASE_URL + "products-list-all", HEADERS);
            const data = response.data;

            const filteredOptions = data
                .filter((option) => option.vId.toLowerCase().includes(inputValue.toLowerCase()))
                .map((option) => ({ value: option.id, label: option.vId }));

            return filteredOptions;
        } catch (error) {
            console.error("Error loading product options:", error);
            return [];
        }
    };

    loadSizeOptions = async (inputValue, selectedOption) => {
        try {
            const response = await axios.get(BASE_URL + SIZES + "?paginate=false", HEADERS);
            const data = response.data;

            const filteredSizes = data
                .filter((option) => {
                    if (selectedOption === 'option1') {
                        return option.type === 0 || option.type === 1;
                    } else if (selectedOption === 'option2') {
                        return option.type === 2;
                    }
                    return true;
                })
                .filter((option) => `${option.width}x${option.length}_${option.type}`.includes(inputValue.toLowerCase()))
                .map((option) => ({ value: option.id, label: `${option.width}x${option.length}_${option.type}` }));

            return filteredSizes;

        } catch (error) {
            console.error("Error loading size options:", error);
            return [];
        }
    };

    loadDistrictOptions = async (inputValue) => {
        try {
            const response = await axios.get(BASE_URL + DISTRICTS, HEADERS);
            const data = response.data;
    
            const filteredDistricts = data
                .filter((option) => option.nameUz.toLowerCase().includes(inputValue.toLowerCase()))
                .map((option) => ({ value: option.id, label: option.nameUz }));
    
            return filteredDistricts;
        } catch (error) {
            console.error("Error loading district options:", error);
            return [];
        }
    };

    handleRemoveItem = (index) => {
        this.setState((prevState) => {
            const updatedOrderItems = [...prevState.orderItems];
            const updatedSelectedProductData = [...prevState.selectedProductData];
            updatedOrderItems.splice(index, 1);
            updatedSelectedProductData.splice(index, 1);

            console.log(updatedOrderItems);
            return { orderItems: updatedOrderItems,
                selectedProductData: updatedSelectedProductData };
        });
    };

    handleFormSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();

        formData.append('phone', this.state.phone);
        formData.append('saler', this.state.saler ? this.state.saler.value : '');
        formData.append('name', this.state.name);
        formData.append('district', this.state.selectedDistrict);
        formData.append('address', this.state.address);
        // formData.append('paid_amount', this.state.paid_amount);
        formData.append('paid_amount', `${this.state.paid_amount} (${this.state.selectedCurrency})`);
        formData.append('comment', this.state.comment);

        this.state.orderItems.forEach((item, index) => {
            formData.append(`order_items[${index}][quantity]`, item.quantity);
            formData.append(`order_items[${index}][product_id]`, item.product_id);
            formData.append(`order_items[${index}][size_id]`, item.size_id);
            formData.append(`order_items[${index}][joynamoz]`, item.joynamoz);

            formData.append(`order_items[${index}][photo]`, this.state.orderItems[index].selectedOption === 'option3' ? item.photo : null)
            
        });


        try {
            await axios.post(BASE_URL + "create-special/", formData, HEADERS).then((response) => {
                this.props.history.push("/specialorders");
            }).catch((error) => {
                console.log(error)
                console.log(error.response.data)
            });


            this.setState({
                phone: '',
                saler: null,
                name: '',
                selectedDistrict: null,
                address: '',
                paid_amount: 0,
                comment: '',
                orderItems: [
                    {
                        quantity: '',
                        product_id: '',
                        size_id: '',
                        joynamoz: false,
                        photo: null,
                    }
                ],

                selectedProductData: [],
            });

            if (this.fileInput) {
                this.fileInput.value = '';
            }

        } catch (error) {
            console.error("Error sending data:", error);
        }
    }


    handleRadioChange = (isChecked, index, option) => {
        this.setState((prevState) => {
            const updatedOrderItems = [...prevState.orderItems];
            updatedOrderItems[index].showInputs = isChecked;
            updatedOrderItems[index].selectedOption = option;
            
            updatedOrderItems[index].joynamoz = option === 'option3' ? true : false;
            updatedOrderItems[index].size_id = '';
            updatedOrderItems[index].quantity = '';
    
            return { orderItems: updatedOrderItems };
        });
    };

    handleCurrencyChange = (selectedCurrency) => {
        this.setState({ selectedCurrency });
    };


    render() {
        const { focused } = this.state;

        const currencyInputStyle = {
            display: 'block',
            width: '100%',
            padding: '0.375rem 0.75rem',
            fontSize: '1rem',
            lineHeight: '1.5',
            color: '#495057',
            backgroundColor: '#fff',
            backgroundClip: 'padding-box',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: '#ced4da',
            borderRadius: '0.25rem',
            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
            ...(focused && {
              color: '#495057',
              backgroundColor: '#fff',
              borderColor: '#80bdff',
              outline: 0,
              boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
            }),
        };

        const selectInputStyles = {
            menu: (provided, state) => ({
                ...provided,
                width: '120px',
                right: '0'
            }),
        };

        const currencyOptions = [
            { value: 'UZS', label: 'UZS' },
            { value: 'USD', label: 'USD' },
        ]

        return (
            <>
                <div className="filters">
                    <Form onSubmit={this.handleFormSubmit}>
                        <div className="row ">
                            <div className="col-4">
                                <Form.Label>Telefon raqami:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="phone"
                                    placeholder='Kiriting...'
                                    value={this.state.phone}
                                    onChange={(e) => this.setState({ phone: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="col-4">
                                <Form.Label>Buyurtma beruvchi Xodim:</Form.Label>
                                <Select
                                    isClearable={true}
                                    placeholder="Tanlang..."
                                    value={this.state.saler}
                                    onChange={(selectedOption) => this.setState({ saler: selectedOption })}
                                    options={this.state.salerOptions}
                                    required
                                />
                            </div>

                            <div className="col-4">
                                <Form.Label>Klient:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='name'
                                    placeholder="Kiriting..."
                                    value={this.state.name}
                                    onChange={(e) => this.setState({ name: e.target.value })}
                                    required
                                />

                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-4">
                                <Form.Label>Tuman:</Form.Label>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={this.loadDistrictOptions}
                                    placeholder="Tanlang..."
                                    value={this.state.selectedDistrict ? { value: this.state.selectedDistrict, label: this.state.districts.find(option => option.id === this.state.selectedDistrict).label } : null}
                                    onChange={(selectedOption) => this.setState({ selectedDistrict: selectedOption ? selectedOption.value : null })}
                                    required
                                 />
                            </div>
                            <div className="col-4">
                                <Form.Label>Manzil:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="address"
                                    placeholder='Kiriting...'
                                    value={this.state.address}
                                    onChange={(e) => this.setState({ address: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="col-4">
                                <Form.Label>Предоплата</Form.Label>
                                <div className='d-flex'>
                                    <CurrencyInput
                                        placeholder="Kiriting..."
                                        // value={this.state.paid_amount}
                                        onValueChange={(value) => this.setState({ paid_amount: value })}
                                        onFocus={() => this.setState({ focused: true })}
                                        onBlur={() => this.setState({ focused: false })}
                                        style={currencyInputStyle}
                                    />
                                    <div className='select-container'>
                                        {/* <select
                                            value={this.state.selectedCurrency}
                                            onChange={(e) => this.handleCurrencyChange(e.target.value)}
                                        >
                                            <option value="UZS">UZS</option>
                                            <option value="USD">USD</option>
                                        </select> */}
                                        <Select
                                            value={{ value: this.state.selectedCurrency, label: this.state.selectedCurrency }}
                                            onChange={(selectedOption) => this.handleCurrencyChange(selectedOption.value)}
                                            options={currencyOptions}
                                            styles={selectInputStyles}
                                            components={{
                                                DropdownIndicator: () => {
                                                    return (  
                                                        <div
                                                            style={{
                                                            height: '100%',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            }}
                                                        >
                                                            <span style={{ border: 'solid lightgray', borderWidth: '0 2.5px 2.5px 0', padding: '3px', transform: 'rotate(45deg)', position: 'relative', right: '12px', bottom: '3px' }}></span>
                                                        </div>
                                                    )}, 
                                                // DropdownIndicator: () => null, 
                                                IndicatorSeparator: () => null,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row pt-2'>
                            <div className="col-12">
                                <Form.Label>Izoh:</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Kiriting..."
                                    value={this.state.comment}
                                    onChange={(e) => this.setState({ comment: e.target.value })}
                                />
                            </div>
                        </div>
                        {this.state.orderItems.map((item, index) => (
                            <div key={index} className='mt-5'>
                                <hr />
                                {this.state.orderItems.length > 1 && (
                                    <Button className='float-right' variant="danger" onClick={() => this.handleRemoveItem(index)}>
                                        Удалить
                                    </Button>
                                )}
                                <div className='row pt-3'>
                                    <div className="col-3">
                                        <Form.Label>Dizayn kodi:</Form.Label>
                                        <AsyncSelect
                                            cacheOptions={10}
                                            defaultOptions={this.state.products.slice(0, 10)}
                                            loadOptions={this.loadProductOptions}
                                            placeholder="Tanlang..."
                                            value={this.state.orderItems[index].product_id ? { value: this.state.orderItems[index].product_id, label: this.state.products.find(product => product.value === this.state.orderItems[index].product_id).label } : null}
                                            onChange={(selectedOption) => this.handleOrderItemsChange(selectedOption, 'product_id', index)}
                                            required
                                        />


                                        {this.state.selectedProductData[index] && (
                                            <div className='' style={{ marginTop: '85px' }}>
                                                <p>Коллекция: {this.state.selectedProductData[index].collection_name}</p>
                                                <p>Категория: {this.state.selectedProductData[index].category_name}</p>
                                                
                                                <p>Цена_коллекции: {this.state.selectedProductData[index].discount ? this.state.selectedProductData[index].price - this.state.selectedProductData[index].discount : this.state.selectedProductData[index].price} сум</p>
                                            </div>
                                        )}
                                        {this.state.selectedProductData[index] && (
                                            <div className='' style={{ marginTop: '75px' }}>
                                        
                                                <div className="mb-2" >
                                                    <input
                                                        type="radio"
                                                        id = {`radioGroup_1${index}`}
                                                        name={`radioGroup_${index}`}
                                                        checked={item.selectedOption === 'option1'}
                                                        onChange={(e) => this.handleRadioChange(e.target.checked, index, 'option1')}
                                                        required
                                                    />
                                                    <label className='ml-2' htmlFor={`radioGroup_1${index}`}>Штучный</label>
                                                </div>

                                                <div className="mb-2">
                                                    <input
                                                        type="radio"
                                                        id = {`radioGroup_2${index}`}
                                                        name={`radioGroup_${index}`}
                                                        checked={item.selectedOption === 'option2'}
                                                        onChange={(e) => this.handleRadioChange(e.target.checked, index, 'option2')}
                                                    />
                                                    <label className='ml-2' htmlFor={`radioGroup_2${index}`}>Метражный</label>
                                                </div>

                                                <div className="mb-2">
                                                    <input
                                                        type="radio"
                                                        id = {`radioGroup_3${index}`}
                                                        name={`radioGroup_${index}`}
                                                        checked={item.selectedOption === 'option3'}
                                                        onChange={(e) => this.handleRadioChange(e.target.checked, index, 'option3')}
                                                    />
                                                    <label className='ml-2' htmlFor={`radioGroup_3${index}`}>Joynamoz</label>
                                                </div>

                                            </div>
                                        )}


                                    </div>
                                    <div className='col-3'></div>
                                    <div className='col-4'>
                                        {this.state.selectedProductData[index] && (
                                            <img src={ this.state.selectedProductData[index].image } alt="Product" style={{ maxWidth: '500px', maxHeight: '400px', objectFit: 'cover' }} />
                                        )}
                                    </div>
                                    
                                </div>

                                <div className='row mt-5'>

                                    {item.showInputs && item.selectedOption === 'option1'  && (
                                        <>
                                            <div className="col-6">
                                                <Form.Label>Soni:</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="quantity"
                                                    placeholder="Kiriting..."
                                                    value={item.quantity}
                                                    onChange={(e) => this.handleOrderItemsChange(e.target.value, 'quantity', index)}
                                                    required
                                                />
                                            </div>

                                            <div className="col-6">
                                                <Form.Label>O'lchami:</Form.Label>
                                                <AsyncSelect
                                                    cacheOptions={10}
                                                    // defaultOptions={this.state.sizes.slice(0, 10)}
                                                    defaultOptions={this.state.sizes.filter((option) => {
                                                        return option.label.endsWith('1') || option.label.endsWith('0')
                                                    }).slice(0, 10)}
                                                    // loadOptions={this.loadSizeOptions}
                                                    loadOptions={(inputValue) => this.loadSizeOptions(inputValue, this.state.orderItems[index].selectedOption)}
                                                    placeholder="Tanlang..."
                                                    value={this.state.orderItems[index].size_id ? { value: this.state.orderItems[index].size_id, label: this.state.sizes.find(size => size.value === this.state.orderItems[index].size_id).label } : null}
                                                    onChange={(selectedOption) => this.handleOrderItemsChange(selectedOption, 'size_id', index)}
                                                    required
                                                />
                                            </div>
                                        </>
                                    )}
                                    {item.showInputs && item.selectedOption === 'option2'  && (
                                        <>
                                            <div className="col-6">
                                                <Form.Label>Uzunligi (sm):</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="quantity"
                                                    placeholder="Kiriting..."
                                                    value={item.quantity}
                                                    onChange={(e) => this.handleOrderItemsChange(e.target.value, 'quantity', index)}
                                                    required
                                                />
                                            </div>

                                            <div className="col-6">
                                                <Form.Label>O'lchami:</Form.Label>
                                                <AsyncSelect
                                                    cacheOptions={10}
                                                    // defaultOptions={this.state.sizes.slice(0, 10)}
                                                    defaultOptions={this.state.sizes.filter((option) => {
                                                        return option.label.endsWith('2')
                                                    }).slice(0, 10)}
                                                    // loadOptions={this.loadSizeOptions}
                                                    loadOptions={(inputValue) => this.loadSizeOptions(inputValue, this.state.orderItems[index].selectedOption)}
                                                    placeholder="Tanlang..."
                                                    value={this.state.orderItems[index].size_id ? { value: this.state.orderItems[index].size_id, label: this.state.sizes.find(size => size.value === this.state.orderItems[index].size_id).label } : null}
                                                    onChange={(selectedOption) => this.handleOrderItemsChange(selectedOption, 'size_id', index)}
                                                    required
                                                />
                                            </div>
                                        </>
                                    )}
                                    {item.selectedOption === 'option3' && item.showInputs && (
                                        <>
                                            
                                            <div className="col-3">
                                                <Form.Label>Yuzasi m.kv:</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="quantity"
                                                    placeholder="Kiriting..."
                                                    value={item.quantity}
                                                    onChange={(e) => this.handleOrderItemsChange(e.target.value, 'quantity', index)}
                                                    required
                                                />
                                                <div>
                                                    
                                                    <div className="mt-5">
                                                        <label className="file-upload">
                                                            <i className="fa-regular fa-image fa-2xl pr-2"></i>
                                                            Rasm yuklash
                                                            <input
                                                                type="file"
                                                                name="photo"
                                                                accept="image/*"
                                                                onChange={(e) => this.handleOrderItemsChange(e, 'photo', index)}
                                                                className="form-control-file"
                                                                required
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                           <div className='col-4'></div>
                                           <div className='col-4'>
                                                {item.photo && (
                                                    <div className='mt-5'>
                                                        <img
                                                            src={URL.createObjectURL(item.photo)}
                                                            alt="Selected Photo"
                                                            style={{ maxWidth: '300px', maxHeight: '300px', objectFit: 'cover' }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                                                    
                            </div>
                        ))}
                        
                        <div className='text-right ml-auto mr-3'>
                            <i onClick={this.handleAddItem} className="fa-solid fa-plus fa-2xl btn cursor-pointer mb-5 mt-5"></i>
                        </div>
                       
                        <br />

                        <br />
                        <Button type="submit">Tasdiqlash</Button>
                    </Form>
                </div>
            </>
        );
    }
}
