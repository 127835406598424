import React from "react";
import Pagination from "./Pagination";

class FilterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      nPages: 1,
      currentPage: 1,
      recordsPerPage: 20,
      allData: [],
    };
    this.currentRecords = [];
    this.array = [];
    this.obj = {};
  }
  async componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.createList();
      this.currentPage = 1;
      this.indexOfLastRecord = this.currentPage * this.state.recordsPerPage;
      this.indexOfFirstRecord =
        this.indexOfLastRecord - this.state.recordsPerPage;
      this.currentRecords = this.array;

      this.currentRecords = this.currentRecords.slice(
        this.indexOfFirstRecord,
        this.indexOfLastRecord
      );

      this.nPages = Math.ceil(this.array.length / this.state.recordsPerPage);

      this.setState({
        currentPage: this.currentPage,
        data: this.currentRecords,
        nPages: this.nPages,
        allData: this.array,
      });
    }
  }

  setCurrentPage = (number) => {
    this.setState({
      currentPage: number,
    });

    this.indexOfLastRecord = number * this.state.recordsPerPage;
    this.indexOfFirstRecord =
      this.indexOfLastRecord - this.state.recordsPerPage;
    this.currentRecords = this.state.allData.slice(
      this.indexOfFirstRecord,
      this.indexOfLastRecord
    );
    this.setState({
      data: this.currentRecords,
    });
  };

  createList = () => {
    if (this.props.data !== undefined) {
      if (Object.keys(this.props.data).length !== 0) {
        Object.keys(this.props.data).forEach((key) => {
          this.obj["name"] = key;
          this.obj["count"] = this.props.data[key];
          console.log(this.props.data, this.obj["count"]);
          this.array.push(this.obj);
          this.obj = {};
        });
      } else {
        this.array = [];
      }
    } else {
      this.array = [];
    }
    console.log(this.array);
  };

  render() {
    return (
      <div key={this.props.data}>
        <p>Filterlangan {this.props.name} </p>
        {this.state.data.length > 0 &&
          this.state.data.map((item) => (
            <p key={item}>
              {item.name} : {item.count}
            </p>
          ))}
        {this.state.data.length > 0 && this.state.nPages && (
          <Pagination
            nPages={this.state.nPages}
            currentPage={this.state.currentPage}
            setCurrentPage={this.setCurrentPage}
          />
        )}
      </div>
    );
  }
}
export default FilterComponent;
