import React from "react";
import axios from "axios";
import { BASE_URL, MAIN, HEADERS } from "../../common/api";

export default class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (event, item) => {
    event.preventDefault();
    let data = {
      titleUz: this.state.titleUz,
      titleRu: this.state.titleRu,
      descriptionUz: this.state.descriptionUz,
      descriptionRu: this.state.descriptionRu,
      metaUz: this.state.metaUz,
      metaRu: this.state.metaRu,
      id: this.state.id,
    };
    axios.put(BASE_URL + MAIN + item.id + "/", data);
  };

  componentDidMount() {
    axios
      .get(BASE_URL + MAIN, HEADERS)
      .then(
        (res) => {
          this.setState({ data: res.data });
        },
        () => {
          console.log(this.state.data);
        }
      )
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const { data } = this.state;
    return (
      <>
        {data.map((item) => (
          <div className="pt-2 m-2" key={item.id}>
            <h5>Title</h5>
            <div className="row">
              <div className="col-6">
                <form>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Uz</label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={item.titleUz}
                      name="titleUz"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={this.handleChange}
                    />
                  </div>
                </form>
              </div>
              <div className="col-6">
                <form>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Ru</label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={item.titleRu}
                      name="titleRu"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={this.handleChange}
                    />
                  </div>
                </form>
              </div>
            </div>
            <h5>Description</h5>
            <div className="row">
              <div className="col-6">
                <form>
                  <div className="form-group">
                    <label htmlFor="textInput">Uz</label>
                    <input
                      type="text"
                      className="form-control"
                      id="textInput"
                      defaultValue={item.descriptionUz}
                      name="descriptionUz"
                      aria-describedby="emailHelp"
                      onChange={this.handleChange}
                    />
                  </div>
                </form>
              </div>
              <div className="col-6">
                <form>
                  <div className="form-group">
                    <label htmlFor="textInput">Ru</label>
                    <input
                      type="text"
                      className="form-control"
                      id="textInput"
                      defaultValue={item.descriptionRu}
                      name="descriptionRu"
                      aria-describedby="emailHelp"
                      onChange={this.handleChange}
                    />
                  </div>
                </form>
              </div>
            </div>
            <h5>Meta</h5>
            <div className="row">
              <div className="col-6">
                <form>
                  <div className="form-group">
                    <label htmlFor="textInput">Uz</label>
                    <input
                      type="text"
                      className="form-control"
                      id="textInput"
                      aria-describedby="emailHelp"
                      defaultValue={item.metaUz}
                      name="metaUz"
                      onChange={this.handleChange}
                    />
                  </div>
                </form>
              </div>
              <div className="col-6">
                <form>
                  <div className="form-group">
                    <label htmlFor="textInput">Ru</label>
                    <input
                      type="text"
                      className="form-control"
                      id="textInput"
                      aria-describedby="emailHelp"
                      defaultValue={item.metaRu}
                      name="metaRu"
                      onChange={this.handleChange}
                    />
                  </div>
                </form>
              </div>
            </div>
            <button
              className="btn btn-primary mt-3"
              onClick={(event) => this.handleSubmit(event, item)}
            >
              Saqlash
            </button>
          </div>
        ))}
      </>
    );
  }
}
