import React, { Component } from "react";
import axios from "axios";
import Error from "../../assets/js/Error";
import { BASE_URL, ITEMS } from "../../common/api";
import { HEADERS } from "../../common/api";

export default class SingleFormat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      data: [],
      imagePreview: null,
    };
    this._isMounted = false;
  }

  handleChange = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };

  onImageChange = (event) => {
    const file = event.target.files[0];
    this.setState({
      data: {
        ...this.state.data,
        image: file,
      },
    });
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ imagePreview: reader.result });
    };
    reader.readAsDataURL(file);
  };

  handleDeleteImage = () => {
    this.setState({
      data: {
        ...this.state.data,
        image: null,
      },
      imagePreview: null,
    });
  };

  componentDidMount() {
    this._isMounted = true;
    const { id } = this.state;
    const obj = {};
    this._isMounted &&
      axios.get(BASE_URL + ITEMS + id, HEADERS).then((res) => {
        const data = res.data;
        Object.keys(data).forEach((item) => (obj[item] = data[item]));
        this.setState({ data: obj, status: res.status });
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { data, id } = this.state;
    const formData = new FormData();

    // Append the image to the formData only if it exists and has a name property
    if (data.image !== null && data.image.name) {
      formData.append("image", data.image, data.image.name);
    } else {
      // Delete the old image by setting the value to true
      formData.append("delete_image", true);
    }

    for (let key in data) {
      if (key !== "image" && data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }

    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    axios
      .put(BASE_URL + ITEMS + id + "/", formData)
      .then(this.props.history.goBack())
      .catch((err) => console.log(err.response));
  };
  render() {
    const { data, status, imagePreview } = this.state;
    console.log(data.image);
    console.log(imagePreview);
    return (
      <>
        {status === 200 ? (
          <>
            <div className="design-card">
              <div className="design-info">
                <div className="design-key">Nomlanishi:</div>
                <div className="design-value">{data.product_name}</div>
              </div>
              <div className="design-info">
                <div className="design-key">O'lcham:</div>
                <div className="design-value">
                  {data.size && `${data.size.width}x${data.size.length}`}
                </div>
              </div>
              <div className="design-info">
                <div className="design-key">Shakl:</div>
                <div className="design-value">
                  {data.size && data.size.shape}
                </div>
              </div>
            </div>
            <div className="design-card">
              <div className="photos-list row m-1">
                <div className="photos">
                  {data.image && (
                    <>
                      <img
                        src={imagePreview}
                        alt="ifoto"
                        className="img-fluid"
                        style={{ width: "200px", height: "200px" }}
                      />
                      <button
                        className="btn btn-danger"
                        onClick={() => this.handleDeleteImage()}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </button>
                    </>
                  )}
                </div>
                <div className="file-upload">
                  <label htmlFor="file-input">
                    <i class="fa-regular fa-image fa-2xl pr-2"></i>Rasm yuklash
                  </label>
                  <input
                    type="file"
                    className="form-control-file"
                    id="file-input"
                    name="image"
                    onChange={this.onImageChange}
                  />
                </div>
              </div>

              <div className="row p-1">
                <div className="col-6">
                  <h6>Narx</h6>
                  <input
                    className="form-control"
                    aria-label="Default select example"
                    defaultValue={data.price}
                    onChange={this.handleChange}
                    name="price"
                    type="number"
                  />
                </div>
                <div className="col-6">
                  <h6>Chegirma</h6>
                  <input
                    className="form-control"
                    aria-label="Default select example"
                    defaultValue={data.discount}
                    onChange={this.handleChange}
                    name="discount"
                    type="number"
                  />
                </div>
                <div className="col-6 pt-4">
                  <h6>Statusi</h6>
                  <select
                    className="form-control"
                    aria-label="Default select example"
                    onChange={this.handleChange}
                    value={data.isActive}
                    name="isActive"
                  >
                    <option value="true">Aktiv</option>
                    <option value="false">Passiv</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="design-card">
              <div className="row p-1">
                <div className="col-6">
                  <h6>Ishlab chiqarish</h6>
                  <select
                    className="form-control"
                    aria-label="Default select example"
                    value={data.isMakingAlways}
                    onChange={this.handleChange}
                    name="isMakingAlways"
                  >
                    <option value="true">Doimiy</option>
                    <option value="false">Vaqtinchalik</option>
                  </select>
                </div>
                <div className="col-6">
                  <h6>Ishlab chiqarish vaqti</h6>
                  <input
                    className="form-control"
                    aria-label="Default select example"
                    defaultValue={data.manufactureDay}
                    onChange={this.handleChange}
                    name="manufactureDay"
                    type="number"
                  />
                </div>
              </div>
            </div>
            <div className="filters buttons">
              <button
                className="btn btn-primary m-2"
                onClick={(event) => this.handleSubmit(event)}
              >
                Saqlash
              </button>
            </div>
          </>
        ) : (
          <Error status={status} />
        )}
      </>
    );
  }
}
