import React from "react";
import axios from "axios";
import { BASE_URL, BRANDS, CATEGORIES, HEADERS } from "../../common/api";
import Table from "../../assets/js/Table";
import { Modal, Form, Button } from "react-bootstrap";
import Error from "../../assets/js/Error";
import Cookies from "universal-cookie";

const columns = [
  { field: "image", header: "Rasmi" },
  { field: "nameUz", header: "O'zbekcha nomlanishi" },
  { field: "nameRu", header: "Ruscha nomlanishi" },
  { field: "brand_name", header: "Brendi" },
  { field: "isActive", header: "Statusi" },
];
const cookies = new Cookies();
export default class Category extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: true,
      show: false,
      confirm: false,
      categories: [],
      brands: [],
      nameUz: "",
      nameRu: "",
      image: null,
      brand: "",
      id: "",
      isActive: false,
      status: 0,
    };

    this._isMounted = false;
  }

  showModal = () => {
    this.setState({ show: true });
  };

  showConfirmationModal = (id) => {
    this.setState({ confirm: true, id: id });
  };

  hideModal = () => {
    this.setState({
      show: false,
      confirm: false,
    });
  };

  // Handle change in event
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  // Image handle
  onImageChange = (event) => {
    this.setState({ image: event.target.files[0] });
  };
  // TITLE change
  getTitle = () => {
    const { id } = this.state;
    if (id) {
      return <h3>O'zgartirish</h3>;
    } else {
      return <h3>Yangi qo'shish</h3>;
    }
  };

  // GET data
  componentDidMount() {
    this._isMounted = true;
    this._isMounted &&
      axios.get(BASE_URL + BRANDS, HEADERS).then((res) => {
        this._isMounted && this.setState({ brands: res.data });
      });
    this._isMounted &&
      axios
        .get(BASE_URL + CATEGORIES, HEADERS)
        .then((res) => {
          this._isMounted &&
            this.setState({ categories: res.data, status: res.status });
        })

        .catch((err) => {
          this._isMounted && this.setState({ status: err.response.status });
        });
    if (cookies.get("hodim") === "sotuvchi") {
      this.setState({ permission: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.show && !this.state.show) {
      this.setState({
        show: false,
        nameUz: "",
        nameRu: "",
        image: null,
        brand: "",
        id: "",
        isActive: true,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // GET data depending on id in order to edit it
  get = (row) => {
    this.showModal();
    this.setState({
      nameUz: row.nameUz,
      nameRu: row.nameRu,
      image: row.image,
      brand_name: row.brand_name,
      brand: row.brand,
      id: row.id,
      isActive: row.isActive,
    });
  };

  // DELETE data
  delete = (id, event) => {
    event.preventDefault();
    const { categories } = this.state;
    axios.delete(BASE_URL + CATEGORIES + id, HEADERS).then((res) => {
      const updatedData = categories.filter((category) => category.id !== id);
      this.setState({ categories: updatedData, confirm: false });
    });
  };

  // POST or UPDATE data
  // Function collects all inputs and depending on whether id is exist or not, decides put or post
  addOrUpdate = (event) => {
    event.preventDefault();
    const { image, id, categories, brand } = this.state;
    let formData = new FormData();
    if (image !== null && image.name) {
      formData.append("image", image, image.name);
    }
    formData.append("nameUz", this.state.nameUz);
    formData.append("nameRu", this.state.nameRu);
    formData.append("isActive", this.state.isActive);
    formData.append("brand", brand);

    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }
    if (id) {
      axios
        .put(BASE_URL + CATEGORIES + id + "/", formData, HEADERS)
        .then((res) => {
          const index = categories.findIndex((item) => item.id === id);
          categories[index] = res.data;
          this.setState({ categories: categories, show: false });
        })
        .catch((err) => console.log(err.response));
    } else {
      axios
        .post(BASE_URL + CATEGORIES, formData, HEADERS)
        .then((res) =>
          this.setState({
            categories: [...this.state.categories, res.data],
            show: false,
          })
        )
        .catch((error) => {
          console.log(error.response);
        });
    }
  };

  render() {
    const {
      categories,
      nameUz,
      nameRu,
      brand,
      brands,
      isActive,
      show,
      id,
      status,
      confirm,
      permission,
    } = this.state;
    console.log(brand);
    if (!categories) {
      return null;
    }
    return (
      <>
        {status === 200 ? (
          <>
            <Modal
              show={show}
              onHide={() => {
                this.hideModal();
              }}
              animation={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>{this.getTitle()}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={(event) => this.addOrUpdate(event)}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                    key={id}
                  >
                    <Form.Label>O'zbekcha nomlanishi:</Form.Label>
                    <Form.Control
                      type="text"
                      name="nameUz"
                      value={nameUz}
                      onChange={this.handleChange}
                    />
                    <Form.Label>Ruscha nomlanishi:</Form.Label>
                    <Form.Control
                      type="text"
                      name="nameRu"
                      value={nameRu}
                      onChange={this.handleChange}
                    />
                    <Form.Label>Rasm:</Form.Label>
                    <Form.Control
                      type="file"
                      name="image"
                      onChange={this.onImageChange}
                    />
                    <Form.Label>Brendi:</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleChange}
                      name="brand"
                      value={brand}
                    >
                      <option hidden>Выберите бренда</option>
                      {brands.map((brand) => (
                        <option value={brand.id} key={brand.id}>
                          {brand.name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Label>Statusi:</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleChange}
                      name="isActive"
                      value={isActive}
                    >
                      <option value="true">active</option>
                      <option value="false">NOT active</option>
                    </Form.Control>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={this.showModal}
                  >
                    Saqlash
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
            <Table
              addFunc={permission ? this.showModal : null}
              data={categories}
              columns={columns}
              title="Kategoriyalar"
              editFunc={permission ? (row) => this.get(row) : null}
              deleteFunc={
                permission
                  ? (event, id) => this.showConfirmationModal(event, id)
                  : null
              }
            />
            {/* Delete confirmation modal field */}
            <Modal
              show={confirm}
              onHide={() => {
                this.hideModal();
              }}
              animation={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>O'chirish</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>Haqiqatan ham ushbu ma'lumotni o'chirmoqchimisiz?</h5>
                <button
                  className="btn btn-danger m-1"
                  onClick={(event) => this.delete(id, event)}
                >
                  O'chirish
                </button>
                <button
                  className="btn btn-light outline-secondary"
                  onClick={() => {
                    this.hideModal();
                  }}
                >
                  Qaytish
                </button>
              </Modal.Body>
            </Modal>
          </>
        ) : (
          <Error status={status} />
        )}
      </>
    );
  }
}
