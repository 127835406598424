import React, { Component } from 'react'
import axios from 'axios';
import { EditButton, DeleteButton, AddButton } from '../../assets/js/Button';
import { Modal, Form, Button } from "react-bootstrap";
import { BASE_URL, DRIVERS, REGIONS, HEADERS } from '../../common/api';

export default class Driver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      confirm: false,
      status: 0,
      drivers: [],
      regions: [],
      first_name: "",
      last_name: "",
      region: "",
      isactive: false,
      phone: "",
      password: "",
      user: {}
    }
    this._isMounted = false;
  }

  showModal = () => {
    this.setState({ show: true });
  };

  showConfirmationModal = (id) => {
      this.setState({confirm: true, id: id})
  }

  hideModal = () => {
    this.setState({ show: false, confirm: false  });
  };

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value });
  };

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && axios.get(BASE_URL + DRIVERS, HEADERS).then(res => {
      this.setState({drivers: res.data, status: res.status})
        })
    this._isMounted && axios.get(BASE_URL + REGIONS, HEADERS).then(res => {
      this.setState({regions: res.data, status: res.status})
        })
  }
  
  getTitle = () => {
    const { id } = this.state;
    if (id) {
      return <h3>O'zgartirish</h3>;
    } else {
      return <h3>Yangi qo'shish</h3>;
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevState.show && !this.state.show) {
      this.setState({
      show: false,
      first_name: "",
      last_name: "",
      region: "",
      isactive: false,
      phone: "",
      })
    }
  }

  get = (item) => {
    console.log(item)
    this.setState({
      id: item.user.id,
      first_name: item.first_name,
      last_name: item.last_name,
      region: item.region,
      isactive: item.isactive,
      phone: item.phone,
      password: item.password,
      user: item.user,
      show: true,
    })
  }

  addOrUpdate = (event) => {
    event.preventDefault();
    const {id, drivers, first_name, last_name, phone, password, isactive, region, user} = this.state;
    let updatedData = {
      first_name: first_name,
      last_name: last_name,
      phone: phone,
      password: password,
      isactive: isactive,
      region: region,
    }

    let postedData = {
      user: {
        username: phone,
        phone: phone,
        password: password,
        is_driver: true,
      },
      first_name: first_name,
      last_name: last_name,
      status: isactive,
      region: region,
    }

    if(id) {
      axios.patch(BASE_URL + DRIVERS + id + '/', updatedData, HEADERS)
      .then(res => {
        const {drivers} = this.state;
        const index = drivers.findIndex((item)=> item.user.id === id);
        console.log(index);
        drivers[index] = res.data;
        console.log(res.data);
        this.setState({drivers: drivers, show: false})
      }).catch(error => console.log(error.response))
    }

    else {
      axios.post(BASE_URL + DRIVERS, postedData, HEADERS)
      .then(res => {
        this.setState({drivers: [...drivers, res.data], show: false})
      }).catch(error => console.log(error.response))
    }
  }

  delete = (id, event) => {
    event.preventDefault();
    console.log(id)
    const { drivers } = this.state;
    axios.delete(BASE_URL + DRIVERS + id, HEADERS).then((res) => {
      const updatedData = drivers.filter((driver) => driver.user.id !== id);
      this.setState({ drivers: updatedData, confirm: false });
    })
  };

  render() {
        const {show, confirm, id, drivers, status, first_name, last_name, phone, isactive, region, regions, password} = this.state;
        console.log(drivers);
    return (
      <>
        <Modal
              show={show}
              onHide={() => {
                this.hideModal();
              }}
              animation={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>{this.getTitle()}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={(event) => this.addOrUpdate(event)}>
                  <Form.Group
                    className="mb-3"
                    
                  >

                    <Form.Label>Ismi:</Form.Label>
                    <Form.Control
                      type="text"
                      name="first_name"
                      value={first_name}
                      onChange={this.handleChange}
                    />
                    <Form.Label>Familyasi:</Form.Label>
                    <Form.Control
                      type="text"
                      name="last_name"
                      value={last_name}
                      onChange={this.handleChange}
                    />
                    <Form.Label>Parol:</Form.Label>
                    {id ? (
                        <button>Parolni o'zgartirish</button>
                    ) : 
                    (
                        <Form.Control
                      type="password"
                      name="password"
                      id="password"
                      value={password}
                      onChange={this.handleChange}
                    />
                    )
                    }
                    <Form.Label>Telefon raqami:</Form.Label>
                    <Form.Control
                    type="text"
                    name="phone"
                    value={phone}
                    onChange={this.handleChange}
                    />
                    <Form.Label>Statusi:</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleChange}
                      name="isactive"
                      value={isactive}
                    >
                      <option value="true">active</option>
                      <option value="false">NOT active</option>
                    </Form.Control>
                    <Form.Label>Regioni:</Form.Label>
                    <Form.Control
                    as="select"
                    onChange={this.handleChange}
                    name="region"
                    value={region}
                    >
                      <option hidden>Tanlang:</option>
                            {regions.map((item) => (
                              <option value={item.id} key={item.id}>
                                {item.nameUz}
                              </option>
                            ))}
                    </Form.Control>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={this.showModal}
                  >
                    Saqlash
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
          <div>
            <AddButton onClick={this.showModal} />
          </div>
        <table className="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Telefon raqami</th>
                            <th>Ism-familyasi</th>
                            <th>Region</th>
                            <th>Statusi</th>
                            <th>Amallar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {drivers.map(driver =>
                            <tr key={driver.id}>
                                <td>{driver.user.id}</td>
                                <td>{driver.user.phone}</td>
                                <td>
                                    {driver.first_name + ' ' + driver.last_name}
                                </td>
                                <td>{driver.region_name}</td>
                                <td>
                                  <p className={driver.isactive ? "badge badge-light text-success" : "badge badge-light text-danger"}>{driver.isactive ? "Aktiv" : "Passiv"}</p>
                                  </td>
                              <td>
                                <EditButton onClick={() => this.get(driver)} />
                                <DeleteButton onClick={()=>this.showConfirmationModal(driver.id)} />
                              </td>

                            </tr>
                            )}
                        
                    </tbody>
                </table>

                <Modal
            show={confirm}
            onHide={() => {
                this.hideModal();
              }}
              animation={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>O'chirish</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>Haqiqatan ham ushbu ma'lumotni o'chirmoqchimisiz?</h5>
                <button className="btn btn-danger m-1" onClick={(event) => this.delete(id, event)} >O'chirish</button>
                <button className="btn btn-light outline-secondary" onClick={() => {
                this.hideModal();
              }} >Qaytish</button>
              </Modal.Body>
            </Modal>
      </>
    )
  }
}
