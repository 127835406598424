import React from "react";
import axios from "axios";
import "../../../assets/features.css";
import { BASE_URL, PARAMETERS, HEADERS } from "../../../common/api";
import { DeleteButton, EditButton, AddButton } from "../../../assets/js/Button";
import { Modal, Form, Button, Image } from "react-bootstrap";

class Parametr extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: [],
      show: false,
      confirm: false,
      nameUz: "",
      nameRu: "",
      image: null,
      id: "",
    };
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    this._isMounted &&
      axios.get(BASE_URL + PARAMETERS).then((res) => {
        this.setState({ parameters: res.data });
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.show && !this.state.show) {
      this.setState({
        show: false,
        nameUz: "",
        nameRu: "",
        image: null,
        id: "",
      });
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onImageChange = (event) => {
    this.setState({ image: event.target.files[0] });
  };

  showModal = () => {
    this.setState({ show: true });
  };

  showConfirmationModal = (event, id) => {
    this.setState({ confirm: true, id: id });
  };

  hideModal = () => {
    this.setState({ show: false, confirm: false });
  };

  get = (data) => {
    this.setState({
      nameUz: data.nameUz,
      nameRu: data.nameRu,
      image: data.image,
      id: data.id,
      show: true,
    });
  };

  delete = (id) => {
    const { parameters } = this.state;
    axios.delete(BASE_URL + PARAMETERS + id).then((res) => {
      const updatedData = parameters.filter((param) => param.id !== id);
      this.setState({ parameters: updatedData, confirm: false });
    });
  };

  addOrUpdate = (event) => {
    event.preventDefault();
    const { nameUz, nameRu, image, id } = this.state;

    let formData = new FormData();
    if (image !== null && image.name) {
      formData.append("image", image, image.name);
    }
    formData.append("nameUz", nameUz);
    formData.append("nameRu", nameRu);

    if (id) {
      axios
        .patch(BASE_URL + PARAMETERS + id + "/", formData, HEADERS)
        .then((res) => {
          const { parameters } = this.state;
          const index = parameters.findIndex((param) => param.id === id);
          parameters[index] = res.data;
          this.setState({ parameters: parameters, show: false });
        })
        .catch((err) => console.log(err.response));
    } else {
      axios.post(BASE_URL + PARAMETERS, formData, HEADERS).then((res) => {
        this.setState({
          parameters: [...this.state.parameters, res.data],
          show: false,
        });
      });
    }
  };

  getTitle = () => {
    const { id } = this.state;
    if (id) {
      return <h3>O'zgartirish</h3>;
    } else {
      return <h3>Yangi qo'shish</h3>;
    }
  };

  render() {
    const { show, confirm, nameUz, nameRu, id } = this.state;
    const { permission } = this.props;
    return (
      <>
        <Modal
          show={show}
          onHide={() => {
            this.hideModal();
          }}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.getTitle()}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={(event) => this.addOrUpdate(event)}>
              <Form.Group className="mb-3" key={id}>
                <Form.Label>O'zbekcha nomlanishi:</Form.Label>
                <Form.Control
                  type="text"
                  name="nameUz"
                  value={nameUz}
                  onChange={this.handleChange}
                />
                <Form.Label>Ruscha nomlanishi:</Form.Label>
                <Form.Control
                  type="text"
                  name="nameRu"
                  value={nameRu}
                  onChange={this.handleChange}
                />
                <Form.Label>Rasmi:</Form.Label>
                <Form.Control
                  type="file"
                  name="image"
                  onChange={this.onImageChange}
                />
              </Form.Group>
              <Button variant="primary" type="submit" onClick={this.showModal}>
                Saqlash
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
        <section id="tab-item-4">
          {permission && <AddButton onClick={() => this.showModal()} />}

          <table className="table">
            <thead className="thead-light">
              <tr>
                <th>Rasmi</th>
                <th>O'zbekcha nomlanishi</th>
                <th>Ruscha nomlanishi</th>
                <th>Amallar</th>
              </tr>
            </thead>
            <tbody>
              {this.state.parameters.map((param) => (
                <tr key={param.id}>
                  <td>
                    <Image
                      src={param.image}
                      alt={param.nameUz}
                      className="param-image"
                    />
                  </td>
                  <td>{param.nameUz}</td>
                  <td>{param.nameRu}</td>
                  <td>
                    {permission && (
                      <EditButton onClick={() => this.get(param)} />
                    )}
                    {permission && (
                      <DeleteButton
                        onClick={(event, id) =>
                          this.showConfirmationModal(event, param.id)
                        }
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
        <Modal
          show={confirm}
          onHide={() => {
            this.hideModal();
          }}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>O'chirish</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Haqiqatan ham ushbu ma'lumotni o'chirmoqchimisiz?</h5>
            <button
              className="btn btn-danger m-1"
              onClick={(event) => this.delete(id, event)}
            >
              O'chirish
            </button>
            <button
              className="btn btn-light outline-secondary"
              onClick={() => {
                this.hideModal();
              }}
            >
              Qaytish
            </button>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Parametr;
