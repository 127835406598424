import React from "react";
import axios from "axios";
import { BASE_URL, HEADERS } from "./api";
import { Editor } from "@tinymce/tinymce-react";

export default class TinyMCEEditor extends React.Component {
  handleImageUpload = (callback, value, meta) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.onchange = () => {
      const file = input.files[0];
      console.log(file);
      const formData = new FormData();
      formData.append("image", file, file.name);

      axios
        .post(BASE_URL + "images/", formData, HEADERS)
        .then((response) => {
          const imageUrl = response.data.image;
          console.log(response);
          callback(imageUrl, { alt: "", title: "" });
        })
        .catch((error) => {
          console.error("Image upload failed", error.response);
        });
    };

    input.click();
  };

  render() {
    const { changed, value } = this.props;
    return (
      <Editor
        onEditorChange={changed}
        value={value}
        apiKey="od6mm7x7lxarhb5xbser7ykyy542sm49sm7ye4cflqpxy8cz"
        init={{
          height: 500,
          menubar: true,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "image code preview | undo redo | blocks | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          file_picker_callback: this.handleImageUpload,
          // Configure other options as needed
        }}
      />
    );
  }
}
