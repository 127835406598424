import React, { useRef } from 'react';
import jsPDF from 'jspdf';
import logo from '../assets/static/image/sag_logo.png'


const currencyFormat = (num) => {
  return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};



  // export const generatePDF = (item) => {
  //   console.log(item);
  //   const baseHeight = 60; // Initial height for the fixed content (title, header, etc.)
  //   const itemHeight = 30; // Height for each item
  //   const footerHeight = 30; // Space for the footer information
  //   const totalHeight = baseHeight + parseInt((item.order_items.length) * itemHeight) + footerHeight;
  //   console.log(totalHeight);
  //   const doc = new jsPDF({
  //     orientation: 'portrait',
  //     unit: 'mm',
  //     format: [120, totalHeight], // Custom size: A4 paper (210mm x 297mm)
  //   });
  //   doc.setFontSize(20)

  //   doc.text('Buyurtma raqami: '+ item.id, 10, 10);
  //   doc.text('Xaridor:.' + item.name, 10, 20);
  //   doc.text("Telefon: " + item.phone, 10, 30);
  //   let baseY = 40  
  //   item.order_items.forEach((el, index) => {
      
  //     doc.text("Mahsulot: " + " ' "+  el.product + " ' " , 10, baseY);
  //     doc.text("O'lchami: "+ + el.quantity + ((el._type === '2' && el.image === null) ? " sm " : ((el._type === '0' || el._type === '1') ? " ta " : " m^2 ")) + el.width + " x " +((el._type === '2' || el._type === '5') ? el.quantity : el.length), 10,  baseY + 10);

  //     baseY += 30
  //   })
  //   doc.text("Summa: " + currencyFormat(item.price.toString()) + " (UZS)"  , 10, item.order_items.length * 30 + 40);
  //   doc.text("Oldindan to'lov: " + currencyFormat(item.paid_amount)  , 10, item.order_items.length * 30 + 50);
  //   doc.text("Buyurtma sanasi: " + item.created_at.split("T")[0]  , 10, item.order_items.length * 30 + 60);
  //   doc.text("Yetkazilish sanasi: " + ((item.expected_delivery_date)? item.expected_delivery_date.split("T")[0] : "__________")  , 10, item.order_items.length * 30 + 70);
  //   doc.text("Xaridorning imzosi: __________"  , 10, item.order_items.length * 30 + 80);


  //   // Create a blob from the PDF
  //   const pdfBlob = doc.output('blob');

  //   // Create a URL for the blob
  //   const pdfUrl = URL.createObjectURL(pdfBlob);

  //   // Open the PDF in a new tab and print
  //   const newWindow = window.open(pdfUrl);
  //   if (newWindow) {
  //     newWindow.addEventListener('load', () => {
  //       newWindow.print();
  //     });
  //   } else {
  //     // Fallback for browsers that block automatic new windows
  //     const a = document.createElement('a');
  //     a.href = pdfUrl;
  //     a.download = 'sample.pdf';
  //     a.click();
  //   }
  // };
  export const generatePDF = (item) => {
  
    const baseHeight = 60; // Initial height for the fixed content (title, header, etc.)
    const itemHeight = 30; // Height for each item
    const footerHeight = 100; // Space for the footer information
    const totalHeight = baseHeight + parseInt((item.order_items.length) * itemHeight) + footerHeight;
  
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: [120, totalHeight], // Custom size: A4 paper (210mm x 297mm)
    });
  
    // Load the image and add it to the PDF
    const img = new Image();
    img.src = logo;
    img.onload = () => {
      doc.addImage(img, 'PNG', 35, 10, 50, 30); // Adjust the position and size as needed
  
      doc.setFontSize(20);
      doc.text('Buyurtma raqami: ' + item.id, 10, 60); // Adjust the y-coordinate
      doc.text('Xaridor: ' + item.name, 10, 70);
      doc.text('Telefon: ' + item.phone, 10, 80);
      doc.text('Sotuvchi: ' + item.saler_firstname + " " + item.saler_lastname, 10, 90);
      let baseY = 100;
  
      item.order_items.forEach((el, index) => {
        doc.text("Mahsulot: " + " ' " + el.product + " ' ", 10, baseY);
        doc.text("O'lchami: " + el.quantity + ((el._type === '2' && el.image === null) ? " sm " : ((el._type === '0' || el._type === '1') ? " ta " : " m^2 ")) + el.width + " x " + ((el._type === '2' || el._type === '5') ? el.quantity : el.length), 10, baseY + 10);
  
        baseY += 30;
      });
  
      doc.text("Summa: " + currencyFormat(item.price.toString()) + " (UZS)", 10, item.order_items.length * 30 + 100);
      doc.text("Oldindan to'lov: " + currencyFormat(item.paid_amount), 10, item.order_items.length * 30 + 110);
      doc.text("Buyurtma sanasi: " + item.created_at.split("T")[0], 10, item.order_items.length * 30 + 120);
      doc.text("Yetkazilish sanasi: " + (item.expected_delivery_date ? item.expected_delivery_date.split("T")[0] : "__________"), 10, item.order_items.length * 30 + 130);
      doc.text("Xaridorning imzosi: __________", 10, item.order_items.length * 30 + 140);
  
      // Create a blob from the PDF
      const pdfBlob = doc.output('blob');
  
      // Create a URL for the blob
      const pdfUrl = URL.createObjectURL(pdfBlob);
  
      // Open the PDF in a new tab and print
      const newWindow = window.open(pdfUrl);
      if (newWindow) {
        newWindow.addEventListener('load', () => {
          newWindow.print();
        });
      } else {
        // Fallback for browsers that block automatic new windows
        const a = document.createElement('a');
        a.href = pdfUrl;
        a.download = 'sample.pdf';
        a.click();
      }
    };
  };

  export default class PrintableCheck extends React.Component {
    render() {
      const { item } = this.props;
      return (
        <div className='text text-center'>
          <h1>Order Check</h1>
          <p>Id: {item.id}</p>
          <p>Name: {item.name}</p>
          <p>Price: {item.price} so'm</p>
          <p>Phone: {item.phone}</p>
          <p>Warehouse: {item.warehouse_name}</p>
          <p>Created At: {new Date(item.created_at).toLocaleDateString("ru-RU")}</p>
          <p>Expected Delivery Date: {
            item.expected_delivery_date ?
            Math.floor((new Date(item.expected_delivery_date).getTime() - new Date().getTime()) / (1000 * 3600 * 24)) + 1 : ""
          }</p>
          <div>
            <h2>Order Items:</h2>
            <ol>
              {item.order_items.map((el, index) => (
                <li key={index}>
                  <strong>{el.product}</strong> - {el.quantity} {(el._type === '2' && el.image === null) ? "sm" : (el._type === '0' || el._type === '1') ? "ta" : "m^2"}
                  <span> {el.width}x{(el._type === '2' || el._type === '5') ? el.quantity : el.length}</span>
                </li>
              ))}
            </ol>
          </div>
          <div>
            <span className={`badge ${item.order_status !== 5 ? "badge-success" : "badge-danger"}`}>
              {item.status}
            </span>
          </div>
        </div>
      );
    }
  }
  