import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import AdminRoutes from "./AdminRoutes";
import Analytics from "./AnalyticsRoutes";
import CatalogRoutes from "./CatalogRoutes";
import ContentRoutes from "./ContentRoutes";
import SaleRoutes from "./SaleRoutes";
import LogisticsRoutes from "./LogisticsRoutes";
import MainPage from "../views/main";
import ErrorPage from "../views/errorPage";
import loginPage from "../views/loginPage";
import MagazinRoutes from "./MagazinRoutes";


const PrivateRouter = ({ component, ...options }) => {
  const token = sessionStorage.getItem("token");
  const finalComponent = token ? (
    <Route {...options} component={component} />
  ) : (
    <Redirect to="/login" />
  );

  return finalComponent;
};

class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Switch>
          {AdminRoutes.map((route, key) => {
            return (
              <PrivateRouter
                path={route.path}
                key={key}
                exact={route.exact}
                component={(props) => {
                  return <route.component {...props} />;
                }}
              />
            );
          })}
          {Analytics.map((route, key) => {
            return (
              <PrivateRouter
                path={route.path}
                key={key}
                exact={route.exact}
                component={(props) => {
                  return <route.component {...props} />;
                }}
              />
            );
          })}
          {CatalogRoutes.map((route, key) => {
            return (
              <PrivateRouter
                path={route.path}
                key={key}
                exact={route.exact}
                component={(props) => {
                  return <route.component {...props} />;
                }}
              />
            );
          })}

          {ContentRoutes.map((route, key) => {
            return (
              <PrivateRouter
                path={route.path}
                key={key}
                exact={route.exact}
                component={(props) => {
                  return <route.component {...props} />;
                }}
              />
            );
          })}

          {SaleRoutes.map((route, key) => {
            return (
              <PrivateRouter
                path={route.path}
                key={key}
                exact={route.exact}
                component={(props) => {
                  return <route.component {...props} />;
                }}
              />
            );
          })}

          {SaleRoutes.map((route, key) => {
            return (
              <PrivateRouter
                path={route.path}
                key={key}
                exact={route.exact}
                component={(props) => {
                  return <route.component {...props} />;
                }}
              />
            );
          })}
          {LogisticsRoutes.map((route, key) => {
            return (
              <PrivateRouter
                path={route.path}
                key={key}
                exact={route.exact}
                component={(props) => {
                  return <route.component {...props} />;
                }}
              />
            );
          })}
          {MagazinRoutes.map((route, key) => {
            return (
              <PrivateRouter
                path={route.path}
                key={key}
                exact={route.exact}
                component={(props) => {
                  return <route.component {...props} />;
                }}
              />
            );
          })}

          <Route exact path="/" component={MainPage} />
          <Route exact path="*" component={ErrorPage} />
          
        </Switch>
      </div>
    );
  }
}

export default Routes;
