import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { BASE_URL } from "../../common/api";

class addSurvey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      survey: {},
    };
  }
  componentDidMount() {
    const id = this.props.match.params.id;
    const current = new Date();
    if (id === "add") {
      const data = this.state.survey;
      data.questions = [];
      data.nameUz = "";
      data.nameRu = "";
      data.sanadan = `${current.getFullYear()}-${
        current.getMonth() + 1
      }-${current.getDate()}`;
      data.sanagacha = `${current.getFullYear()}-${current.getMonth() + 1}-${
        current.getDate() + 1
      }`;
      data.status = false;
      this.setState({
        survey: data,
      });
    } else {
      axios
        .get(BASE_URL + "surveys/" + id + "/")
        .then((res) => {
          const survey = res.data;

          this.setState({
            survey,
          });
        })
        .catch((err) => {
          console.log(err);
          console.log(err.message);
          console.log(err.response);
        });
    }
  }
  addQuesetion = () => {
    let survey = this.state.survey;
    survey.questions = [
      ...survey.questions,
      {
        answers: [],
        nameUz: "",
        nameRu: "",
        status: true,
        defaultUz: "",
        defaultRu: "",
        type_question: "checkbox",
      },
    ];

    this.setState({
      survey,
    });
  };

  addVersion = (index) => {
    let data = this.state.survey;
    data.questions[index].answers.push({ nameUz: "", nameRu: "" });

    this.setState({ survey: data });
  };

  deleteQuestion = (index) => {
    let data = this.state.survey;

    data.questions.splice(index, 1);
    this.setState({
      survey: data,
    });
  };

  deleteVariant = (index, count) => {
    let data = this.state.survey;
    data.questions[index].answers.splice(count, 1);
    this.setState({
      newQuestion: data,
    });
  };

  getData = (e, index) => {
    const data = this.state.survey;

    if (e.target.name === "status") {
      if (e.target.checked) {
        data.questions[index][e.target.name] = true;
      } else {
        data.questions[index][e.target.name] = false;
      }
    } else {
      data.questions[index][e.target.name] = e.target.value;
    }
    this.setState({
      survey: data,
    });
  };

  getVariant = (index, count, e) => {
    const data = this.state.survey;

    data.questions[index].answers[count][e.target.name] = e.target.value;

    this.setState({
      survey: data,
    });
  };

  getSurvey = (e) => {
    const data = this.state.survey;
    if (e.target.name === "status") {
      if (e.target.value === "true") {
        data[e.target.name] = true;
      } else {
        data[e.target.name] = false;
      }
    } else {
      data[e.target.name] = e.target.value;
    }
    this.setState(
      {
        survey: data,
      },
      () => console.log(this.state.survey)
    );
  };

  handleSubmit = () => {
    const id = this.props.match.params.id;
    console.log(this.state.survey.sanagacha);
    if (id !== "add") {
      console.log(this.state.survey);
      axios
        .put(
          BASE_URL + "surveys/" + id + "/",
          {
            answers: this.state.survey,
            nameUz: this.state.survey.nameUz,
            nameRu: this.state.survey.nameRu,
            sanadan: this.state.survey.sanadan,
            sanagacha: this.state.survey.sanagacha,
            status: this.state.survey.status,
          },
          {
            headers: {
              accept: "application/json",
            },
          }
        )
        .then((res) => console.log(res.data))
        .catch((err) => {
          console.log(err);
          console.log(err.message);
          console.log(err.response);
        });
    } else {
      console.log(this.state.survey);
      axios
        .post(
          BASE_URL + "answers/",
          {
            answers: this.state.survey,
          },
          {
            headers: {
              accept: "application/json",
            },
          }
        )
        .then((res) => console.log(res.data))
        .catch((err) => {
          console.log(err);
          console.log(err.message);
          console.log(err.response);
        });
    }
    this.props.history.push("/survey");
  };

  render() {
    return (
      <div>
        <div>
          <label>
            {" "}
            So'rovnomanig nomi Uz
            <input
              type="text"
              name="nameUz"
              value={this.state.survey.nameUz}
              onChange={(e) => this.getSurvey(e)}
            />
          </label>
          <label>
            So'rovnomaning nomi Ru
            <input
              type="text"
              name="nameRu"
              value={this.state.survey.nameRu}
              onChange={(e) => this.getSurvey(e)}
            />
          </label>
        </div>
        {this.state.survey.questions &&
          this.state.survey.questions.map((question, index) => (
            <div key={index}>
              <div>
                <select
                  name="type_question"
                  id="question_type"
                  value={question["type_question"]}
                  onChange={(e) => this.getData(e, index)}
                >
                  <option value="checkbox">Checkbox</option>
                  <option value="radio">Radio</option>
                  <option value="text">TEXT</option>
                </select>
                <button onClick={() => this.deleteQuestion(index)}>
                  Delete
                </button>
              </div>
              <div>
                <label>
                  {" "}
                  Savol Uz
                  <input
                    type="text"
                    name="nameUz"
                    value={question.nameUz}
                    onChange={(e) => this.getData(e, index)}
                  />
                </label>
                <label>
                  {" "}
                  Savol Ru
                  <input
                    type="text"
                    name="nameRu"
                    value={question.nameRu}
                    onChange={(e) => this.getData(e, index)}
                  />
                </label>
              </div>
              <div>
                <label>
                  {" "}
                  Default Uz
                  <input
                    type="text"
                    name="defaultUz"
                    value={question.defaultUz}
                    onChange={(e) => this.getData(e, index)}
                  />
                </label>
                <label>
                  {" "}
                  Default Ru
                  <input
                    type="text"
                    name="defaultRu"
                    value={question.defaultRu}
                    onChange={(e) => this.getData(e, index)}
                  />
                </label>
                <label>
                  {" "}
                  Aktivmi?
                  <input
                    type="checkbox"
                    name="status"
                    checked={question.status}
                    onChange={(e) => this.getData(e, index)}
                  />
                </label>
              </div>
              <div>
                {question.answers &&
                  question.answers.map((item, count) => (
                    <div key={count}>
                      <label>
                        Varinat #{count + 1}
                        <input
                          type="text"
                          name="nameUz"
                          value={item.nameUz}
                          onChange={(e) => this.getVariant(index, count, e)}
                        />
                        <input
                          type="text"
                          name="nameRu"
                          value={item.nameRu}
                          onChange={(e) => this.getVariant(index, count, e)}
                        />
                      </label>
                      <button onClick={(e) => this.deleteVariant(index, count)}>
                        Delete
                      </button>
                    </div>
                  ))}
              </div>
              <div>
                {question["type_question"] !== "text" && (
                  <button onClick={() => this.addVersion(index)}>+</button>
                )}
              </div>
            </div>
          ))}
        <button onClick={() => this.addQuesetion()}>
          + Yangi savol qo'shish
        </button>
        <div>
          <label>
            Sanadan
            <input
              type="date"
              name="sanadan"
              value={this.state.survey.sanadan}
              onChange={(e) => this.getSurvey(e)}
            />
          </label>
          <label>
            Sanagacha
            <input
              type="date"
              name="sanagacha"
              value={this.state.survey.sanagacha}
              onChange={(e) => this.getSurvey(e)}
            />
          </label>
          <select
            name="status"
            id="question_type"
            value={this.state.survey.status}
            onChange={(e) => this.getSurvey(e)}
          >
            <option value={false}>Passiv</option>
            <option value={true}>Aktiv</option>
          </select>
        </div>
        <button onClick={() => this.handleSubmit()}>Tasdiqlash</button>
      </div>
    );
  }
}
export default withRouter(addSurvey);
