import React from "react";
import axios from "axios";
import { BASE_URL, COLLECTIONS, HEADERS, PARAMETERS } from "../../common/api";
import TinyMCEEditor from "../../common/tiny";
import Select from "react-select";

export default class SingleCollection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      data: {},
      status: "",
      options: [],
      inParameters: [],
      imagePreview: [],
    };
    this.myRef = React.createRef();
  }

  log = () => {
    if (this.myRef.current) {
      console.log(this.myRef.current.getContent());
    }
  };

  componentDidMount() {
    const { id } = this.state;
    axios.get(BASE_URL + COLLECTIONS + id, HEADERS).then((res) => {
      const data = res.data;
      const arr = [];
      const obj = {};
      Object.keys(data).forEach((item) => (obj[item] = data[item]));
      data.parameters.map((el) => {
        return arr.push({ value: el.id, label: el.nameUz });
      });
      this.setState({
        data: obj,
        status: res.status,
        inParameters: arr,
        imagePreview: data.image,
      });
    });
    axios.get(BASE_URL + PARAMETERS).then((res) => {
      const data = res.data;
      const arr = [];
      data.map((el) => {
        return arr.push({ value: el.id, label: el.nameUz });
      });
      this.setState({ options: arr });
    });
  }

  onUzTinyChange = (content) => {
    this.setState({ data: { ...this.state.data, descriptionUz: content } });
    // this.setState({ textUz: content });
  };
  onRuTinyChange = (content) => {
    this.setState({ data: { ...this.state.data, descriptionRu: content } });
  };

  handleChange = (event) => {
    const data = {};
    data[event.target.name] = event.target.value;
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleSelect = (event, stateProp) => {
    const arr = [];
    event.map((item) => {
      arr.push({ value: item.value, label: item.label });
      return arr;
    });
    this.setState({ [stateProp]: arr });
  };

  onImageChange = (event) => {
    const file = event.target.files[0];
    this.setState({
      data: {
        ...this.state.data,
        image: file,
      },
    });
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ imagePreview: reader.result });
    };
  };

  deleteImage = () => {
    this.setState({
      data: {
        ...this.state.data,
        image: null,
      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { data, id, inParameters } = this.state;
    const selectedParams = [];
    const formData = new FormData();

    // Append the image to the formData only if it exists and has a name property
    if (data.image !== null && data.image.name) {
      formData.append("image", data.image, data.image.name);
    }
    if (data.image === null) {
      formData.append("image", "");
    }

    inParameters.map((param) => {
      formData.append("parameters", param.value);
    });

    for (let key in data) {
      if (key !== "image" && key !== "parameters" && data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }

    // for (let [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }
    axios
      .patch(BASE_URL + COLLECTIONS + "put/" + id + "/", formData, HEADERS)
      .then(this.props.history.goBack())
      .catch((err) => {
        console.log(err);
        console.log(err.message);
        console.log(err.response);
      });
  };

  render() {
    const { data, status, options, inParameters, imagePreview } = this.state;
    return (
      <>
        {status === 200 && (
          <div className="">
            <div className="tabs effect-3">
              <input
                type="radio"
                id="tab-1"
                name="tab-effect-3"
                defaultChecked="checked"
              />
              <span>Asosiy</span>
              <input type="radio" id="tab-2" name="tab-effect-3" />
              <span>Kontent</span>
              <div className="line ease"></div>
              <div className="tab-content">
                <section id="tab-item-1">
                  <div className="design-card">
                    <div className="design-info">
                      <div className="design-key">Kolleksiya:</div>
                      <div className="design-value">{data.nameUz}</div>
                    </div>
                    <div className="design-info">
                      <div className="design-key">Kategoriya:</div>
                      <div className="design-value">{data.category_nameUz}</div>
                    </div>
                  </div>
                  <div className="design-card">
                    <div className="row">
                      <div className="col-12">
                        <h6>Turi</h6>
                        <select
                          className="form-control"
                          aria-label="Default select example"
                          onChange={this.handleChange}
                          value={data.isMain}
                          name="isMain"
                        >
                          <option value="true">Top</option>
                          <option value="false">Oddiy</option>
                        </select>
                      </div>
                    </div>
                    <div className="row pt-4">
                      <div className="col-6 ">
                        <h6>Statusi</h6>
                        <select
                          className="form-control"
                          aria-label="Default select example"
                          onChange={this.handleChange}
                          value={data.isActive}
                          name="isActive"
                        >
                          <option value="true">active</option>
                          <option value="false">Passiv</option>
                        </select>
                      </div>
                      <div className="col-6">
                        <h6>isNew</h6>
                        <select
                          className="form-control"
                          aria-label="Default select example"
                          value={data.isNew}
                          onChange={this.handleChange}
                          name="isNew"
                        >
                          <option value="true">new</option>
                          <option value="false">NOTnew</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="design-card row">
                    <div className="col-6">
                      <h6>Narx</h6>
                      <input
                        className="form-control"
                        aria-label="Default select example"
                        defaultValue={data.price}
                        onChange={this.handleChange}
                        name="price"
                      />
                    </div>
                    <div className="col-6">
                      <h6>Chegirma</h6>
                      <input
                        className="form-control"
                        aria-label="Default select example"
                        defaultValue={data.discount}
                        onChange={this.handleChange}
                        name="discount"
                      />
                    </div>
                  </div>
                  <div className="filters buttons">
                    <button
                      className="btn btn-primary m-2"
                      onClick={(event) => this.handleSubmit(event)}
                    >
                      Barcha dizaynlarga biriktirish
                    </button>
                    <button
                      className="btn btn-primary m-2"
                      onClick={(event) => this.handleSubmit(event)}
                    >
                      Saqlash
                    </button>
                  </div>
                </section>
                <section id="tab-item-2">
                  <div className="filters">
                    <div className="row m-1">
                      <div>
                        {data.image && (
                          <img
                            src={imagePreview}
                            alt="ifoto"
                            className="img-fluid pr-2"
                            style={{ width: "200px", height: "200px" }}
                          />
                        )}
                      </div>
                      <div className="file-upload">
                        <label htmlFor="file-input">
                          <i className="fa-regular fa-image fa-2xl pr-2"></i>{" "}
                          Rasm yuklash
                        </label>
                        <input
                          type="file"
                          className="form-control-file"
                          id="file-input"
                          name="image"
                          onChange={this.onImageChange}
                        />
                      </div>
                      
                    </div>
                    <div>
                        <button className="btn btn-danger" onClick={()=> this.deleteImage()}> <i className="fa-solid fa-trash-can mr-2"></i>Rasmni o'chirish</button>
                      </div>
                    <div className="row">
                      <div className="col-12">
                        <form>
                          <div className="form-group">
                            <label htmlFor="textInput">Tasnif</label>
                            <TinyMCEEditor
                              changed={this.onUzTinyChange}
                              value={data.descriptionUz}
                              name="descriptionUz"
                            />
                          </div>
                        </form>
                      </div>
                      <div className="col-12">
                        <form>
                          <div className="form-group">
                            <label htmlFor="textInput">Описание</label>
                            <TinyMCEEditor
                              changed={this.onRuTinyChange}
                              value={data.descriptionRu}
                              name="descriptionRu"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <label htmlFor="parameters">Parametrlar</label>
                        <Select
                          closeMenuOnSelect={false}
                          defaultValue={inParameters}
                          isMulti
                          placeholder="Tanlang..."
                          onChange={(event) =>
                            this.handleSelect(event, "inParameters")
                          }
                          name="inParameters"
                          options={options}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="filters">
                    <div className="row">
                      <div className="col-6">
                        <form>
                          <div className="form-group">
                            <label htmlFor="densityRu">Плотность</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Введите"
                              defaultValue={data.densityRu}
                              name="densityRu"
                              id="densityRu"
                              onChange={this.handleChange}
                            />
                          </div>
                        </form>
                      </div>
                      <div className="col-6">
                        <form>
                          <div className="form-group">
                            <label htmlFor="densityUz">Zichlik</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Kiriting"
                              defaultValue={data.densityUz}
                              name="densityUz"
                              id="densityUz"
                              onChange={this.handleChange}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <form>
                          <div className="form-group">
                            <label htmlFor="basisRu">Основа</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Введите"
                              defaultValue={data.basisRu}
                              name="basisRu"
                              id="basisRu"
                              onChange={this.handleChange}
                            />
                          </div>
                        </form>
                      </div>
                      <div className="col-6">
                        <form>
                          <div className="form-group">
                            <label htmlFor="basisUz">Asosi</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={data.basisUz}
                              name="basisUz"
                              placeholder="Kiriting"
                              id="basisUz"
                              onChange={this.handleChange}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <form>
                          <div className="form-group">
                            <label htmlFor="pileHeightRu">Высота ворса</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Введите"
                              defaultValue={data.pileHeightRu}
                              name="pileHeightRu"
                              id="pileHeightRu"
                              onChange={this.handleChange}
                            />
                          </div>
                        </form>
                      </div>
                      <div className="col-6">
                        <form>
                          <div className="form-group">
                            <label htmlFor="pileHeightUz">Qalinligi</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Kiriting"
                              defaultValue={data.pileHeightUz}
                              name="pileHeightUz"
                              id="pileHeightUz"
                              onChange={this.handleChange}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-6">
                        <form>
                          <div className="form-group">
                            <label htmlFor="pileThreadRu">Нить ворса</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Введите"
                              defaultValue={data.pileThreadRu}
                              name="pileThreadRu"
                              id="pileThreadRu"
                              onChange={this.handleChange}
                            />
                          </div>
                        </form>
                      </div>
                      <div className="col-6">
                        <form>
                          <div className="form-group">
                            <label htmlFor="pileThreadUz">Ipning turi</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Kiriting"
                              defaultValue={data.pileThreadUz}
                              name="pileThreadUz"
                              id="pileThreadUz"
                              onChange={this.handleChange}
                            />
                          </div>
                        </form>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-6">
                        <form>
                          <div className="form-group">
                            <label htmlFor="threadCompositionRu">
                              Состав нити
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Введите"
                              defaultValue={data.threadCompositionRu}
                              name="threadCompositionRu"
                              id="threadCompositionRu"
                              onChange={this.handleChange}
                            />
                          </div>
                        </form>
                      </div>
                      <div className="col-6">
                        <form>
                          <div className="form-group">
                            <label htmlFor="threadCompositionUz">
                              Ipning tarkibi
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Kiriting"
                              defaultValue={data.threadCompositionUz}
                              name="threadCompositionUz"
                              id="threadCompositionUz"
                              onChange={this.handleChange}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <form>
                          <div className="form-group">
                            <label htmlFor="weightRu">Вес</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Введите"
                              defaultValue={data.weightRu}
                              name="weightRu"
                              id="weightRu"
                              onChange={this.handleChange}
                            />
                          </div>
                        </form>
                      </div>
                      <div className="col-6">
                        <form>
                          <div className="form-group">
                            <label htmlFor="weightUz">Og'irligi</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Kiriting"
                              defaultValue={data.weightUz}
                              name="weightUz"
                              id="weightUz"
                              onChange={this.handleChange}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-6">
                        <form>
                          <div className="form-group">
                            <label htmlFor="edgeProcessingRu">
                              Обработка края
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Введите"
                              defaultValue={data.edgeProcessingRu}
                              name="edgeProcessingRu"
                              id="edgeProcessingRu"
                              onChange={this.handleChange}
                            />
                          </div>
                        </form>
                      </div>
                      <div className="col-6">
                        <form>
                          <div className="form-group">
                            <label htmlFor="edgeProcessingUz">
                              Yoniga ishlov berish xususiyati
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Kiriting"
                              defaultValue={data.edgeProcessingUz}
                              name="edgeProcessingUz"
                              id="edgeProcessingUz"
                              onChange={this.handleChange}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <form>
                          <div className="form-group">
                            <label htmlFor="designRu">Дизайн</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Введите"
                              defaultValue={data.designRu}
                              name="designRu"
                              id="designRu"
                              onChange={this.handleChange}
                            />
                          </div>
                        </form>
                      </div>
                      <div className="col-6">
                        <form>
                          <div className="form-group">
                            <label htmlFor="designUz">Dizayn</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Kiriting"
                              defaultValue={data.designUz}
                              name="designUz"
                              id="designUz"
                              onChange={this.handleChange}
                            />
                          </div>
                        </form>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-6">
                        <form>
                          <div className="form-group">
                            <label htmlFor="serviceRu">Уход</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Введите"
                              defaultValue={data.serviceRu}
                              name="serviceRu"
                              id="serviceRu"
                              onChange={this.handleChange}
                            />
                          </div>
                        </form>
                      </div>
                      <div className="col-6">
                        <form>
                          <div className="form-group">
                            <label htmlFor="serviceUz">
                              Tozalash vositalari
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={data.serviceUz}
                              name="serviceUz"
                              placeholder="Kiriting"
                              id="serviceUz"
                              onChange={this.handleChange}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary"
                    onClick={(event) => this.handleSubmit(event)}
                  >
                    Saqlash
                  </button>
                </section>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
