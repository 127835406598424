import React from "react";
import axios from "axios";
import { BASE_URL, HEADERS } from "../common/api";
import image from "../assets/static/image/man_logo.png";

class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      surname: "",
    };
  }
  async componentDidMount() {
    axios
      .get(BASE_URL + "users/user/", HEADERS)
      .then((res) => {
        this.setState({
          name: res.data.first_name,
          surname: res.data.last_name,
        });
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        console.log(err.message);
      });
  }

  render() {
    const { name, surname } = this.state;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "70vh",
        }}
      >
        <div
          style={{
            width: "160px",
            height: "160px",
            backgroundColor: "#f4f4f4",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{ width: "120px", height: "120px" }}
            src={image}
            alt="Logo"
            border="0"
          />
        </div>
        <h3 className="pt-3">
          Salom, {name} {surname}!
        </h3>
        <p className="pt-3">
          Marhamat, xohlagan ishingizni qilishingiz mumkin!
        </p>
      </div>
    );
  }
}
export default MainPage;
