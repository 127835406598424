import React from "react";
import { withRouter } from "react-router-dom";

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);

    this.pageNumber = this.pageNumber.bind(this);
  }

  nextPage = () => {
    const { currentPage, nPages, setCurrentPage } = this.props;

    if (currentPage !== nPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  prevPage = () => {
    const { currentPage, setCurrentPage } = this.props;
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  pageNumber = (page) => {
    this.props.setCurrentPage(page);
  };

  render() {
    this.pageNumbers = [...Array(this.props.nPages + 1).keys()].slice(1);

    const { currentPage } = this.props;

    return (
      <nav>
        <ul className="pagination mt-3">
          <li className="text disabled">
            <div onClick={this.prevPage}>
              <button className="btn">
                <i className="fa-solid fa-angle-left fa-xs"></i>
              </button>
            </div>
          </li>
          {this.pageNumbers.map((pgNumber) => (
            <li
              onClick={() => this.pageNumber(pgNumber)}
              key={pgNumber}
              className={`text page-item ${
                currentPage == pgNumber ? "active" : ""
              } `}
            >
              <button className="btn">{pgNumber}</button>
            </li>
          ))}
          <li>
            <div onClick={this.nextPage}>
              <span className="text"></span>
              <button className="btn">
                <i className="fa-solid fa-angle-right fa-xs"></i>
              </button>
            </div>
          </li>
        </ul>
      </nav>
    );
  }
}

export default withRouter(Pagination);
