import React, { Component } from "react";
import axios from "axios";
import { BASE_URL, ROLES, GROUPS, HEADERS, REGIONS } from "../../common/api";
import { EditButton, DeleteButton, AddButton } from "../../assets/js/Button";
import { Modal, Form, Button } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { withRouter } from "react-router-dom";
import Error from "../../assets/js/Error";

const animatedComponents = makeAnimated();

export class Role extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      show: false,
      roles: [],
      title: "",
      inGroups: [],
      groups: [],
      selectedGroups: [],
      id: "",
      options: [],
      status: 0,
      regions: [],
      regionOptions: [],
      selectedRegions: [],
      inRegions: [],
    };

    this._isMounted = false;
  }

  showModal = () => {
    this.setState({ show: true });
  };

  showConfirmationModal = (id) => {
    this.setState({ confirm: true, id: id });
  };

  hideModal = () => {
    this.setState({ show: false, confirm: false });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSelect = (event, stateProp) => {
    const arr = [];
    event.map((item) => {
      arr.push(item.value);
      return arr;
    });

    this.setState({ [stateProp]: arr });
  };

  getTitle = () => {
    const { id } = this.state;
    if (id) {
      return <h3>O'zgartirish</h3>;
    } else {
      return <h3>Yangi qo'shish</h3>;
    }
  };

  componentDidMount() {
    this._isMounted = true;
    const arr = [];
    const regionsArr = [];
    this._isMounted &&
      axios
        .get(BASE_URL + ROLES + "list/", HEADERS)
        .then((res) => {
          this._isMounted &&
            this.setState({ roles: res.data, status: res.status });
        })
        .catch((err) => {
          this._isMounted && this.setState({ status: err.response.status });
        });
    this._isMounted &&
      axios.get(BASE_URL + GROUPS, HEADERS).then((res) => {
        let result = res.data;
        result.map((item) => {
          return arr.push({ value: item.id, label: item.name });
        });
        this._isMounted && this.setState({ groups: res.data, options: arr });
      });
    this._isMounted &&
      axios.get(BASE_URL + REGIONS, HEADERS).then((res) => {
        let data = res.data;
        data.map((item) => {
          return regionsArr.push({ value: item.id, label: item.nameUz });
        });
        this.setState({ regions: res.data, regionOptions: regionsArr });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.show && !this.state.show) {
      this.setState({
        id: "",
        show: false,
        title: "",
        inGroups: [],
        selectedGroups: [],
        inRegions: [],
        selectedRegions: [],
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  get = (role) => {
    const arr = [];
    const ids = [];
    const regionsArr = [];
    const regionIds = [];
    role.groups.map((item) => {
      return arr.push({ value: item.id, label: item.name });
    });
    role.groups.map((item) => {
      return ids.push(item.id);
    });
    role.regions.map((item) => {
      return regionsArr.push({ value: item.id, label: item.nameUz });
    });
    role.regions.map((item) => {
      return regionIds.push(item.id);
    });
    this.setState({
      id: role.id,
      title: role.title,
      inGroups: arr,
      inRegions: regionsArr,
      selectedGroups: ids,
      selectedRegions: regionIds,
      show: true,
    });
  };

  delete = (id, event) => {
    event.preventDefault();
    const { roles } = this.state;
    axios.delete(BASE_URL + ROLES + id, HEADERS).then((res) => {
      const updatedData = roles.filter((role) => role.id !== id);
      this.setState({ roles: updatedData, confirm: false });
    });
  };

  addOrUpdate = (event) => {
    event.preventDefault();
    const {
      roles,
      title,
      id,
      groups,
      regions,
      selectedGroups,
      selectedRegions,
    } = this.state;

    let data = {
      title: title,
      groups: selectedGroups,
      regions: selectedRegions,
    };
    if (id) {
      axios.put(BASE_URL + ROLES + id + "/", data, HEADERS).then((res) => {
        const { roles } = this.state;
        console.log(res.data);
        const index = roles.findIndex((item) => item.id === id);
        res.data.groups = groups.filter((group) =>
          selectedGroups.includes(group.id)
        );
        res.data.regions = regions.filter((region) =>
          selectedRegions.includes(region.id)
        );
        roles[index] = res.data;
        this.setState({ roles: roles, show: false });
      });
    } else {
      axios
        .post(BASE_URL + ROLES, data, HEADERS)
        .then((res) => {
          res.data.groups = groups.filter((group) =>
            selectedGroups.includes(group.id)
          );
          res.data.regions = regions.filter((region) =>
            selectedRegions.includes(region.id)
          );
          this.setState({ roles: [...roles, res.data], show: false });
        })
        .catch((error) => {
          this.setState({ error: error.response.status });
        });
    }
  };

  render() {
    const {
      roles,
      show,
      id,
      title,
      inGroups,
      options,
      status,
      confirm,
      regionOptions,
      inRegions,
    } = this.state;
    return (
      <>
        {status === 200 ? (
          <div>
            <Modal
              show={show}
              onHide={() => {
                this.hideModal();
              }}
              animation={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>{this.getTitle()}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={(event) => this.addOrUpdate(event)}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                    key={id}
                  >
                    <Form.Label>Nomlanishi:</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      value={title}
                      onChange={this.handleChange}
                    />
                    <Form.Label>Imkoniyatlari:</Form.Label>
                    <Select
                      closeMenuOnSelect={false}
                      defaultValue={inGroups}
                      components={animatedComponents}
                      placeholder="Tanlang..."
                      onChange={(event) =>
                        this.handleSelect(event, "selectedGroups")
                      }
                      name="selectedGroups"
                      isMulti
                      options={options}
                    />
                    <Form.Label>Regionlari:</Form.Label>
                    <Select
                      closeMenuOnSelect={false}
                      defaultValue={inRegions}
                      components={animatedComponents}
                      placeholder="Tanlang..."
                      onChange={(event) =>
                        this.handleSelect(event, "selectedRegions")
                      }
                      name="selectedRegions"
                      isMulti
                      options={regionOptions}
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={this.showModal}
                  >
                    Saqlash
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
            <div>
              <AddButton onClick={this.showModal} />
              <hr />
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nomlanishi</th>
                  <th>Imkoniyatlari</th>

                  <th>Amallar</th>
                </tr>
              </thead>
              <tbody>
                {roles.map((role) => (
                  <tr key={role.id}>
                    <td>{role.id}</td>
                    <td>{role.title}</td>
                    <td>
                      {role.groups.map((group) => (
                        <span key={group.id}>{group.name + "; "}</span>
                      ))}
                    </td>

                    <td>
                      <EditButton onClick={() => this.get(role)} />
                      <DeleteButton
                        onClick={() => this.showConfirmationModal(role.id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Delete confirmation modal field */}
            <Modal
              show={confirm}
              onHide={() => {
                this.hideModal();
              }}
              animation={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>O'chirish</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>Haqiqatan ham ushbu ma'lumotni o'chirmoqchimisiz?</h5>
                <button
                  className="btn btn-danger m-1"
                  onClick={(event) => this.delete(id, event)}
                >
                  O'chirish
                </button>
                <button
                  className="btn btn-light outline-secondary"
                  onClick={() => {
                    this.hideModal();
                  }}
                >
                  Qaytish
                </button>
              </Modal.Body>
            </Modal>
          </div>
        ) : (
          <Error status={status} />
        )}
      </>
    );
  }
}

export default withRouter(Role);
