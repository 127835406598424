import React from "react";
import axios from "axios";
import "../../../assets/features.css";
import { BASE_URL, PRICES, HEADERS } from "../../../common/api";
import { DeleteButton, EditButton, AddButton } from "../../../assets/js/Button";
import { Modal, Form, Button } from "react-bootstrap";

class Price extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prices: [],
      show: false,
      confirm: false,
      price_min: "",
      price_max: "",
      priority: "",
      isActive: false,
      id: "",
    };
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    this._isMounted &&
      axios.get(BASE_URL + PRICES).then((res) => {
        this.setState({ prices: res.data });
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.show && !this.state.show) {
      this.setState({
        show: false,
        price_min: "",
        price_max: "",
        priority: "",
        isActive: false,
        id: "",
      });
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  showModal = () => {
    this.setState({ show: true });
  };

  showConfirmationModal = (event, id) => {
    this.setState({ confirm: true, id: id });
  };

  hideModal = () => {
    this.setState({ show: false, confirm: false });
  };

  get = (data) => {
    this.setState({
      price_min: data.price_min,
      price_max: data.price_max,
      id: data.id,
      priority: data.priority,
      isActive: data.isActive,
      show: true,
    });
  };

  delete = (id) => {
    const { prices } = this.state;
    axios.delete(BASE_URL + PRICES + id).then((res) => {
      const updatedData = prices.filter((price) => price.id !== id);
      this.setState({ prices: updatedData, confirm: false });
    });
  };

  addOrUpdate = (event) => {
    event.preventDefault();
    const { price_min, price_max, priority, isActive, id } = this.state;
    let data = {
      price_min: price_min,
      price_max: price_max,
      priority: priority,
      isActive: isActive,
    };

    if (id) {
      axios.put(BASE_URL + PRICES + id + "/", data, HEADERS).then((res) => {
        const { prices } = this.state;
        const index = prices.findIndex((price) => price.id === id);
        prices[index] = res.data;
        this.setState({ prices: prices, show: false });
      });
    } else {
      axios.post(BASE_URL + PRICES, data, HEADERS).then((res) => {
        this.setState({
          prices: [...this.state.prices, res.data],
          show: false,
        });
      });
    }
  };

  getTitle = () => {
    const { id } = this.state;
    if (id) {
      return <h3>O'zgartirish</h3>;
    } else {
      return <h3>Yangi qo'shish</h3>;
    }
  };

  render() {
    const { show, confirm, price_min, price_max, priority, isActive, id } =
      this.state;
    const { permission } = this.props;
    return (
      <>
        <Modal
          show={show}
          onHide={() => {
            this.hideModal();
          }}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.getTitle()}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={(event) => this.addOrUpdate(event)}>
              <Form.Group className="mb-3" key={id}>
                <Form.Label>Dan:</Form.Label>
                <Form.Control
                  type="number"
                  name="price_min"
                  value={price_min}
                  onChange={this.handleChange}
                />
                <Form.Label>Gacha:</Form.Label>
                <Form.Control
                  type="number"
                  name="price_max"
                  value={price_max}
                  onChange={this.handleChange}
                />
                <Form.Label>Prioriteti:</Form.Label>
                <Form.Control
                  type="number"
                  name="priority"
                  value={priority}
                  onChange={this.handleChange}
                />
                <Form.Label>Statusi:</Form.Label>
                <Form.Control
                  as="select"
                  onChange={this.handleChange}
                  name="isActive"
                  value={isActive}
                >
                  <option value="true">active</option>
                  <option value="false">NOT active</option>
                </Form.Control>
              </Form.Group>
              <Button variant="primary" type="submit" onClick={this.showModal}>
                Saqlash
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
        <section id="tab-item-5">
          {permission && <AddButton onClick={() => this.showModal()} />}
          <table className="table table-hover">
            <thead className="thead-light">
              <tr>
                <th>ID</th>
                <th>Dan</th>
                <th>Gacha</th>
                <th>Prioriteti</th>
                <th>Statusi</th>
                <th>Amallar</th>
              </tr>
            </thead>
            <tbody>
              {this.state.prices.map((price) => (
                <tr key={price.id}>
                  <td>{price.id}</td>
                  <td>{price.price_min}</td>
                  <td>{price.price_max}</td>
                  <td>{price.priority}</td>
                  <td>
                    <p
                      className={
                        price.isActive
                          ? "badge badge-light text-success"
                          : "badge badge-light text-danger"
                      }
                      role="alert"
                    >
                      {price.isActive ? "Aktiv" : "Passiv"}
                    </p>
                  </td>
                  <td>
                    {permission && (
                      <EditButton onClick={() => this.get(price)} />
                    )}
                    {permission && (
                      <DeleteButton
                        onClick={(event, id) =>
                          this.showConfirmationModal(event, price.id)
                        }
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
        <Modal
          show={confirm}
          onHide={() => {
            this.hideModal();
          }}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>O'chirish</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Haqiqatan ham ushbu ma'lumotni o'chirmoqchimisiz?</h5>
            <button
              className="btn btn-danger m-1"
              onClick={(event) => this.delete(id, event)}
            >
              O'chirish
            </button>
            <button
              className="btn btn-light outline-secondary"
              onClick={() => {
                this.hideModal();
              }}
            >
              Qaytish
            </button>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Price;
