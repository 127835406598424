import Brand from "../views/catalog/Brand";
import Category from "../views/catalog/Category";
import Collection from "../views/catalog/Collection";
import Design from "../views/catalog/Design";
import Format from "../views/catalog/Format";
import Parameter from "../views/catalog/Parameter";
import SingleCollection from "../views/catalog/SingleCollection";
import SingleDesign from "../views/catalog/SingleDesign";
import SingleFormat from "../views/catalog/SingleFormat";
import Size from "../views/catalog/Size";
import Test from "../views/catalog/Test";

var routes = [
  {
    path: "/brands",
    name: "Brand",
    component: Brand,
  },
  {
    path: "/categories",
    name: "Category",
    component: Category,
  },
  {
    path: "/collections",
    name: "Collection",
    component: Collection,
  },
  {
    path: "/single-collection/:id",
    name: "SingleCollection",
    component: SingleCollection,
  },
  {
    path: "/designs",
    name: "Design",
    component: Design,
  },
  {
    path: "/single-design/:id",
    name: "SingleDesign",
    component: SingleDesign,
  },
  {
    path: "/formats",
    name: "Format",
    component: Format,
  },
  {
    path: "/single-format/:id",
    name: "SingleFormat",
    component: SingleFormat
  },
  {
    path: "/parameters",
    name: "Parameter",
    component: Parameter,
  },
  {
    path: "/sizes",
    name: "Size",
    component: Size,
  },
  {
    path: "/test",
    name: "Test",
    component: Test,
  },
];

export default routes;
