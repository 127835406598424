import React, { Component, Suspense } from "react";
import axios from 'axios';
import { BASE_URL, PRODUCTS, COLLECTIONS, CATEGORIES, BRANDS, HEADERS } from "../../common/api";
import Table from '../../assets/js/Table';
import { Modal, Form, Button } from "react-bootstrap";
import Pagination from "../../common/pagination";
import Select from 'react-select';
import AsyncSelect from "react-select/async";


const columns = [
  {field: 'image', header: 'Rasmi'},
  {field: 'vId', header: "Nomlanishi"},
  {field: 'collection_name', header: "Kolleksiya"},
  {field: 'price', header: "Narxi (so'm)"},
  {field: 'isActive', header: "Statusi"},
]


export class Design extends Component {
  constructor(props) {
    super(props);
    this.state = {
      designs: [],
      brands: [],
      categories: [],
      collections: [],
      currentPage: 1,
      totalPages: 1,
      filters: {},
      status: 0,
    }
    this._isMounted = false;
  }


  currencyFormat = (num) => {
    return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
  };

  loadOptions = (inputValue, dataType) => {
    return new Promise((resolve, reject) => {
      // using setTimeout to emulate a call to server
      setTimeout(() => {
        resolve(this.filterLoad(inputValue, dataType));
      }, 2000);
    });
  };

  filterLoad = async (inputValue, dataType) => {
    const data = this.state[dataType];
    return await data.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  handleFilter = (event, stateVariable, selectType=0) => {
    const updateFilters = () => {
      const { filters } = this.state;
      const queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
      this.props.history.push(`?${queryString}`);
      this.setState({queryString});
    }
    if (selectType === 1) {
    const selectedValues = [];
    const filteredValues = [];
    event.map((item) => {
      selectedValues.push({ value: item.value, label: item.label });
      filteredValues.push(item.value);
      return { selectedValues, filteredValues };
    });
    this.setState({
      [stateVariable]: selectedValues,
      filters: {
        ...this.state.filters,
        [stateVariable]: filteredValues.join(","),
      },
    }, updateFilters);
    }
    else if (selectType === 2) {
      const value = event ? event.value : '';
      this.setState({
      filters: {
        ...this.state.filters,
        [stateVariable]: value
      },
    }, updateFilters)
    } else {
      this.setState({
      filters: {
        ...this.state.filters,
        [stateVariable]: event.target.value,
      },
    }, updateFilters)
    }
  };

  getSelectedValues = (filterProp, stateProp) => {
    const { filters } = this.state;
    const selectedValue = filters[filterProp] && filters[filterProp];
    const arr = []

    selectedValue && arr.push(this.state[stateProp].find(col => col.value == selectedValue))
    return arr.length ? arr : [];
  }

  handleCancel = (stateVariable) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [stateVariable]: "",
      },
    });
  };

  handlePageChange(pageNumber) {
    this.setState({
      currentPage: pageNumber
    });
    this.getData(pageNumber)
  }

  getData = async (pageNumber=1) => {
      this._isMounted = true;
  console.log(this.state.filters);
    this._isMounted && await axios.get(BASE_URL + PRODUCTS + `?page=${pageNumber}`, { params: this.state.filters }, HEADERS)
    .then(res => {this.setState({designs: res.data.results, status: res.status, totalPages: Math.ceil(res.data.count/21)});
  console.log(res.data)});
  }

  async componentDidMount() {
    await axios.get(BASE_URL + BRANDS, HEADERS)
    .then(res => this.setState({brands: res.data}))
    await axios.get(BASE_URL + CATEGORIES, HEADERS)
    .then(res => {
      const arr = [];
      const data = res.data;
      data.map(el => {
        return arr.push({value: el.id, label: el.nameUz})
      })
      this.setState({categories: arr})
    })
    await axios.get(BASE_URL + 'collections-list', HEADERS)
    .then(res => {
      const arr = [];
      const data = res.data;
      data.map(el => {
        return arr.push({value: el.id, label: el.nameUz})
      })
      this.setState({collections: arr})
    })
    const searchParams = new URLSearchParams(window.location.search);
    const filters = {};
    for (let [key, value] of searchParams.entries()) {
      if (value === "true" || value === "false") {
        filters[key] = value === "true";
      } else if (isNaN(Number(value))) {
        filters[key] = value;
      } else {
        filters[key] = value;
      }
    }
    this.setState({ filters });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevState.filters !== this.state.filters) {
      this.getData();
    }
  }

  filter = (id) => {
    this.props.history.push('single-design/' + id)
  }


  render() {
    const {designs, filters, brands, categories, collections, status, currentPage, totalPages} = this.state;
    console.log(filters.isMain);
    return <>
        <Suspense fallback={<div>Loading...</div>}>
         <div className="filters">
              <div className="row">
              <div className="col-3">
                <Form.Label>Dizayn kodi:</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={filters.name || ''}
                  onChange={(event)=>this.handleFilter(event, "name")}
                />
              </div>
                <div className="col-3">
                <Form.Label>Brendlar:</Form.Label>
                <Form.Control
                  as="select"
                  name="brand"
                  className="foo"
                  onChange={(event)=>this.handleFilter(event, "brand")}
                  value={filters.brand}
                >
                  <option hidden>Brend tanlang:</option>
                {brands.map(brand => (
                  <option value={brand.id} key={brand.id} className="p-4">
                    {brand.name}
                  </option>
                ))}
                </Form.Control>
                {filters.brand && (
                  <button className="btn btn-secondary bar" onClick={()=>this.handleCancel("brand")}>x</button>
                )}
              </div>
              <div className="col-3">
                <Form.Label>Kategoriyalar:</Form.Label>
                <Select
                    options={categories}
                    isClearable={true}
                    placeholder="Select..."
                    onChange={(event) => this.handleFilter(event, "category", 2)}
                    value={this.getSelectedValues('category', 'categories')} />

              </div>
              <div className="col-3">
                <Form.Label>Kolleksiya:</Form.Label>
                <AsyncSelect
                    defaultOptions={collections.slice(0, 10)}
                    cacheOptions
                    loadOptions={(inputValue) =>
                      this.loadOptions(inputValue, "collections")
                    }
                    isClearable={true}
                    value={this.getSelectedValues('collection', 'collections') || []}
                    onChange={(event) => this.handleFilter(event, "collection", 2)}
                  />
              </div>
              </div>
              <div className="row pt-2">
                <div className="col-4">
                <Form.Label>Ishlab chiqarish:</Form.Label>               
                <Select
                    options={[{value: true, label: "Doimiy"}, {value: false, label: "Vaqtinchalik"}, {value: "all", label: "Barchasi"}]}
                    isClearable={true}
                    placeholder="Select..."
                    onChange={(event) => this.handleFilter(event, "isMain", 2)}
                    value={(filters.isMain === true && {value: filters.isMain, label: "Doimiy"}) || (filters.isMain === false && {value: filters.isMain, label: "Vaqtinchalik"}) || (filters.isMain === "all" && {value: filters.isMain, label: "Barchasi"})} />
                </div>
                  <div className="col-4">
                <Form.Label>Turi:</Form.Label>
                <Select
                    options={[{value: true, label: "Top"}, {value: false, label: "Oddiy"}, {value: "all", label: "Barchasi"}]}
                    isClearable={true}
                    placeholder="Select..."
                    onChange={(event) => this.handleFilter(event, "isTop", 2)}
                    value={(filters.isTop === true && {value: filters.isTop, label: "Top"}) || (filters.isTop === false && {value: filters.isTop, label: "Oddiy"}) || (filters.isTop === "all" && {value: filters.isTop, label: "Barchasi"})} />
                </div>
                  <div className="col-4">
                <Form.Label>Statusi</Form.Label>
                  <Select
                    options={[{value: true, label: "Aktiv"}, {value: false, label: "Passiv"}, {value: "all", label: "Barchasi"}]}
                    isClearable={true}
                    placeholder="Select..."
                    onChange={(event) => this.handleFilter(event, "status", 2)}
                    value={(filters.status === true && {value: filters.status, label: "Aktiv"}) || (filters.status === false && {value: filters.status, label: "Passiv"}) || (filters.status === "all" && {value: filters.status, label: "Barchasi"})} />
                </div>
              </div>
            </div>
    <Table
    title="Dizayn"
    data={designs}
    columns={columns}
    filterFunc={(id)=> this.filter(id)}
    />

    <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(num)=>this.handlePageChange(num)}
        />
        </Suspense>
    </>
  }
}

export default Design;
