import React from "react";

const Error = (props) => {
  if (props.status === 401) {
    return (
      <div className="alert alert-danger text-center" role="alert">
        <h1>401</h1>
        <h3>Unauthorized</h3>
      </div>
    );
  }
  if (props.status === 403) {
    return (
      <div className="alert alert-danger text-center" role="alert">
        <h1>403</h1>
        <h3>Sizda bu sahifaga kirish huquqi yo'q!</h3>
      </div>
    );
  } else if (props.status === 404) {
    return (
      <div className="alert alert-danger text-center" role="alert">
        <h1>404</h1>
        <h3>Sahifa topilmadi!</h3>
      </div>
    );
  } else if (props.status === 500) {
    return (
      <div className="alert alert-danger text-center" role="alert">
        <h1>500</h1>
        <h3>Serverda xatolik yuz berdi!</h3>
      </div>
    );
  } else {
    return (
      // loading spinner
      <div className="d-flex justify-content-center align-items-center">
        <div className="loader">
          <div className="loader-square"></div>
          <div className="loader-square"></div>
          <div className="loader-square"></div>
          <div className="loader-square"></div>
          <div className="loader-square"></div>
          <div className="loader-square"></div>
          <div className="loader-square"></div>
        </div>
      </div>
    );
  }
};

export default Error;
