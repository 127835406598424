import React, { Component } from "react";
import axios from "axios";
import {
  BASE_URL,
  BRANDS,
  CATEGORIES,
  COLLECTIONS,
  HEADERS,
} from "../../common/api";
import Table from "../../assets/js/Table";
import { Modal, Form, Button } from "react-bootstrap";
import Error from "../../assets/js/Error";
import Pagination from "../../common/pagination";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const columns = [
  { field: "id", header: "id" },
  { field: "nameUz", header: "Nomlanishi" },
  { field: "isActive", header: "Statusi" },
  { field: "vId", header: "VID" },
];

export default class Collection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: true,
      show: false,
      selected: false,
      collections: [],
      status: 0,
      nameUz: "",
      nameRu: "",
      brands: [],
      brand: "",
      id: "",
      isActive: true,
      image: null,
      categories: [],
      category: "",
      isNew: false,
      priority: 0,
      currentPage: 1,
      totalPages: 1,
      queryString: "",
      filters: {
        brand: "",
        category: "",
        status: "",
        nameUz: "",
        page: 1,
      },
    };

    this.data = {};
    this._isMounted = false;
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({
      show: false,
    });
  };

  getQueryString = (pageNumber) => {
    this.queryString = require("query-string");
    this.parsed = this.queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    this.setState({
      filters: this.parsed,
      currentPage: this.parsed["page"] ? parseInt(this.parsed["page"]) : 1,
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  updateFilters = () => {
    const { filters } = this.state;
    const queryString = Object.keys(filters)
      .map((key) => key + "=" + filters[key])
      .join("&");
    this.props.history.push(`?${queryString}`);
  };

  handleFilter = (event, stateVariable) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [stateVariable]: event.target.value,
          page: 1,
        },
      },
      this.updateFilters
    );
  };

  handleCancel = (stateVariable) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [stateVariable]: "",
          page: 1,
        },
      },
      this.updateFilters
    );
  };

  // Image handle
  onImageChange = (event) => {
    this.setState({ logo: event.target.files[0] });
  };

  get = (row) => {
    this.showModal();
    this.setState({
      id: row.id,
      nameUz: row.nameUz,
      nameRu: row.nameRu,
      brand: row.brand,
      category: row.category,
      isActive: row.isActive,
      isNew: row.isNew,
      priority: row.priority,
      seoTitleUz: row.seoTitleUz,
      seoTitleRu: row.seoTitleRu,
      seoDescriptionUz: row.seoDescriptionUz,
      seoDescriptionRu: row.seoDescriptionRu,
      image: row.image,
    });
  };
  // TITLE change
  getTitle = () => {
    const { id } = this.state;
    if (id) {
      return <h3>O'zgartirish</h3>;
    } else {
      return <h3>Yangi qo'shish</h3>;
    }
  };
  handlePageChange = async (pageNumber) => {
    await this.setState(
      {
        currentPage: pageNumber,
        filters: {
          ...this.state.filters,
          page: pageNumber,
        },
      },
      this.updateFilters
    );

    this.getData(pageNumber);
  };

  getData = async (pageNumber = 1) => {
    this._isMounted = true;
    await this.getQueryString(pageNumber);
    await axios
      .get(BASE_URL + COLLECTIONS, { params: this.state.filters }, HEADERS)
      .then((res) => {
        if (this._isMounted) {
          this.setState({
            collections: res.data.results,
            status: res.status,
            totalPages: Math.ceil(res.data.count / 21),
          });
        }
      })
      .catch((err) => {
        this.setState({ status: err.response.status });
      });
    axios
      .get(BASE_URL + BRANDS, HEADERS)
      .then((res) => {
        if (this._isMounted) {
          this.setState({ brands: res.data, status: res.status });
        }
      })
      .catch((err) => {
        this.setState({ status: err.response.status });
      });
    axios
      .get(BASE_URL + CATEGORIES, HEADERS)
      .then((res) => {
        if (this._isMounted) {
          const arr = [];
          const data = res.data;
          data.map((el) => {
            if (el.nameUz && el.isActive) {
              arr.push({ id: el.id, nameUz: el.nameUz });
            }
            return el.id;
          });
          this.setState({ categories: arr, status: res.status });
        }
      })
      .catch((err) => {
        this.setState({ status: err.response.status });
      });
    if (cookies.get("hodim").toLowerCase() === "sotuvchi") {
      this.setState({ permission: false });
    }
  };

  async componentDidMount() {
    this.getData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.search !== prevProps.location.search) {
      this.getData();
    }
  }

  filter = (id) => {
    this.props.history.push("single-collection/" + id);
  };

  addOrUpdate = (event) => {
    event.preventDefault();
    const {
      id,
      nameUz,
      nameRu,
      brand,
      category,
      isActive,
      isNew,
      priority,
      seoTitleUz,
      seoTitleRu,
      seoDescriptionUz,
      seoDescriptionRu,
      image,
    } = this.state;
    const formData = new FormData();
    if (image !== null && image.name) {
      formData.append("image", image, image.name);
    }
    formData.append("nameUz", nameUz);
    formData.append("nameRu", nameRu);
    formData.append("brand", brand);
    formData.append("category", category);
    formData.append("isActive", isActive);
    formData.append("isNew", isNew);
    formData.append("priority", priority);
    formData.append("seoTitleUz", seoTitleUz);
    formData.append("seoTitleRu", seoTitleRu);
    formData.append("seoDescriptionUz", seoDescriptionUz);
    formData.append("seoDescriptionRu", seoDescriptionRu);
    // if id is not null then update and update the state
    if (id) {
      axios
        .patch(BASE_URL + COLLECTIONS + id + "/", formData, HEADERS)
        .then((res) => {
          const { collections } = this.state;
          const index = collections.findIndex((item) => item.id === id);
          collections[index] = res.data;
          this.setState({ collections: collections, show: false });
        })
        .catch((err) => console.log(err.response));
    }
  };

  render() {
    const {
      permission,
      brands,
      collections,
      status,
      nameUz,
      nameRu,
      categories,
      id,
      category,
      isActive,
      filters,
      currentPage,
      totalPages,
      priority,
    } = this.state;
    return (
      <>
        {status === 200 ? (
          <>
            <Modal
              show={this.state.show}
              onHide={() => this.hideModal()}
              animation={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>{this.getTitle()}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={(event) => this.addOrUpdate(event)}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                    key={id}
                  >
                    <Form.Label>Nomlanishi:</Form.Label>
                    <Form.Control
                      type="text"
                      name="nameUz"
                      value={nameUz}
                      onChange={this.handleChange}
                    />
                    <Form.Label>Наименование</Form.Label>
                    <Form.Control
                      type="text"
                      name="nameRu"
                      value={nameRu}
                      onChange={this.handleChange}
                    />

                    <Form.Label>Kategoriyasi:</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleChange}
                      name="category"
                      value={category}
                    >
                      <option hidden>Выберите категорию</option>
                      {categories.map((cat) => (
                        <option value={cat.id} key={cat.id}>
                          {cat.nameUz}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Label>Statusi:</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleChange}
                      name="isActive"
                      value={isActive}
                    >
                      <option value="true">active</option>
                      <option value="false">NOT active</option>
                    </Form.Control>
                    <Form.Label>New:</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleChange}
                      name="isNew"
                      value={isActive}
                    >
                      <option value="true">new</option>
                      <option value="false">NOTnew</option>
                    </Form.Control>
                    <Form.Label>Priority:</Form.Label>
                    <Form.Control
                      as="input"
                      type="number"
                      onChange={this.handleChange}
                      name="priority"
                      value={priority}
                    ></Form.Control>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={this.showModal}
                  >
                    Saqlash
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
            <div className="filters row">
              <div className="col-3">
                <Form.Label>Brendlar:</Form.Label>
                <Form.Control
                  as="select"
                  name="brand"
                  className="foo"
                  onChange={(event) => this.handleFilter(event, "brand")}
                  value={filters.brand}
                >
                  <option hidden>Brend tanlang:</option>
                  {brands.map((brand) => (
                    <option value={brand.id} key={brand.id} className="p-4">
                      {brand.name}
                    </option>
                  ))}
                </Form.Control>
                {filters.brand && (
                  <button
                    className="btn btn-secondary bar"
                    onClick={() => this.handleCancel("brand")}
                  >
                    x
                  </button>
                )}
              </div>
              <div className="col-3">
                <Form.Label>Kategoriyalar:</Form.Label>
                <Form.Control
                  as="select"
                  name="category"
                  className="foo"
                  onChange={(event) => this.handleFilter(event, "category")}
                  value={filters.category}
                >
                  <option hidden>Kategoriya tanlang:</option>
                  {categories.map((cat) => (
                    <option value={cat.id} key={cat.id}>
                      {cat.nameUz}
                    </option>
                  ))}
                </Form.Control>
                {filters.category && (
                  <button
                    className="btn btn-secondary bar"
                    onClick={() => this.handleCancel("category")}
                  >
                    x
                  </button>
                )}
              </div>
              <div className="col-3">
                <Form.Label>Statusi:</Form.Label>
                <Form.Control
                  as="select"
                  className="foo"
                  onChange={(event) => this.handleFilter(event, "status")}
                  value={filters.status}
                  name="status"
                >
                  <option hidden>Выберите статус</option>
                  <option value="true">active</option>
                  <option value="false">NOT active</option>
                  <option value="all">Barchasi</option>
                </Form.Control>
                {filters.status && (
                  <button
                    className="btn btn-secondary bar"
                    onClick={() => this.handleCancel("status")}
                  >
                    x
                  </button>
                )}
              </div>
              <div className="col-3">
                <Form.Label>Nomi:</Form.Label>
                <Form.Control
                  type="text"
                  name="nameUz"
                  value={filters.nameUz || ""}
                  onChange={(event) => this.handleFilter(event, "nameUz")}
                />
              </div>
            </div>
            <Table
              data={collections}
              columns={columns}
              title="Kolleksiyalar"
              editFunc={permission ? (row) => this.get(row) : null}
              filterFunc={permission ? (id) => this.filter(id) : null}
            />
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(num) => this.handlePageChange(num)}
            />
          </>
        ) : (
          <Error status={status} />
        )}
      </>
    );
  }
}
