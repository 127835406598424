import React from 'react';
import axios from 'axios';


export default class Pagination extends React.Component {
render() {
    const { currentPage, totalPages, onPageChange } = this.props;
    const pageNumbers = [];

    // Calculate the start and end pages to display
    let startPage, endPage;
    if (totalPages <= 10) {
      // If there are less than 10 pages, show all of them
      startPage = 1;
      endPage = totalPages;
    } else {
      // If there are more than 10 pages, show the first 3, last 3, and some in between
      if (currentPage <= 4) {
        startPage = 1;
        endPage = 6;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 5;
        endPage = totalPages;
      } else {
        startPage = currentPage - 3;
        endPage = currentPage + 2;
      }
    }


    // Generate the page numbers to display
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <ul className="pagination">
        {startPage > 1 && (
          <li className="page-item">
            <a href="#!" className="page-link" onClick={() => onPageChange(1)}>
              &#171;
            </a>
          </li>
        )}
        {startPage > 2 && (
          <li className="page-item disabled">
            <a href="#!" className="page-link">
              ...
            </a>
          </li>
        )}
        {pageNumbers.map(number => (
          <li
            key={number}
            className={`page-item ${currentPage === number ? 'active' : null}`}
          >
            <a href="#!" className="page-link" onClick={() => onPageChange(number)}>
              {number}
            </a>
          </li>
        ))}
        {endPage < totalPages - 1 && (
          <li className="page-item disabled">
            <a href="#!" className="page-link">
              ...
            </a>
          </li>
        )}
        {endPage <= totalPages && (
          <li className="page-item">
            <a href="#!" className="page-link" onClick={() => onPageChange(totalPages)}>
              &#187;
            </a>
          </li>
        )}
      </ul>
    );
  }
}