import React, { Component } from "react";
import axios from "axios";
import { BASE_URL, CLIENTS, HEADERS } from "../../common/api";
import Table from "../../assets/js/Table";

const columns = [
  { field: "name", header: "Ismi" },
  { field: "phone", header: "Telefon raqami" },
  { field: "region__nameUz", header: "Hudud" },
];

export class Client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    axios
      .get(BASE_URL + CLIENTS, HEADERS)
      .then((res) => this.setState({ data: res.data }));
  }
  render() {
    const { data } = this.state;
    return (
      <>
        <Table
          data={data}
          columns={columns}
          title="Xaridorlar"
          // editFunc={(row) => this.get(row)}
          // deleteFunc={(event, id) => this.showConfirmationModal(event, id)}
          // filterFunc={(event, id) => this.filter(event, id)}
        />
      </>
    );
  }
}

export default Client;
