// const BASE_URL = "http://localhost:8000/api/v1/"; 
// const BASE_URL = "https://www.sagex.uz/api/v1/";
// const BASE_URL = "https://65.108.49.195:8000/api/v1/";
// const BASE_URL = "http://176.96.243.117:8000/api/v1/";
// const BASE_URL = "http://185.74.5.116:8888/api/v1/";
const BASE_URL = "https://www.sagexpress.uz/api/v1/";

const STYLES = "styles/";
const ROOMS = "rooms/";
const COLORS = "colors/";
const PARAMETERS = "parameters/";
const PRICES = "price/";
const BRANDS = "brands/";
const SHOPS = "shops/";
const CATEGORIES = "categories/";
const COLLECTIONS = "cols/";
const SIZES = "sizes/";
const REGIONS = "regions/";
const DISTRICTS = "districts/";
const ADMIN = "users/";
const REGISTER = "register/";
const LOGIN = "login/";
const LOGOUT = "logout/";
const ROLES = "roles/";
const GROUPS = "groups/";
const TOKEN = sessionStorage.getItem("token");
const HEADERS = {
  headers: {
    Authorization: `token ${TOKEN}`,
  },
};
const CLIENTS = "clients/";

const BANNERS = "banners/";
const OPPORTUNITIES = "opportunities/";
const POSTS = "posts/";
const SOCIALS = "socialmedia/";
const CONTACTS = "contacts/";
const ORDERINFO = "order-info/";
// const ORDERINFO = 'order-items/'
const ORDERS = "orders/";
const FORMATS = "units/";
const ITEMS = "items/";
const UNITSALL = "units_all/";
const PRODUCTS = "products/";
const DELIVERY = "delivery_met/";
const DAYS = "deliverydate/";
const MAIN = "main/";
const WAREHOUSE = "warehouses/";
const LOGS = "logs/";
const DRIVERS = "drivers/";
const SERVICES = "service/";
const PAYMENT = "payment/";
const CURRENTUSER = "users/user";

export {
  BASE_URL,
  BRANDS,
  CATEGORIES,
  REGIONS,
  COLLECTIONS,
  HEADERS,
  ROLES,
  GROUPS,
  STYLES,
  ROOMS,
  COLORS,
  PARAMETERS,
  PRICES,
  ADMIN,
  SIZES,
  REGISTER,
  BANNERS,
  OPPORTUNITIES,
  POSTS,
  SOCIALS,
  LOGIN,
  LOGOUT,
  CONTACTS,
  ORDERS,
  ORDERINFO,
  DISTRICTS,
  SHOPS,
  ITEMS,
  FORMATS,
  PRODUCTS,
  DELIVERY,
  WAREHOUSE,
  DAYS,
  MAIN,
  LOGS,
  CLIENTS,
  DRIVERS,
  SERVICES,
  PAYMENT,
  CURRENTUSER,
  UNITSALL,
};
