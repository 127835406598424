import React from "react";
import axios from "axios";
import "../assets/header.css";
import { BASE_URL, ORDERS } from "./api";
import { withRouter } from "react-router-dom";
import OpenClose from "../assets/js/OpenClose";


class AppHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openOrders: 0,
      receivedOrders: 0,
      services: 0,
    };
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this._isMounted &&
      axios
        .get(BASE_URL + ORDERS + "?order_status=0")
        .then((res) => this.setState({ openOrders: res.data.count }));
    this._isMounted &&
      axios
        .get(BASE_URL + ORDERS + "?order_status=1")
        .then((res) => this.setState({ receivedOrders: res.data.count }));
    this._isMounted &&
      axios
        .get(BASE_URL + "service/")
        .then((res) => this.setState({ services: res.data.count }));
  }

  logout = () => {
    sessionStorage.removeItem("token");
    window.location.href = "/";
  };
  render() {
    const { openOrders, receivedOrders, services } = this.state;

    return (
        <>
        <div className="main-header">
          <OpenClose />
          <div className="header">
        <div className="open-orders">
          <i
            className="fa-solid fa-cart-shopping"
            style={{ color: "#304156" }}
            title="Ochiq buyurtmalar"
          >
            <span className="count-badge">{openOrders}</span>
          </i>
        </div>
        <div className="received">
          <i
            className="fa-solid fa-square-check"
            style={{ color: "#304156" }}
            title="Qabul qilingan buyurtmalar"
          >
            <span className="count-badge">{receivedOrders}</span>
          </i>
        </div>
        <div className="services">
          <i
            className="fa-solid fa-ruler"
            style={{ color: "#304156" }}
            title="Xizmatlar"
          >
            <span className="count-badge">{services}</span>
          </i>
        </div>
        <div className="logout" onClick={() => this.logout()}>
          <i
            className="fa-solid fa-right-from-bracket"
            style={{ color: "#304156" }}
            title="Logout"
          ></i>
        </div>
      </div>
        </div>
        </>
    );
  }
}
export default withRouter(AppHeader);
