import React from "react";


class ErrorPage extends React.Component {
    render() {
        return (
    <div className="alert alert-danger text-center" role="alert">
        <h1>404</h1>
        <h3>Sahifa topilmadi!</h3>
    </div>
        )
    }
}

export default ErrorPage