import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { BASE_URL } from "../../common/api";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
// import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

class result extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      finalData: [],
    };
  }

  handleData = () => {
    const { results } = this.state;

    let overallData = [];
    results.questions.map((question) => {
      let obj = {};
      let innerLabels = [];
      let innerData = [];
      let innerDataset = [];
      question.answers.map((answer) => {
        innerLabels.push(answer.nameUz);
        obj["labels"] = innerLabels;
        return obj;
      });
      innerLabels.map((item, index) => {
        let num = question.user_answers.filter(
          (el) => el.answer === item
        ).length;
        innerData.push(num);

        return obj;
      });
      innerDataset.push({
        label: question.nameUz,
        data: innerData,
        backgroundColor: [
          "rgba(250,219,224,0.9)",
          "rgba(212,230,246,0.9)",
          "rgba(249,239,217,0.9)",
          "rgba(215,236,235,0.9)",
          "rgba(229,218,249,0.9)",
        ],
        borderColor: [
          "rgba(255,130,155)",
          "rgba(79,170,237)",
          "rgba(253,216,127)",
          "rgba(71,182,182)",
          "rgba(166,153,254)",
        ],
        borderWidth: 1,
      });
      obj["datasets"] = innerDataset;
      obj["options"] = {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: question.nameUz,
          },
        },
      };
      overallData.push(obj);
      return overallData;
    });
    this.setState({ finalData: overallData });
  };

  async componentDidMount() {
    const id = this.props.match.params.id;

    await axios.get(BASE_URL + "surveys/" + id).then((res) => {
      const results = res.data;
      this.setState(
        {
          results,
        },
        () => this.handleData()
      );
    });
  }

  render() {
    const { finalData } = this.state;

    return (
      <div>
        <h3>So'rovnomaning nomi</h3>
        <h3>{this.state.results.nameUz}</h3>
        {finalData &&
          finalData.map((item, index) => (
            <Bar key={index} options={item.options} data={item} />
          ))}
      </div>
    );
  }
}
export default withRouter(result);
