import React from "react";
import axios from "axios";
import { BASE_URL, BANNERS, HEADERS } from "../../common/api";
import Table from "../../assets/js/Table";
import { Modal, Form, Button } from "react-bootstrap";
import Error from "../../assets/js/Error";

const columns = [
  { field: "imageUz", header: "Rasm Uz" },
  { field: "imageRu", header: "Rasm Ru" },
  { field: "link", header: "Havola" },
  { field: "priority", header: "Prioriteti" },
  { field: "isActive", header: "Statusi" },
];

export default class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      show: false,
      banners: [],
      link: "",
      priority: 1,
      imageUz: null,
      imageRu: null,
      id: "",
      isActive: true,
      status: 0,
    };
    this.showModal = this.showModal.bind(this);
    this.addOrUpdate = this.addOrUpdate.bind(this);
    this.renewState = this.renewState.bind(this);
    this.get = this.get.bind(this);
    this.delete = this.delete.bind(this);
    this.getTitle = this.getTitle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
    this._isMounted = false;
  }

  renewState = () => {
    this.setState({
      show: false,
      link: "",
      priority: 1,
      imageUz: null,
      imageRu: null,
      id: "",
      isActive: true,
    });
  };
  // Show or hide modal depending on show state
  // setState sets default values in order to erase state after every modal show
  showModal = () => {
    this.setState({ show: true });
  };

  onShowAlert = () => {
    this.setState({ visible: true }, () => {
      window.setTimeout(() => {
        this.setState({ visible: false });
      }, 2000);
    });
  };

  hideModal = () => {
    this.setState({ show: false });
    this.renewState();
  };

  // Handle change in event
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  // Image handle
  onImageChange = (event) => {
    this.setState(
      { [event.target.name]: event.target.files[0] },
      console.log(this.state.imageUz)
    );
  };
  // TITLE change
  getTitle = () => {
    const { id } = this.state;
    if (id) {
      return <h3>O'zgartirish</h3>;
    } else {
      return <h3>Yangi qo'shish</h3>;
    }
  };

  // GET data
  componentDidMount() {
    this._isMounted = true;
    this._isMounted &&
      axios
        .get(BASE_URL + BANNERS, HEADERS)
        .then((res) => {
          this._isMounted &&
            this.setState({ banners: res.data, status: res.status });
        })
        .catch((err) => {
          this._isMounted && this.setState({ status: err.response.status });
        });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // GET data depending on id in order to edit it
  get = (row) => {
    this.showModal();
    this.setState({
      link: row.link,
      priority: row.priority,
      imageUz: row.imageUz,
      imageRu: row.imageRu,
      id: row.id,
      isActive: row.isActive,
    });
  };

  // DELETE data
  delete = (id, event) => {
    event.preventDefault();
    const { banners } = this.state;
    axios.delete(BASE_URL + BANNERS + id, HEADERS).then((res) => {
      const updatedData = banners.filter((banner) => banner.id !== id);
      this.setState({ banners: updatedData });
    });
  };

  // POST or UPDATE data
  // Function collects all inputs and depending on whether id is exist or not, decides put or post
  addOrUpdate = (event) => {
    event.preventDefault();
    const { imageUz, imageRu, id, banners } = this.state;

    let formData = new FormData();
    if (imageUz !== null && imageUz.name) {
      formData.append("imageUz", imageUz, imageUz.name);
    }
    if (imageRu !== null && imageRu.name) {
      formData.append("imageRu", imageRu, imageRu.name);
    }
    formData.append("link", this.state.link);
    formData.append("priority", this.state.priority);
    formData.append("isActive", this.state.isActive);
    console.log(formData);
    if (id) {
      axios
        .patch(BASE_URL + BANNERS + id + "/", formData, HEADERS)
        .then((res) => {
          const index = banners.findIndex((item) => item.id === id);
          banners[index] = res.data;
          this.setState({ banners });
          this.hideModal();
        });
    } else {
      axios
        .post(BASE_URL + BANNERS, formData, HEADERS)
        .then(
          (res) =>
            this.setState({ banners: [...this.state.banners, res.data] }),
          this.renewState()
        )
        .catch((error) => {
          console.log(error.response);
        });
    }
  };

  render() {
    const {
      banners,
      imageUz,
      imageRu,
      link,
      priority,
      isActive,
      show,
      id,
      status,
    } = this.state;
    if (!banners) {
      return null;
    }
    return (
      <>
        {status === 200 ? (
          <>
            <Modal
              show={show}
              onHide={() => {
                this.hideModal();
              }}
              animation={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>{this.getTitle()}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={(event) => this.addOrUpdate(event)}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                    key={id}
                  >
                    <Form.Label>Link</Form.Label>
                    <Form.Control
                      type="text"
                      name="link"
                      value={link}
                      onChange={this.handleChange}
                    />
                    <Form.Label>Prioriteti</Form.Label>
                    <Form.Control
                      type="number"
                      name="priority"
                      value={priority}
                      onChange={this.handleChange}
                    />
                    <Form.Label>Rasm Uz:</Form.Label>
                    {imageUz && (
                      <img
                        src={imageUz}
                        alt="logo"
                        style={{ width: "90px", height: "90px" }}
                        className="p-2"
                      />
                    )}
                    <Form.Control
                      type="file"
                      className="file"
                      name="imageUz"
                      onChange={this.onImageChange}
                    />
                    <Form.Label>Rasm Ru:</Form.Label>
                    {imageRu && (
                      <img
                        src={imageRu}
                        alt="logo"
                        style={{ width: "90px", height: "90px" }}
                        className="p-2"
                      />
                    )}
                    <Form.Control
                      type="file"
                      className="file"
                      name="imageRu"
                      onChange={this.onImageChange}
                    />
                    <Form.Label>Statusi:</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleChange}
                      name="isActive"
                      value={isActive}
                    >
                      <option value="true">active</option>
                      <option value="false">NOT active</option>
                    </Form.Control>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={this.showModal}
                  >
                    Saqlash
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
            <Table
              addFunc={this.showModal}
              data={banners}
              columns={columns}
              title="Bannerlar"
              editFunc={(row) => this.get(row)}
              deleteFunc={(id, event) => this.delete(id, event)}
            />
          </>
        ) : (
          <Error status={status} />
        )}
      </>
    );
  }
}
