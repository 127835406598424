import React, { createContext, useState } from 'react';

export const Context = createContext(null);

export const Provider = ({ children }) => {
  const [show, setShow] = useState(true);

  const toggleShow = () => setShow(prevShow => !prevShow);

  const contextValue = {
    show,
    toggleShow,
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};