import React from "react";
import axios from "axios";
import {
  BASE_URL,
  DELIVERY,
  ORDERINFO,
  ORDERS,
  WAREHOUSE,
  LOGS,
  HEADERS,
  DRIVERS,
  REGIONS,
  CURRENTUSER,
} from "../../common/api";
import Error from "../../assets/js/Error";
import noimage from "../../assets/static/image/noimage.png";
import Modal from "react-bootstrap/Modal";
import { ModalDialog } from "react-bootstrap";
import { Image } from "react-bootstrap";

export default class SingleOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      type: this.props.match.params.orderType,
      name: "",
      phone: "",
      saler: "",
      paid_amount: 0,
      region: "",
      district: "",
      address: "",
      comment: "",
      orderStatus: "",
      price: "",
      status: "",
      expected_delivery_date: "",
      warehouse: "",
      deliver: "",
      deliver_name: "",
      employee: "",
      warehouse_name: "",
      log: [],
      items: [],
      filteredItems: [],
      methods: [],
      warehouses: [],
      drivers: [],
      isWarehouseSelected: false,
      resStatus: 0,
      isRegion: false,
      region_id: 0,
      in_warehouse: "",
      barcodes: [],
      barcode: "",
      currentPage: 1,
      totalPages: 1,
      show: false,
      selectedImage: null,
      confirm: false,
      deleted_id: "",
      deleted_index: "",
      required: false,
    };
    this.showModal = this.showModal.bind(this);
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
    });
    if (event.target.name === "expected_delivery_date") {
      this.setState({ required: false });
    }
  };

  handlePageChange(pageNumber) {
    this.setState({
      currentPage: pageNumber,
    });
    this.getData(pageNumber);
  }
  handleWarehouseChange = (event, item) => {
    const { value } = event.target;
    const product = this.state.items.find((product) => product.id === item.id);
    const updatedProduct = JSON.parse(JSON.stringify(product));
    updatedProduct.warehouse_in = value;
    updatedProduct.product.units = updatedProduct.product.units.filter(
      (el) => el.warehouse == value
    );
    updatedProduct.barcode =
      updatedProduct.product.units.length > 0
        ? updatedProduct.product.units[0].barcode
        : "";
    const updatedProducts = this.state.filteredItems.map((data) =>
      data.id === updatedProduct.id ? updatedProduct : data
    );
    this.setState({ filteredItems: updatedProducts });
  };

  handleBarcodeChange = (event, item) => {
    const { value } = event.target;
    const warehouse = item.product.units.find((el) => el.barcode === value);

    const updatedBarcode = {
      ...item,
      barcode: value,
      warehouse_in: warehouse["warehouse"],
      warehouse_name: warehouse["warehouse_name_uz"],
    };
    const updatedBarcodes = this.state.filteredItems.map((data) =>
      data.id === updatedBarcode.id ? updatedBarcode : data
    );
    this.setState({ filteredItems: updatedBarcodes });
  };

  confirmOrder = (id) => {
    const { employee, type, expected_delivery_date } = this.state;
    if (expected_delivery_date !== null) {
      let data = {};
      let logData = {};
      if (type === "1") {
        this.setState({ orderStatus: 1 });
        data["order_status"] = 1;
        data["expected_delivery_date"] = this.state.expected_delivery_date;

        logData["action"] = "Buyurtma qabul qilindi";
        logData["manager"] = employee;
        logData["order"] = id;
      } else if (type === "2") {
        this.setState({ orderStatus: 1 });
        data["order_status"] = 1;
        data["delivery_status"] = "Delivery attached";
        logData["action"] = "Buyurtma o'zgartirildi";
        logData["manager"] = employee;
        logData["order"] = id;
      }
      this.state.filteredItems.forEach((item) => {
        const order_item = {
          barcode: "",
          warehouse: "",
        };
        order_item["barcode"] = parseInt(item.barcode);
        order_item["warehouse"] = parseInt(item.warehouse_in);
        axios.patch(BASE_URL + "order-items/" + item.id, order_item, HEADERS);
      });
      axios.put(BASE_URL + ORDERINFO + id + "/", data, HEADERS);
      axios.post(BASE_URL + LOGS + id + "/", logData, HEADERS).then((res) => {
        const data = res.data;
        data["date"] =
          new Date(data["date"]).toLocaleDateString("ru-RU") +
          "  " +
          new Date(data["date"]).toLocaleTimeString("ru-RU").slice(0, 5);
        this.setState({ log: [data, ...this.state.log], required: false });
      });
    } else {
      this.setState({
        required: true,
      });
      window.scrollTo({ top: 4000, left: 0, behavior: "smooth" });
    }
  };

  rejectOrder = (id, status) => {
    let logData = {};
    const { employee } = this.state;
    let data = {
      order_status: status,
    };
    logData["action"] =
      status === 5 ? "Buyurtma bekor qilindi" : "Buyurtma yetkazildi";
    logData["manager"] = employee;
    logData["order"] = this.state.id;
    axios
      .post(BASE_URL + LOGS + this.state.id + "/", logData, HEADERS)
      .then((res) => {
        const data = res.data;
        data["date"] =
          new Date(data["date"]).toLocaleDateString("ru-RU") +
          "  " +
          new Date(data["date"]).toLocaleTimeString("ru-RU").slice(0, 5);
        this.setState({ log: [...this.state.log, data] });
      });
    axios
      .put(BASE_URL + ORDERINFO + id + "/", data, HEADERS)
      .then(this.setState({ orderStatus: status }));
  };

  handleSAP = (e, index) => {
    const SAP_status = e.target.value;
    const filteredItems = this.state.filteredItems;
    filteredItems[index]["SAP_status"] = SAP_status;
    this.setState({
      filteredItems,
    });
  };
  changeSAPStatus = (id, index) => {
    const SAP_status = this.state.filteredItems[index]["SAP_status"];
    const order_item = {
      SAP_status: SAP_status,
    };
    const logData = {
      action: `${this.state.filteredItems[index]["product"]["vId"]} SAP Statusi < ${SAP_status} >ga  o'zgartirildi`,
      manager: this.state.employee,
      order: this.state.id,
    };
    axios
      .patch(BASE_URL + "order-items/" + id, order_item, HEADERS)
      .then((res) => {
        const id = this.state.id;
        axios.post(BASE_URL + LOGS + id + "/", logData, HEADERS).then((res) => {
          const data = res.data;
          data["date"] =
            new Date(data["date"]).toLocaleDateString("ru-RU") +
            "  " +
            new Date(data["date"]).toLocaleTimeString("ru-RU").slice(0, 5);
          this.setState({ log: [data, ...this.state.log] });
        });
      });
  };

  confirmWarehouse = () => {
    const { id, employee, warehouse } = this.state;
    let logData = {
      action: "Buyurtma o'zgartirildi",
      manager: employee,
      order: id,
    };
    let orderData = {
      warehouse: warehouse,
    };
    axios.patch(BASE_URL + ORDERINFO + id + "/", orderData, HEADERS).then(
      // axios.post(BASE_URL + LOGS + id + "/", logData, HEADERS).then((res) => {
      //   const data = res.data;
      //   data["date"] =
      //     new Date(data["date"]).toLocaleDateString("ru-RU") +
      //     "  " +
      //     new Date(data["date"]).toLocaleTimeString("ru-RU").slice(0, 5);
      //   this.setState({
      //     log: [...this.state.log, data],
      //     isWarehouseSelected: true,
      //   });
      // })
      this.setState({
        isWarehouseSelected: true,
      })
    );
  };

  async componentDidMount() {
    const { id } = this.state;
    if (id === "add") {
      return;
    } else {
      await axios.get(BASE_URL + ORDERINFO + id, HEADERS).then((res) => {
        const order = res.data;
        const resStatus = res.status;
        const date = order.expected_delivery_date;

        this.setState({
          name: order.name,
          phone: order.phone,
          saler: order.saler_firstname + " " + order.saler_lastname,
          paid_amount: order.paid_amount,
          region: order.region_name,
          region_id: order.region,
          district: order.district_name,
          address: order.address,
          comment: order.comment,
          orderStatus: order.order_status,
          items: order.order_items,
          filteredItems: order.order_items,
          price: order.price,
          status: order.status,
          warehouse: order.warehouse,
          warehouse_name: order.warehouse_name,
          expected_delivery_date: date,
          deliver: order.deliver,
          deliver_name: order.deliver_name,
          resStatus: resStatus,
        });
      });
      await axios.get(BASE_URL + CURRENTUSER, HEADERS).then((res) => {
        const regions = res.data.regions;
        const isInRegion = regions.some((el) => el === this.state.region_id);
        this.setState({ isInRegion });
      });
      await axios.get(BASE_URL + DELIVERY, HEADERS).then((res) => {
        const methods = res.data;
        this.setState({ methods: methods, resStatus: res.status });
      });
      await axios.get(BASE_URL + WAREHOUSE, HEADERS).then((res) => {
        this.setState({ warehouses: res.data, resStatus: res.status });
      });
      await axios
        .get(BASE_URL + "users/user/", HEADERS)
        .then((res) => this.setState({ employee: res.data.id }));
      await axios.get(BASE_URL + LOGS + id, HEADERS).then((res) => {
        const log = res.data;
        log.map(
          (el) =>
            (el.date =
              new Date(el.date).toLocaleDateString("ru-RU") +
              "  " +
              new Date(el.date).toLocaleTimeString("ru-RU").slice(0, 5))
        );
        this.setState({ log: log });
      });
      await axios
        .get(BASE_URL + DRIVERS, HEADERS)
        .then((res) =>
          this.setState({ drivers: res.data, resStatus: res.status })
        );
    }
  }

  showConfirmationModal = (id, index) => {
    this.setState({ confirm: true, deleted_id: id, deleted_index: index });
  };

  hideConfirmationModal = () => {
    this.setState({ confirm: false, deleted_id: "" });
  };

  delete = () => {
    axios
      .delete(BASE_URL + "order-items/" + this.state.deleted_id, HEADERS)
      .then((res) => {
        const logData = {
          action: ` ${
            this.state.filteredItems[this.state.deleted_index]["product"]["vId"]
          }  buyurtma o'chirildi`,
          manager: this.state.employee,
          order: this.state.id,
        };
        axios
          .post(BASE_URL + LOGS + this.state.id + "/", logData, HEADERS)
          .then((res) => {
            const data = res.data;
            data["date"] =
              new Date(data["date"]).toLocaleDateString("ru-RU") +
              "  " +
              new Date(data["date"]).toLocaleTimeString("ru-RU").slice(0, 5);
            const newFilteredItems = this.state.filteredItems.filter(
              (el, index) => index !== this.state.deleted_index
            );
            let newPrice = 0;
            for (let i = 0; i < newFilteredItems.length; i++) {
              if (newFilteredItems[i]['product']['size']['vSizeId'] == "104") {
                newPrice += newFilteredItems[i]['price'] * newFilteredItems[i]['quantity'];
              } else if (newFilteredItems[i]['product']['size']['type'] == "2") {
                newPrice += newFilteredItems[i]['price'] * ((newFilteredItems[i]['quantity'] / 100) * (newFilteredItems[i]['product']['size']['width']) / 100);
              } else if (newFilteredItems[i]['product']['size']['type'] == "1" || newFilteredItems[i]['product']['size']['type'] == "0") {
                newPrice += (((newFilteredItems[i]['product']['size']['width'] / 100) * (newFilteredItems[i]['product']['size']['length']) / 100) * newFilteredItems[i]['price']) * newFilteredItems[i]['quantity'];
              }
            }
            this.setState({
              filteredItems: newFilteredItems,
              confirm: false,
              deleted_id: "",
              deleted_index: "",
              log: [data, ...this.state.log],
              isWarehouseSelected: true,
              price: newPrice,
            });
          });
      });
  };

  showModal = (image) => {
    this.setState({ show: true, selectedImage: image });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  render() {
    const {
      name,
      phone,
      saler,
      paid_amount,
      region,
      district,
      address,
      comment,
      filteredItems,
      items,
      id,
      price,
      orderStatus,
      status,
      warehouse,
      methods,
      drivers,
      expected_delivery_date,
      log,
      warehouses,
      isWarehouseSelected,
      type,
      warehouse_name,
      barcode,
      resStatus,
      isInRegion,
      in_warehouse,
      barcodes,
      currentPage,
      totalPages,
      deliver,
      deliver_name,
      show,
      selectedImage,
      confirm,
      required,
    } = this.state;
    return (
      <>
        {resStatus === 200 && isInRegion ? (
          <div className="single-order">
            <div className="">
              {orderStatus !== 5 && (
                <button
                  className={`btn btn-info ${
                    isWarehouseSelected || deliver ? "" : "disabled"
                  }`}
                  onClick={() =>
                    isWarehouseSelected ? this.confirmOrder(id) : null
                  }
                >
                  Buyurtmani tasdiqlash
                </button>
              )}

              <button
                className="btn btn-danger mx-2"
                disabled={orderStatus === 0 ? false : true}
                onClick={() => this.rejectOrder(id, 5)}
              >
                Buyurtmani bekor qilish
              </button>
              {orderStatus !== 5 && orderStatus !== 0 && (
                <button
                  className={`btn btn-success ${
                    orderStatus !== 4 ? "" : "disabled"
                  } `}
                  onClick={() => this.rejectOrder(id, 4)}
                >
                  Yetkazildi
                </button>
              )}
            </div>
            <div className="main">
              <div className="customer-info">
                <div className="status">
                  <span>№ {id}</span>
                  <span className="badge badge-success">{status}</span>
                </div>
                <hr />
                <div className="info">
                  <form>
                    <div className="form-group">
                      <label htmlFor="name">F.I.Sh</label>
                      {orderStatus === 0 ? (
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={name || ""}
                          id="name"
                          aria-describedby="emailHelp"
                          onChange={this.handleChange}
                        />
                      ) : (
                        <h6>{name}</h6>
                      )}
                    </div>
                  </form>
                  <form>
                    <div className="form-group">
                      <label htmlFor="phone">Telefon raqami</label>
                      {orderStatus === 0 ? (
                        <input
                          type="text"
                          className="form-control"
                          name="phone"
                          value={phone || ""}
                          id="phone"
                          onChange={this.handleChange}
                        />
                      ) : (
                        <h6>{phone}</h6>
                      )}
                    </div>
                  </form>
                  <form>
                    <div className="form-group">
                      <label htmlFor="region">Hudud</label>
                      {orderStatus === 0 ? (
                        <input
                          type="text"
                          className="form-control"
                          name="region"
                          value={region || ""}
                          disabled
                          id="region"
                          aria-describedby="emailHelp"
                          onChange={this.handleChange}
                        />
                      ) : (
                        <h6>{region}</h6>
                      )}
                    </div>
                  </form>
                  <form>
                    <div className="form-group">
                      <label htmlFor="district">Tuman</label>
                      {orderStatus === 0 ? (
                        <input
                          type="text"
                          className="form-control"
                          name="district"
                          value={district || ""}
                          disabled
                          id="district"
                          aria-describedby="emailHelp"
                          onChange={this.handleChange}
                        />
                      ) : (
                        <h6>{district}</h6>
                      )}
                    </div>
                  </form>
                  <form>
                    <div className="form-group">
                      <label htmlFor="address">Manzil</label>
                      {orderStatus === 0 ? (
                        <input
                          type="text"
                          className="form-control"
                          name="address"
                          value={address || ""}
                          id="address"
                          aria-describedby="emailHelp"
                          onChange={this.handleChange}
                        />
                      ) : (
                        <h6>{address}</h6>
                      )}
                    </div>
                  </form>
                  <form>
                    <div className="form-group">
                      <label htmlFor="comment">Buyurtmachi izohi</label>
                      {orderStatus === 0 ? (
                        <input
                          type="text"
                          className="form-control"
                          name="comment"
                          value={comment || ""}
                          id="comment"
                          aria-describedby="emailHelp"
                          onChange={this.handleChange}
                        />
                      ) : (
                        <h6>{comment}</h6>
                      )}
                    </div>
                  </form>
                </div>
                <hr />
                <div className="info delivery-info">
                  <form>
                    <div className="form-group">
                      <label htmlFor="driver">Yetkazib beruvchi</label>
                      <select
                        className="form-control"
                        name="deliver"
                        id="deliver"
                        onChange={this.handleChange}
                        value={deliver || ""}
                      >
                        {drivers.map((driver, index) => (
                          <option value={driver.id} key={index}>
                            {driver.first_name + " " + driver.last_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </form>
                  <form>
                    <div className="form-group">
                      <label htmlFor="textInput">Yetkazib berish metodi</label>
                      <select
                        className="form-control"
                        name="titleUz"
                        id="textInput"
                        onChange={this.handleChange}
                      >
                        {methods.map((method) => (
                          <option value={method.id} key={method.id}>
                            {method.nameUz}
                          </option>
                        ))}
                      </select>
                    </div>
                  </form>
                  <form>
                    <div className="form-group">
                      <label htmlFor="delivery_date">
                        Yetkazib berish sanasi
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        name="expected_delivery_date"
                        style={{
                          border: required ? "1px solid red" : "",
                          color: required ? "red" : "",
                        }}
                        defaultValue={expected_delivery_date}
                        onChange={this.handleChange}
                        id="delivery_date"
                      />
                    </div>
                  </form>

                  <div className="delivery-dates">
                    <div>
                      <label>Yetkazish boshlangan vaqt</label>
                      <span>---</span>
                    </div>
                    <div>
                      <label>Yetkazish tugash vaqti</label>
                      <span>---</span>
                    </div>
                    <div>
                      <label>Kuryer manzilga kirish</label>
                      <span>---</span>
                    </div>
                    <div>
                      <label>Kuryer manzilga chiqishi</label>
                      <span>---</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="order-info">
                <div className="order">
                  <div className="warehouse">
                    {type === "1" && orderStatus === 0 ? (
                      <>
                        <div>
                          <h6>Yig'iluvchi ombor</h6>
                          <select
                            className="form-control"
                            aria-label="Default select example"
                            onChange={this.handleChange}
                            name="warehouse"
                            value={warehouse}
                          >
                            <option hidden>Tanlang:</option>
                            {warehouses.map((item) => (
                              <option value={item.id} key={item.id}>
                                {item.name_uz}
                              </option>
                            ))}
                          </select>
                        </div>
                        <button
                          className={`btn btn-primary ${
                            warehouse ? "" : "disabled"
                          }`}
                          onClick={() => this.confirmWarehouse()}
                        >
                          Skladni tasdiqlash
                        </button>
                      </>
                    ) : (
                      <>
                        <h6>Yig'iluvchi ombor: {warehouse_name}</h6>
                      </>
                    )}
                  </div>

                  <div className="order-list">
                    <label>Asosiy mahsulotlar</label>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Articul</th>
                          <th>Miqdori</th>
                          <th>Yetkazib berish kuni</th>
                          <th>Rasmi</th>
                          <th>Ombor</th>
                          <th>Barcode</th>
                          {type === "1" &&
                            (orderStatus === 1 || orderStatus === 4) && (
                              <th>SAP Status</th>
                            )}
                          <th>Amal</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredItems.map((data, index) => (
                          <tr key={data.id}>
                            <td>{data.product.vId} </td>
                            <td>{data.quantity}</td>
                            <td>{data.delivery_day} kun</td>
                            <td>
                              <img
                                src={data.image != null ? data.image : noimage}
                                width={50}
                                height={50}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  data.image !== null &&
                                  this.showModal(data.image)
                                }
                              />
                            </td>
                            <td>
                              {type === "1" && orderStatus === 0 ? (
                                <>
                                  <div>
                                    <select
                                      className="form-control"
                                      aria-label="Default select example"
                                      onChange={(e) =>
                                        this.handleWarehouseChange(e, data)
                                      }
                                      name="in_warehouse"
                                      value={data.warehouse_in || ""}
                                    >
                                      <option hidden>Tanlang:</option>
                                      {warehouses.map((data) => (
                                        <option value={data.id} key={data.id}>
                                          {data.name_uz}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </>
                              ) : (
                                <>{data.warehouse_name}</>
                              )}
                            </td>
                            <td>
                              {type === "1" && orderStatus === 0 ? (
                                <>
                                  <div>
                                    <select
                                      className="form-control"
                                      aria-label="Default select example"
                                      onChange={(e) =>
                                        this.handleBarcodeChange(e, data)
                                      }
                                      name="barcode"
                                      value={data.barcode || ""}
                                    >
                                      <option>Zavod (1)</option>
                                      {data.product.units.map((data) => (
                                        <option
                                          value={data.barcode}
                                          key={data.id}
                                        >
                                          {data.barcode}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </>
                              ) : (
                                <>{data.barcode}</>
                              )}
                            </td>
                            {type === "1" &&
                              (orderStatus === 1 || orderStatus === 4) && (
                                <td>
                                  <input
                                    className="form-control"
                                    style={{ width: "100px" }}
                                    value={
                                      data.SAP_status ? data.SAP_status : ""
                                    }
                                    onChange={(e) => this.handleSAP(e, index)}
                                    type="text"
                                  />
                                </td>
                              )}
                            <td>
                              {type === "1" && orderStatus === 1 && (
                                <button
                                  className="btn btn-success mr-1"
                                  onClick={() =>
                                    this.changeSAPStatus(data.id, index)
                                  }
                                >
                                  +
                                </button>
                              )}
                              <button
                                className="btn btn-danger"
                                onClick={() =>
                                  this.showConfirmationModal(data.id, index)
                                }
                              >
                                -
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Modal
                      show={confirm}
                      onHide={() => {
                        this.hideConfirmationModal();
                      }}
                      animation={false}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>O'chirish</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <h5>
                          Haqiqatan ham ushbu ma'lumotni o'chirmoqchimisiz?
                        </h5>
                        <button
                          className="btn btn-danger m-1"
                          onClick={(event) => this.delete()}
                        >
                          O'chirish
                        </button>
                        <button
                          className="btn btn-light outline-secondary"
                          onClick={() => {
                            this.hideConfirmationModal();
                          }}
                        >
                          Qaytish
                        </button>
                      </Modal.Body>
                    </Modal>
                  </div>
                  <div className="order-list">
                    <label>Qo'shimcha mahsulotlar</label>
                  </div>
                </div>
                <div className="purchase">
                  <div className="row">
                    <div className="col-4">
                      <h6>To'lov metodi</h6>
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        onChange={this.handleChange}
                      >
                        <option defaultValue="1">Naqd pul</option>
                        <option value="2">Terminal</option>
                        <option value="3">Click</option>
                        <option value="4">Payme</option>
                      </select>
                    </div>
                    <div className="col-4">
                      <h6>To'lov statusi</h6>
                      <p>Umuman to'lanmagan</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <h6>Summa</h6>
                      <p>{price} so'm</p>
                    </div>
                    <div className="col-4">
                      <h6>Chegirma</h6>
                      <p>0 so'm</p>
                    </div>
                    <div className="col-4">
                      <b>Sotuvchi</b>
                      <p>{saler}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <h6>Oldindan</h6>
                      <p>{paid_amount}</p>
                    </div>
                    <div className="col-4">
                      <h6>Tizimga</h6>
                      <p>0 so'm</p>
                    </div>
                    <div className="col-4">
                      <h6>Kuryerga</h6>
                      <p>0 so'm</p>
                    </div>
                  </div>
                </div>

                <div className="history">
                  <label>Buyurtma tarixi</label>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>id</th>
                        <th>Harakat</th>
                        <th>Manager</th>
                        <th>Vaqt</th>
                      </tr>
                    </thead>
                    <tbody>
                      {log.map((el) => (
                        <tr key={el.id}>
                          <td>{el.id}</td>
                          <td>{el.action}</td>
                          <td>{el.manager_name}</td>
                          <td>{el.date}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Error status={resStatus} />
        )}
        <ModalDialog>
          <Modal
            dialogClassName="custom-modal"
            show={show}
            onHide={() => {
              this.hideModal();
            }}
          >
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Selected"
                style={{ width: "100%" }}
                onClick={() => this.hideModal()}
              />
            )}
          </Modal>
        </ModalDialog>
      </>
    );
  }
}
