import React, { Component } from "react";
import axios from "axios";
import {
  BASE_URL,
  PRODUCTS,
  COLORS,
  STYLES,
  ROOMS,
  HEADERS,
} from "../../common/api";
import Select from "react-select";

export default class SingleDesign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      data: {},
      colors: [],
      styles: [],
      rooms: [],
      inColors: [],
      inRooms: [],
      inStyles: [],
      images: [],
      newImages: [],
      status: "",
      singleImage: "",
      newSingleImage: "",
    };
  }

  getProducts = async () => {
    const { id } = this.state;
    await axios.get(BASE_URL + PRODUCTS + id).then((res) => {
      const obj = {};
      const inColors = [];
      const inStyles = [];
      const inRooms = [];
      const data = res.data;
      Object.keys(data).forEach((item) => (obj[item] = data[item]));
      data.colors.map((item) => {
        return inColors.push({ value: item.id, label: item.nameUz });
      });
      data.rooms.map((item) => {
        return inRooms.push({ value: item.id, label: item.nameUz });
      });
      data.styles.map((item) => {
        return inStyles.push({ value: item.id, label: item.nameUz });
      });
      this.setState({
        data: obj,
        status: res.status,
        inColors,
        inRooms,
        inStyles,
        singleImage: data.image,
      });
    });
  };

  getColors = async () => {
    await axios.get(BASE_URL + COLORS).then((res) => {
      const colors = res.data;
      const arr = [];
      colors.map((item) => arr.push({ value: item.id, label: item.nameUz }));
      this.setState({ colors: arr });
    });
  };

  getRooms = async () => {
    await axios.get(BASE_URL + ROOMS).then((res) => {
      const rooms = res.data;
      const arr = [];
      rooms.map((item) => arr.push({ value: item.id, label: item.nameUz }));
      this.setState({ rooms: arr });
    });
  };

  getStyles = async () => {
    await axios.get(BASE_URL + STYLES).then((res) => {
      const styles = res.data;
      const arr = [];
      styles.map((item) => arr.push({ value: item.id, label: item.nameUz }));
      this.setState({ styles: arr, status: res.status });
    });
  };

  getImages = async () => {
    await this.state.data.photos.map((image) =>
      axios.get(BASE_URL + `images/${image}`).then((res) => {
        const images = [...this.state.images, res.data];
        images.sort(function (a, b) {
          return a.id - b.id;
        });
        this.setState({ images: images }, () => {});
      })
    );
  };
  async componentDidMount() {
    await this.getProducts();
    await this.getColors();
    await this.getRooms();
    await this.getStyles();
    await this.getImages();
  }

  handleChange = (event) => {
    const data = {};
    data[event.target.name] = event.target.value;
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };
  handleSelect = (event, stateProp) => {
    const arr = [];
    event.map((item) => {
      arr.push({ value: item.value, label: item.label });
      return arr;
    });
    this.setState({ [stateProp]: arr });
  };
  onImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const singleImage = reader.result;
      const newSingleImage = file;
      this.setState({ singleImage, newSingleImage });
    };

    reader.readAsDataURL(file);
  };
  handleAddImage = (event) => {
    // Get the uploaded image file
    const file = event.target.files[0];
    // Create a FileReader object to read the file
    const reader = new FileReader();
    // Set up the onload function for the reader to add the image URL to state
    reader.onload = () => {
      const images = [...this.state.images, reader.result];
      const newImages = [...this.state.newImages, file];
      this.setState({ images, newImages });
    };
    // Read the file as a data URL
    reader.readAsDataURL(file);
  };
  handleUpdateImage = async (id, index, event) => {
    console.log(event.target.files[0]);
    const file = event.target.files[0];

    let formData = new FormData();
    formData.append("image", file, file.name);
    console.log(id);
    await axios
      .patch(BASE_URL + `images/${id}/`, formData, HEADERS)
      .then((res) => console.log(res))
      .catch((err) => console.log(err.response));
    // Get the uploaded image file
    // Create a FileReader object to read the file
    const reader = new FileReader();
    // Set up the onload function for the reader to add the image URL to state
    reader.onload = () => {
      const images = [...this.state.images];
      images[index] = reader.result;
      // const newImages = [...this.state.newImages];
      // newImages[index] = file;
      this.setState({ images });
    };
    // Read the file as a data URL
    reader.readAsDataURL(file);
  };

  handleDeleteImage = (index) => {
    const data = [...this.state.data.photos];
    const images = [...this.state.images];
    const newImages = [...this.state.newImages];

    data.splice(index, 1);
    images.splice(index, 1);
    newImages.splice(images.length - index - 1, 1);

    this.setState({
      data: {
        ...this.state.data,
        photos: data,
      },
      images: images,
      newImages: newImages,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { id, data, newImages, inColors, inRooms, inStyles } = this.state;
    const selectedColors = [];
    const selectedRooms = [];
    const selectedStyles = [];
    inColors.map((color) => {
      return selectedColors.push(parseInt(color.value));
    });
    inRooms.map((room) => {
      return selectedRooms.push(parseInt(room.value));
    });
    inStyles.map((style) => {
      return selectedStyles.push(parseInt(style.value));
    });
    // If images is submitted then append it to formData, if not leave it as it was
    // if (data.image !== null && data.image.name) {
    // formData.append("image", data.image, data.image.name);}
    const ids = this.state.data.photos;

    // Adding newly added images to 'images/' endpoint, then getting their ids and push to ids array in order to update product's images
    const newFormDataArray = [];
    newImages.forEach((image) => {
      let newFormData = new FormData();
      newFormData.append("image", image, image.name);
      newFormDataArray.push(newFormData);
    });
    await Promise.all(
      newFormDataArray.map((data) => {
        return axios.post(BASE_URL + "images/", data, HEADERS).then((res) => {
          ids.push(res.data.id);
        });
      })
    );

    let formData = new FormData();
    if (this.state.newSingleImage !== null && this.state.newSingleImage.name) {
      formData.append(
        "image",
        this.state.newSingleImage,
        this.state.newSingleImage.name
      );
    }
    selectedColors.forEach((color) => {
      formData.append("colors", color);
    });
    selectedRooms.forEach((room) => {
      formData.append("rooms", room);
    });
    selectedStyles.forEach((style) => {
      formData.append("styles", style);
    });
    formData.append("seoMetaUz", data.seoMetaUz);
    formData.append("seoMetaRu", data.seoMetaRu);
    formData.append("seoTitleUz", data.seoTitleUz);
    formData.append("seoTitleRu", data.seoTitleRu);
    formData.append("isTrend", data.isTrend);
    formData.append("isActive", data.isActive);
    formData.append("isNew", data.isNew);
    formData.append("price", data.price);
    formData.append("discount", data.discount);
    ids.forEach((id) => {
      formData.append("photos", id);
    });

    // let sentData = {
    //   colors: selectedColors,
    //   rooms: selectedRooms,
    //   styles: selectedStyles,
    //   photos: ids,
    //   seoMetaUz: data.seoMetaUz,
    //   seoMetaRu: data.seoMetaRu,
    //   seoTitleUz: data.seoTitleUz,
    //   seoTitleRu: data.seoTitleRu,
    //   isTrend: data.isTrend,
    //   isActive: data.isActive,
    //   isNew: data.isNew,
    //   price: data.price,
    //   discount: data.discount,
    // };

    await axios
      .patch(BASE_URL + PRODUCTS + "post/" + id + "/", formData, HEADERS)
      .then(this.props.history.goBack())
      .catch((err) => console.log(err.response));
    };

  render() {
    const { data, colors, inColors, styles, inStyles, rooms, inRooms, status } =
      this.state;

    return (
      <>
        {status === 200 && (
          <div className="tabs effect-3">
            <input type="radio" id="tab-1" name="tab-effect-3" defaultChecked />
            <span>Asosiy</span>
            <input type="radio" id="tab-2" name="tab-effect-3" />
            <span>Kontent</span>
            <div className="line ease"></div>
            <div className="tab-content">
              <section id="tab-item-1">
                <div className="design-card">
                  <div className="design-info">
                    <div className="design-key">Nomlanishi:</div>
                    <div className="design-value">{data.vId}</div>
                  </div>
                  <div className="design-info">
                    <div className="design-key">Kolleksiya:</div>
                    <div className="design-value">{data.collection_name}</div>
                  </div>
                  <div className="design-info">
                    <div className="design-key">Kategoriya:</div>
                    <div className="design-value">{data.category_name}</div>
                  </div>
                </div>
                <div className="design-card">
                  <div className="row p-1">
                    <div className="col-12">
                      <h6>Turi</h6>
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        onChange={this.handleChange}
                        value={data.isMain}
                        name="isMain"
                      >
                        <option value="true">Top</option>
                        <option value="false">Oddiy</option>
                      </select>
                    </div>
                  </div>
                  <div className="row pt-4 p-1">
                    <div className="col-4">
                      <h6>Statusi</h6>
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        onChange={this.handleChange}
                        value={data.isActive}
                        name="isActive"
                      >
                        <option value="true">active</option>
                        <option value="false">Passiv</option>
                      </select>
                    </div>
                    <div className="col-4">
                      <h6>isNew</h6>
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        value={data.isNew}
                        onChange={this.handleChange}
                        name="isNew"
                      >
                        <option value="true">new</option>
                        <option value="false">NOTnew</option>
                      </select>
                    </div>
                    <div className="col-4">
                      <h6>isTrend</h6>
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        value={data.isTrend}
                        onChange={this.handleChange}
                        name="isTrend"
                      >
                        <option value="true">Trendda</option>
                        <option value="false">Trendda emas</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="design-card">
                  <div className="row p-1">
                    <div className="col-6">
                      <h6>Narx</h6>
                      <input
                        className="form-control"
                        aria-label="Default select example"
                        defaultValue={data.price}
                        onChange={this.handleChange}
                        name="price"
                      />
                    </div>
                    <div className="col-6">
                      <h6>Chegirma</h6>
                      <input
                        className="form-control"
                        aria-label="Default select example"
                        defaultValue={data.discount}
                        onChange={this.handleChange}
                        name="discount"
                      />
                    </div>
                  </div>
                </div>

                <button
                  className="btn btn-primary"
                  onClick={(event) => this.handleSubmit(event)}
                >
                  Saqlash
                </button>
              </section>
              <section id="tab-item-2">
                <div className="filters">
                  <div className="row m-1">
                    <div className="col-12 photos-list">
                      {this.state.images.map((image, index) => (
                        <div className="photos" key={index}>
                          <div
                            className="file-upload"
                            style={{
                              display: "flex",
                              bottom: 0,
                              top: "auto",
                              position: "absolute",
                              backgroundColor: "#28a745",
                              borderRadius: "5px",
                            }}
                          >
                            <label
                              htmlFor={`file-update_${index}`}
                              style={{
                                width: "35px",
                                height: "35px",
                              }}
                            >
                              <i className="fa-solid fa-pen-to-square"></i>
                            </label>
                            <input
                              type="file"
                              className="form-control-file"
                              id={`file-update_${index}`}
                              name="image"
                              onChange={(event) =>
                                this.handleUpdateImage(image.id, index, event)
                              }
                            />
                          </div>
                          <img
                            src={image.image ? image.image : image}
                            alt="images"
                            className="img-fluid pr-4"
                            style={{ width: "200px", height: "200px" }}
                          />
                          <button
                            className="btn btn-danger"
                            onClick={() => this.handleDeleteImage(index)}
                          >
                            <i className="fa-solid fa-trash-can"></i>
                          </button>
                        </div>
                      ))}

                      <div className="file-upload">
                        <label htmlFor="file-input">
                          <i className="fa-regular fa-image fa-2xl pr-2"></i>
                          Rasm yuklash
                        </label>
                        <input
                          type="file"
                          className="form-control-file"
                          id="file-input"
                          name="image"
                          onChange={this.handleAddImage}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="m-1 row pl-3 mt-3">
                    <div>
                      {this.state.singleImage && (
                        <img
                          src={
                            this.state.singleImage ? this.state.singleImage : ""
                          }
                          alt="images"
                          className="img-fluid pr-4"
                          style={{ width: "200px", height: "200px" }}
                        />
                      )}
                    </div>

                    <div className="file-upload pt-3">
                      <label htmlFor="file-input_1">
                        <i className="fa-regular fa-image fa-2xl pr-2"></i>
                        Asosiy rasm yuklash
                      </label>
                      <input
                        type="file"
                        className="form-control-file"
                        id="file-input_1"
                        name="image"
                        onChange={this.onImageChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="filters">
                  <h6>Xususiyatlar</h6>
                  <div className="row">
                    <div className="col-6">
                      <form>
                        <div className="form-group">
                          <label htmlFor="inColors">Rangi</label>
                          <Select
                            closeMenuOnSelect={false}
                            defaultValue={inColors}
                            // components={animatedComponents}
                            placeholder="Tanlang..."
                            onChange={(event) =>
                              this.handleSelect(event, "inColors")
                            }
                            name="inColors"
                            isMulti
                            options={colors}
                          />
                        </div>
                      </form>
                    </div>
                    <div className="col-6">
                      <form>
                        <div className="form-group">
                          <label htmlFor="inRooms">Xonasi</label>
                          <Select
                            closeMenuOnSelect={false}
                            defaultValue={inRooms}
                            // components={animatedComponents}
                            placeholder="Tanlang..."
                            onChange={(event) =>
                              this.handleSelect(event, "inRooms")
                            }
                            name="inRooms"
                            isMulti
                            options={rooms}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <form>
                        <div className="form-group">
                          <label htmlFor="inStyles">Stili</label>
                          <Select
                            closeMenuOnSelect={false}
                            defaultValue={inStyles}
                            placeholder="Tanlang..."
                            onChange={(event) =>
                              this.handleSelect(event, "inStyles")
                            }
                            name="inStyles"
                            isMulti
                            options={styles}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  <h6>SEO</h6>
                  <div className="row">
                    <div className="col-6">
                      <form>
                        <div className="form-group">
                          <label htmlFor="seoMetaUz">seoMetaUz</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={data.seoMetaUz}
                            name="seoMetaUz"
                            placeholder="Kiriting"
                            id="seoMetaUz"
                            onChange={this.handleChange}
                          />
                        </div>
                      </form>
                    </div>
                    <div className="col-6">
                      <form>
                        <div className="form-group">
                          <label htmlFor="seoMetaRu">seoMetaRu</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={data.seoMetaRu}
                            name="seoMetaRu"
                            placeholder="Kiriting"
                            id="seoMetaRu"
                            onChange={this.handleChange}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <form>
                        <div className="form-group">
                          <label htmlFor="basisUz">seoTitleUz</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={data.seoTitleUz}
                            name="seoTitleUz"
                            placeholder="Kiriting"
                            id="seoTitleUz"
                            onChange={this.handleChange}
                          />
                        </div>
                      </form>
                    </div>
                    <div className="col-6">
                      <form>
                        <div className="form-group">
                          <label htmlFor="seoTitleRu">seoTitleRu</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={data.seoTitleRu}
                            name="seoTitleRu"
                            placeholder="Kiriting"
                            id="seoTitleRu"
                            onChange={this.handleChange}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-primary"
                  onClick={(event) => this.handleSubmit(event)}
                >
                  Saqlash
                </button>
              </section>
            </div>
          </div>
        )}
      </>
    );
  }
}
